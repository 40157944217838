import { Refresh, X } from 'assets/images';
import { Button } from 'components';
import useSelfieCapture from 'hooks/useSelfieCapture';
import React from 'react';
import { base64toFile } from 'shared/resources';

const UploadSelfieModal = ({
  onSuccess = () => null,
  handleClose = () => null,
}) => {
  const { selfie, takeSelfie, clearSelfie, videoTag, cameraError } =
    useSelfieCapture();

  function handleSucces() {
    const selfieFile = base64toFile(selfie);
    onSuccess({ file: selfieFile, type: 'S' });
    handleClose();
  }

  return (
    <div className="w-full fixed top-[80px] left-0  h-[calc(100vh_-_80px)] flex flex-col items-center justify-center bg-black-300">
      <div
        className="cursor-pointer fixed top-24 right-5"
        onClick={() => handleClose()}
      >
        <X className="text-white w-7 h-7" />
      </div>
      <div className="sm:max-w-[320px] md:max-w-[480px] lg:max-w-[580px] xxl:max-w-[800px]   mx-auto  w-full border border-dashed border-green p-2 ">
        {!selfie ? (
          <>
            {cameraError ? (
              <div className="text-white h-full w-full min-h-[250px] flex items-center justify-center">
                {cameraError}
              </div>
            ) : (
              videoTag
            )}
          </>
        ) : (
          <img className="w-full" src={selfie} alt="selfie" />
        )}
      </div>
      <div className="text-white text-sm mt-2">
        Place your face in the rectangle shape
      </div>
      <div className="w-full flex items-center justify-center gap-2 mt-3">
        {!selfie ? (
          <div className="sm:w-[36px] sm:h-[36px] md:w-[44px] md:h-[44px] lg:w-[64px] lg:h-[64px] xxl:w-[72px] xxl:h-[72px] rounded-full bg-white p-1 flex items-center justify-center">
            <button
              className="sm:w-7 sm:h-7 md:w-9 md:h-9 lg:w-14 lg:h-14  xxl:w-16 xxl:h-16 rounded-full bg-white border-2"
              onClick={takeSelfie}
            ></button>
          </div>
        ) : (
          <div className="sm:w-[36px] sm:h-[36px] md:w-[44px] md:h-[44px] lg:w-[64px] lg:h-[64px] xxl:w-[72px] xxl:h-[72px] rounded-full bg-white flex items-center justify-center">
            <button
              className="sm:w-7 sm:h-7 md:w-9 md:h-9 lg:w-14 lg:h-14  xxl:w-16 xxl:h-16  rounded-full bg-white border-2 flex items-center justify-center"
              onClick={clearSelfie}
            >
              <Refresh className="sm:w-4 sm:h-4 md:w-5 md:h-5 lg:w-6 lg:h-6 xxl:w-7 xxl:7" />
            </button>
          </div>
        )}
      </div>
      <div className="w-[334px]">
        <Button
          extraClasses="md:mt-3 lg:mt-5 xl:mt-10"
          type="button"
          onClick={handleSucces}
          disabled={!selfie}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export default UploadSelfieModal;
