import React from 'react';
import { ReactComponent as Africa } from '../../assets/images/signup/Africa.svg';
import { formatPhoneNumber } from 'shared/resources';
const baseInputLabelClasses = 'text-grey-800 text-base font-medium';

const baseInputClasses =
  'px-4 flex gap-3 items-center w-full border border-grey-400  rounded-md  focus:outline-none focus:ring focus:bg-white font-medium		text-base text-grey-900';

export default function StaticPhoneSelect({
  extraClasses = '',
  background = 'bg-transparent',
  height = ' xxl:h-[56px] xl:h-[45px] lg:h-[45px] md:h-[41px] sm:h-[40px] xs:h-[40px]',
  number = '',
  label = '',
}) {
  return (
    <div className={`flex flex-col gap-2 ${extraClasses}`}>
      {label && (
        <label htmlFor={'Country'} className={baseInputLabelClasses}>
          {label}
        </label>
      )}
      <div className={baseInputClasses + ' ' + background + height}>
        <Africa />
        {number ? formatPhoneNumber(number) : '+234'}
      </div>
    </div>
  );
}
