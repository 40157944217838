export const initialModalState = {
  modalType: '',
  isShow: false,
  elementData: {},
  type: '',
};

export const dropdownActions = {
  NUMBER_DETAIL_MODAL: 'number_details',
  PURCHASE_CONFIRMATION: 'PURCHASE_CONFIRMATION',
};
