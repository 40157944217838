import { alphaNumericRegx, getRequiredText } from 'shared/resources';
import * as yup from 'yup';

export const addMemberInitialValues = {
  first_name: '',
  last_name: '',
  email: '',
  role_id: '',
  phone: '+234',
};
export const validationSchema = yup.object().shape({
  first_name: yup
    .string()
    .required(getRequiredText('First name'))
    .matches(alphaNumericRegx, 'Special characters are not allowed.'),
  role_id: yup.string().required(getRequiredText('Role')),
  email: yup.string().required(getRequiredText('Email')),
  phone: yup
    .string()
    .min(6, getRequiredText('Phone'))
    .required(getRequiredText('Phone')),
});

export const initialModalState = {
  modalType: '',
  isShow: false,
  elementData: {},
};

export const dropdownActions = {
  UPSERT_MEMBER: 'upsert_member',
  ASSIGN_NUMBER: 'assign_number',
  ASSIGN_TEAM: 'assign_team',
  DELETE_MEMBER: 'delete_member',
  BULK_DELETE_MEMBER: 'bulk_delete_member',
  RESEND_INVITATION: 'resend_initiation',
};
