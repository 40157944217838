import { useUserDetailsByParams } from 'hooks';
import { useEffect, useState } from 'react';
const initialName = 'Unknown Number';

const NameByNumber = ({ number = '' }) => {
  const [userName, serUserName] = useState(initialName);

  const { mutateAsync, data } = useUserDetailsByParams();
  useEffect(() => {
    if (number && number?.length <= 4) {
      mutateAsync({ extension: number });
    }
  }, [number, mutateAsync]);

  useEffect(() => {
    if (number) {
      if (data?.data?.data) {
        const selectedUser = data?.data?.data;
        serUserName(
          `${selectedUser?.first_name} ${selectedUser?.last_name}` ??
            initialName,
        );
      } else {
        serUserName(initialName);
      }
    } else {
      serUserName(initialName);
    }

    return () => {
      serUserName(initialName);
    };
  }, [number, data]);

  return number?.length > 4 ? number : userName;
};

export default NameByNumber;
