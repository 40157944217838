import React from 'react';
import { useParams } from 'react-router-dom';
import Footer from './Footer';
import MessageBoard from './MessageBoard';
import Header from './Header';

const ChatViewArea = () => {
  const { id } = useParams();

  return (
    <>
      <div className="flex items-center justify-between bg-white pt-5 px-6 pb-5">
        <Header chatId={id} />
      </div>
      <span className="flex border-grey-300 border-b p-0 w-full "></span>
      <div className="pt-6 bg-white px-[60px] overflow-auto h-[calc(100vh_-_258px)]">
        <MessageBoard chatId={id} />
      </div>
      <Footer chatId={id} />
    </>
  );
};

export default ChatViewArea;
