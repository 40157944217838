import { Button, Modal, ReadViewInput, Spinner } from 'components';
import DocumentUploadInput from 'pages/PortNumber/PortNumberForm/UploadDocumentsForm/DocumentUploadInput';
import React, { useEffect, useState, useMemo } from 'react';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import { DocumentUploadConstant, initialModalState } from '../constants';
import { Arrow, Check, Download } from 'assets/images';
import { useAddKYC, useKYCData, useUploadFile } from 'hooks';
import UploadIdentificationModal from '../UploadIdentificationModal';
import UploadSelfieModal from '../UploadSelfieModal';
import { downloadFile } from 'shared/resources';
import { useAuth } from 'hooks/useAuth';
import useDeleteKYC from 'hooks/useDeleteKYC';
import DeleteKYCModal from '../DeleteKYCModal';

const colorLookup = {
  pending: 'text-[#E29704]',
  submitted: 'text-[#3333FF]',
  in_review: 'text-[#87909B]',
  completed: 'text-[#4F772D]',
  rejected: 'text-[#E81313]',
};

const PassportReadView = ({ values }) => {
  const { user } = useAuth();

  const watch = (key) => {
    if (key === 'front_image' && typeof values?.front_image === 'string') {
      return `${process.env.REACT_APP_BASE_API_URL}${
        user?.company_uuid || user?.uuid
      }/kyc/${values?.front_image}`;
    } else {
      return values?.[key];
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex gap-3">
        <ReadViewInput
          labelText="Passport number"
          valueText={values?.passport_number}
        />
        <ReadViewInput labelText="First name" valueText={values?.first_name} />
      </div>
      <div className="flex gap-3">
        <ReadViewInput
          labelText="Middle name"
          valueText={values?.middle_name}
        />
        <ReadViewInput labelText="Surname" valueText={values?.last_name} />
      </div>
      <div className="flex gap-3 ">
        <ReadViewInput labelText="Date of birth" valueText={values?.dob} />
        <ReadViewInput labelText="Gender" valueText={values?.gender} />
      </div>
      <div className="flex gap-3 ">
        <ReadViewInput
          labelText="Issued date"
          valueText={values?.issued_date}
        />
        <ReadViewInput
          labelText="Expiry date"
          valueText={values?.expiry_date}
        />
      </div>
      <div className="flex gap-3 ">
        <ReadViewInput
          labelText="State of issue"
          valueText={values?.issue_state}
        />
        <DocumentUploadInput
          watch={watch}
          label="Upload front"
          valueKey="front_image"
          readView={true}
          showIIcon={false}
        />
      </div>
    </div>
  );
};
const NationalIdentityReadView = ({ values }) => {
  return (
    <div className="flex flex-col gap-4">
      <div>
        <ReadViewInput
          labelText="NIN"
          valueText={values?.national_identity_number}
        />
      </div>
      <div>
        <ReadViewInput labelText="First name" valueText={values?.first_name} />
      </div>
      <div>
        <ReadViewInput
          labelText="Middle name"
          valueText={values?.middle_name}
        />
      </div>
      <div>
        <ReadViewInput labelText="Surname" valueText={values?.last_name} />
      </div>
      <div>
        <ReadViewInput labelText="Date of birth" valueText={values?.dob} />
      </div>
    </div>
  );
};
const DriversLicenseReadView = ({ values }) => {
  const { user } = useAuth();

  const watch = (key) => {
    if (key === 'front_image' && typeof values?.front_image === 'string') {
      return `${process.env.REACT_APP_BASE_API_URL}${
        user?.company_uuid || user?.uuid
      }/kyc/${values?.front_image}`;
    } else if (key === 'back_image' && typeof values?.back_image === 'string') {
      return `${process.env.REACT_APP_BASE_API_URL}${
        user?.company_uuid || user?.uuid
      }/kyc/${values?.back_image}`;
    } else {
      return values?.[key];
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex gap-3">
        <ReadViewInput
          labelText="License number"
          valueText={values?.licence_number}
        />
        <ReadViewInput labelText="First name" valueText={values?.first_name} />
      </div>
      <div className="flex gap-3">
        <ReadViewInput
          labelText="Middle name"
          valueText={values?.middle_name}
        />
        <ReadViewInput labelText="Surname" valueText={values?.last_name} />
      </div>
      <div className="flex gap-3 ">
        <ReadViewInput labelText="Date of birth" valueText={values?.dob} />
        <ReadViewInput labelText="Gender" valueText={values?.gender} />
      </div>
      <div className="flex gap-3 ">
        <ReadViewInput
          labelText="Issued date"
          valueText={values?.issued_date}
        />
        <ReadViewInput
          labelText="Expiry date"
          valueText={values?.expiry_date}
        />
      </div>
      <div className="flex gap-3 ">
        <ReadViewInput
          labelText="State of issue"
          valueText={values?.issue_state}
        />
      </div>

      <div className="flex gap-3">
        <DocumentUploadInput
          watch={watch}
          label="Upload front"
          valueKey="front_image"
          readView={true}
          showIIcon={false}
        />
        <DocumentUploadInput
          watch={watch}
          label="Upload back"
          valueKey="back_image"
          readView={true}
          showIIcon={false}
        />
      </div>
    </div>
  );
};
const VoterCardReadView = ({ values }) => {
  const { user } = useAuth();
  const watch = (key) => {
    if (key === 'front_image' && typeof values?.front_image === 'string') {
      return `${process.env.REACT_APP_BASE_API_URL}${
        user?.company_uuid || user?.uuid
      }/kyc/${values?.front_image}`;
    } else if (key === 'back_image' && typeof values?.back_image === 'string') {
      return `${process.env.REACT_APP_BASE_API_URL}${
        user?.company_uuid || user?.uuid
      }/kyc/${values?.back_image}`;
    } else {
      return values?.[key];
    }
  };
  return (
    <div className="flex flex-col gap-4">
      <div className="flex gap-3">
        <ReadViewInput labelText="VIN" valueText={values?.voter_number} />
        <ReadViewInput labelText="First name" valueText={values?.first_name} />
      </div>
      <div className="flex gap-3">
        <ReadViewInput
          labelText="Middle name"
          valueText={values?.middle_name}
        />
        <ReadViewInput labelText="Surname" valueText={values?.last_name} />
      </div>
      <div className="flex gap-3 w-1/2 ">
        <ReadViewInput labelText="Date of birth" valueText={values?.dob} />
      </div>

      <div className="flex gap-3">
        <DocumentUploadInput
          watch={watch}
          label="Upload front"
          valueKey="front_image"
          readView={true}
          showIIcon={false}
        />
        <DocumentUploadInput
          watch={watch}
          label="Upload back"
          valueKey="back_image"
          readView={true}
          showIIcon={false}
        />
      </div>
    </div>
  );
};

const BankVerificationReadView = ({ values }) => {
  return (
    <div className="flex flex-col gap-4">
      <div>
        <ReadViewInput
          labelText="BVN"
          valueText={values?.bank_verification_number}
        />
      </div>
      <div>
        <ReadViewInput labelText="First name" valueText={values?.first_name} />
      </div>
      <div>
        <ReadViewInput
          labelText="Middle name"
          valueText={values?.middle_name}
        />
      </div>
      <div>
        <ReadViewInput labelText="Surname" valueText={values?.last_name} />
      </div>
      <div>
        <ReadViewInput labelText="Date of birth" valueText={values?.dob} />
      </div>
    </div>
  );
};

const KYCReadView = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [searchParams] = useSearchParams();
  const { state } = useLocation();
  const { mutate: handleDeleteKYC, isPending: isPendingKycDelete } =
    useDeleteKYC({ handleSuccess });
  const [documentData, setDocumentData] = useState(null);
  const [documentType, setDocumentType] = useState('');
  const [showModal, setShowModal] = useState(initialModalState);
  const [selfieData, setSelfieData] = useState(null);
  const [isKYCInProgress, setIsKYCInProgress] = useState(false);
  const { mutate: addKYCMutate, isPending: addKYCLoad } = useAddKYC({
    handleSuccess,
  });
  const { isLoading: mediaUploadLoad, uploadMedia } = useUploadFile();

  const { data: kycData, isLoading: isLoadingKYCData } = useKYCData();

  useEffect(() => {
    if (searchParams) {
      const documentType = searchParams.get('documentType');
      setDocumentType(documentType);
    }
  }, [searchParams]);

  useEffect(() => {
    if (state && Object.keys(state).length > 0 && !state?.uuid) {
      if (state?.identification_doc) {
        setSelfieData({
          type: state?.identification_type,
          file: state?.identification_doc,
        });
        setIsKYCInProgress(true);
      }
      setDocumentData(state);
    }

    if (kycData && kycData.length > 0) {
      const refKycData =
        kycData?.find((item) => item?.uuid === state?.uuid) || null;
      if (refKycData) {
        if (refKycData?.identification_doc) {
          setSelfieData({
            type: refKycData?.identification_type,
            file: refKycData?.identification_doc,
          });
          setIsKYCInProgress(true);
        }

        setDocumentData({
          ...refKycData?.kyc_details,
          status: refKycData?.status?.toLowerCase(),
          uuid: refKycData?.uuid,
          rejection_reason: refKycData?.rejection_reason,
        });
      }
    }
  }, [state, kycData]);

  const DocumentReadViewLookup = useMemo(() => {
    return {
      [DocumentUploadConstant.VOTERS_CARD]: (
        <VoterCardReadView values={documentData} />
      ),
      [DocumentUploadConstant.BANK_VERIFICATION]: (
        <BankVerificationReadView values={documentData} />
      ),
      [DocumentUploadConstant.DRIVERS_LICENCE]: (
        <DriversLicenseReadView values={documentData} />
      ),
      [DocumentUploadConstant.INTERNATIONAL_PASSPORT]: (
        <PassportReadView values={documentData} />
      ),
      [DocumentUploadConstant.NATIONAL_IDENTITY]: (
        <NationalIdentityReadView values={documentData} />
      ),
    };
  }, [documentData]);

  async function handleSubmit() {
    let selfieName;
    let front_image_name;
    let back_image_name;
    const { front_image = '', back_image = '', ...rest } = documentData;

    if (selfieData && selfieData?.file) {
      const res = await uploadMedia({
        topLeft: false,
        type: 'kyc',
        file: selfieData?.file,
      });
      selfieName = res?.file_name ?? '';
    }

    if (front_image) {
      const res = await uploadMedia({
        topLeft: false,
        type: 'kyc',
        file: front_image,
      });
      front_image_name = res?.file_name ?? '';
    }
    if (back_image) {
      const res = await uploadMedia({
        topLeft: false,
        type: 'kyc',
        file: back_image,
      });
      back_image_name = res?.file_name ?? '';
    }
    const kyc_details = rest;
    if (front_image_name) {
      kyc_details.front_image = front_image_name;
    }
    if (back_image_name) {
      kyc_details.back_image = back_image_name;
    }
    const payload = {
      country: 'NG',
      kyc_doc_type: documentType,
      kyc_details: {
        ...kyc_details,
        identification_doc: selfieName,
        identification_type: selfieData?.type,
      },
      identification_doc: selfieName,
      identification_type: selfieData?.type,
    };
    addKYCMutate(payload);
  }

  const ModalLookup = useMemo(() => {
    return {
      [DocumentUploadConstant.IDENTIFICATION_MODAL]: (
        <UploadIdentificationModal
          handleClose={handleCloseModal}
          onSuccess={handleUploadIdentification}
          openSelfieModal={() => {
            setShowModal({
              isShow: true,
              modalType: DocumentUploadConstant.SELFIE_MODAL,
              elementData: {},
            });
          }}
        />
      ),
      [DocumentUploadConstant.SELFIE_MODAL]: (
        <UploadSelfieModal
          onSuccess={handleUploadIdentification}
          handleClose={handleCloseModal}
        />
      ),
      [DocumentUploadConstant.DELETE_KYC]: (
        <DeleteKYCModal
          showModal={showModal}
          handleClose={handleCloseModal}
          deleteFn={handleDeleteKYC}
          isLoading={isPendingKycDelete}
        />
      ),
    };
  }, [showModal, isPendingKycDelete]);

  function handleUploadIdentification(selfieData) {
    setSelfieData(selfieData);
  }
  function handleCloseModal() {
    setShowModal(initialModalState);
  }

  function handleSuccess() {
    navigate('/KYC/customer-kyc');
  }
  const { isShow, modalType } = showModal;

  if (isLoadingKYCData) {
    return (
      <span className="flex items-center justify-center w-full">
        <Spinner />
      </span>
    );
  }

  return (
    <div className="bg-white w-full h-full overflow-auto px-6 pt-6 pb-[114px]">
      {isKYCInProgress ? null : (
        <div className="text-grey-700 text-sm mb-16">
          Review and confirm uploaded information
        </div>
      )}
      {isKYCInProgress ? (
        <div className="mb-2">
          <div className="flex gap-3 items-center">
            <div className="flex flex-col min-w-[210px]">
              <div
                className={`font-semibold	text-base ${
                  colorLookup[documentData?.status?.toLowerCase()]
                }`}
              >
                Status
              </div>
              <div
                className={`font-medium mt-1 text-base flex ${
                  colorLookup[documentData?.status?.toLowerCase()]
                } `}
              >
                <div className="capitalize">
                  Your request is&nbsp;
                  {documentData?.status?.toLowerCase()?.split('_')?.join(' ')}
                  &nbsp;
                  {documentData?.status
                    ?.toLowerCase()
                    ?.split('_')
                    ?.join(' ') === 'rejected'
                    ? `because of ${documentData?.rejection_reason}`
                    : ''}
                </div>
              </div>
            </div>
            {/* {documentData?.status === 'rejected' ? (
              <Button
                extraClasses="max-h-[40px] max-w-[300px]"
                type="button"
                background="danger"
                onClick={() => {
                  setShowModal({
                    isShow: true,
                    modalType: DocumentUploadConstant.DELETE_KYC,
                    elementData: { uuid: documentData?.uuid },
                  });
                }}
              >
                <div className=" flex items-center">Re-verify</div>
              </Button>
            ) : null} */}
          </div>
        </div>
      ) : null}

      <div className="flex gap-6">
        {!isKYCInProgress ? (
          <div className="rounded-full w-10 min-w-[40px] min-h-[40px] h-10 text-white flex items-center justify-center bg-green">
            <Check />
          </div>
        ) : null}

        <div className="flex flex-col  w-full max-w-[433px]">
          {!isKYCInProgress ? (
            <>
              <div className="text-grey-900 text-lg font-semibold">
                Uploaded documents
              </div>
              <div className="text-grey-700 text-sm mb-8">
                Uploaded relevant data information
              </div>{' '}
            </>
          ) : null}

          {DocumentReadViewLookup?.[documentType]}
          {selfieData ? (
            <>
              <div className="text-grey-900 text-lg font-semibold mt-16">
                Others
              </div>
              <div className="text-grey-700 text-sm mb-8">
                Captured selfie or uploaded identity document
              </div>
              <div>
                <div className="font-medium text-base text-grey-800">
                  Uploaded valid identification
                </div>
                <div className="flex">
                  {selfieData?.file && typeof selfieData?.file === 'string' ? (
                    <img
                      className="rounded-md w-52 h-32"
                      src={`${process.env.REACT_APP_BASE_API_URL}${
                        user?.company_uuid || user?.uuid
                      }/kyc/${selfieData?.file}`}
                      alt="selfie-data"
                    />
                  ) : (
                    <>
                      {selfieData?.file ? (
                        <img
                          className="rounded-md w-52 h-32"
                          src={URL.createObjectURL(selfieData?.file)}
                          alt="selfie-data"
                        />
                      ) : null}
                    </>
                  )}

                  <div
                    className="cursor-pointer"
                    onClick={() =>
                      downloadFile(
                        typeof selfieData?.file === 'string'
                          ? `${process.env.REACT_APP_BASE_API_URL}${
                              user?.company_uuid || user?.uuid
                            }/kyc/${selfieData?.file}`
                          : selfieData?.file,
                        typeof selfieData?.file === 'string',
                      )
                    }
                  >
                    <Download className="text-green" />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      {!isKYCInProgress ? (
        <div className="mt-[100px] ">
          <div className="flex gap-6 ">
            <div className="w-[80px]">
              {addKYCLoad || mediaUploadLoad ? null : (
                <Button
                  extraClasses="mt-10 "
                  type="button"
                  background="transparent"
                  onClick={() =>
                    navigate(`/KYC/customer-kyc?documentType=${documentType}`, {
                      state: documentData,
                    })
                  }
                >
                  <div className="text-black flex items-center">
                    <Arrow className="rotate-180 text-black" /> Back
                  </div>
                </Button>
              )}
            </div>
            {selfieData ? (
              <div className="w-[334px]">
                <Button
                  extraClasses="mt-10"
                  type="button"
                  disabled={addKYCLoad || mediaUploadLoad}
                  onClick={handleSubmit}
                >
                  {addKYCLoad || mediaUploadLoad ? <Spinner /> : 'Submit'}
                </Button>
              </div>
            ) : (
              <div className="w-[334px]">
                <Button
                  extraClasses="mt-10"
                  type="button"
                  onClick={() =>
                    setShowModal({
                      isShow: true,
                      modalType: DocumentUploadConstant.IDENTIFICATION_MODAL,
                      elementData: {},
                    })
                  }
                >
                  Continue
                </Button>
              </div>
            )}
          </div>
        </div>
      ) : null}
      {isShow ? (
        <Modal
          handleClose={handleCloseModal}
          headerComponent={null}
          footerComponent={null}
          shouldCloseOnClickOutside={false}
        >
          {ModalLookup[modalType]}
        </Modal>
      ) : null}
    </div>
  );
};

export default KYCReadView;
