import React from 'react';
import AutoReplyInput from './AutoReplyInput';
import { Controller } from 'react-hook-form';
import SwitchInput from 'components/SwitchInput';

const AdvanceSettings = ({ watch, control, setValue }) => {
  const isDeleteMessageEnabled = watch('delete_sms') === 'Y';
  //   console.log(watch('delete_sms'));
  return (
    <>
      <div className="flex flex-col gap-1">
        <div className="text-grey-900 text-base font-semibold">
          Auto-Replies
        </div>
        <div className="text-grey-700 text-sm font-medium">
          Set up automatic text message replies based on different triggers on
          your phone number
        </div>
      </div>
      <div className="flex flex-col gap-1 mt-6">
        <div className="text-grey-900 text-base font-semibold">
          During business hour
        </div>
        <div className="flex flex-col gap-4 mt-2">
          <AutoReplyInput
            inputLabel="Set incoming message auto-reply"
            messageLabel="On incoming message"
            valueKey={'during_business_hour.incoming_reply'}
            control={control}
            watch={watch}
            setValue={setValue}
          />

          <AutoReplyInput
            inputLabel="Set missed call (no voicemail) auto-reply"
            messageLabel="On missed call (no voicemail)"
            valueKey={'during_business_hour.no_voicemail_reply'}
            control={control}
            watch={watch}
            setValue={setValue}
          />

          <AutoReplyInput
            inputLabel="Set missed call (with voicemail) auto-reply"
            messageLabel="On missed call (with voicemail)"
            valueKey={'during_business_hour.voicemail_reply'}
            control={control}
            watch={watch}
            setValue={setValue}
          />
        </div>
      </div>
      <div className="flex flex-col gap-1 mt-7">
        <div className="text-grey-900 text-base font-semibold">
          After business hours
        </div>
        <div className="flex flex-col gap-4 mt-2">
          <AutoReplyInput
            inputLabel="Set incoming message auto-reply"
            messageLabel="On incoming message"
            valueKey={'closed_business_hour.incoming_reply'}
            control={control}
            watch={watch}
            setValue={setValue}
          />
          <AutoReplyInput
            inputLabel="Set missed call (no voicemail) auto-reply"
            messageLabel="On missed call (no voicemail)"
            valueKey={'closed_business_hour.no_voicemail_reply'}
            control={control}
            watch={watch}
            setValue={setValue}
          />
          <AutoReplyInput
            inputLabel="Set missed call (with voicemail) auto-reply"
            messageLabel="On missed call (with voicemail)"
            valueKey={'closed_business_hour.voicemail_reply'}
            control={control}
            watch={watch}
            setValue={setValue}
          />
        </div>
      </div>
      <div className="flex justify-between mt-7">
        <div className="flex flex-col gap-1">
          <div className="text-grey-900 text-base font-semibold">
            {isDeleteMessageEnabled
              ? 'Disable delete SMS'
              : 'Enable delete SMS'}
          </div>
          <div className="text-grey-700 text-sm font-medium">
            {isDeleteMessageEnabled
              ? 'Disable the delete SMS option'
              : 'Enable to opt the delete SMS option'}
          </div>
        </div>

        <Controller
          name={'delete_sms'}
          control={control}
          render={({ field }) => (
            <SwitchInput
              {...field}
              name="delete_sms"
              label={''}
              onChange={(e) => {
                field.onChange(e.target.checked ? 'Y' : 'N');
              }}
              checked={watch('delete_sms') === 'Y'}
            />
          )}
        />
      </div>
    </>
  );
};
export default AdvanceSettings;
