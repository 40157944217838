import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Input, Spinner } from 'components';
import useForgotPassword from 'hooks/useForgotPassword';
import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { getRequiredText, passwordRegx } from 'shared/resources';
import * as yup from 'yup';
import { useParams } from 'react-router-dom';

const validationSchema = yup.object().shape({
  password: yup
    .string()
    .required(getRequiredText('Password'))
    .matches(
      passwordRegx,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character i.e Demo@123.',
    ),
  confirm_password: yup
    .string()
    .required(getRequiredText('Confirm password'))
    .when('password', {
      is: (password) => password && password.length > 0,
      then: yup.string().oneOf([yup.ref('password')], 'Passwords do not match'),
    }),
});

const SetPassword = () => {
  const { token } = useParams();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      password: '',
      confirm_password: '',
    },
    mode: 'onChange',
  });

  const { mutate: setPasswordMutate, isPending: setPasswordLoad } =
    useForgotPassword();
  const onSubmit = (values) => {
    const payload = {
      ...values,
      token: token,
      template_type: 'afterSignUpMember',
    };
    setPasswordMutate(payload);
  };

  return (
    <>
      <div className="flex flex-col w-full max-w-[433px]">
        <div className="xxl:text-3xl xl:text-[28px] lg:text-[24px] md:text-[22px] text-grey-900 font-medium">
          Set password
        </div>
        <div className="xxl:text-base xl:text-[15px] lg:text-[15px] md:text-[14px] font-normal text-grey-700 xxl:mt-[.1em] ">
          Ensure its 8 characters long and something only you can remember
        </div>
      </div>
      <form
        autoComplete="off"
        className="mt-8 w-full"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="w-full">
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                type="password"
                autoComplete="off"
                placeholder="Min. of 8 characters"
                label="Password"
                error={errors?.password?.message}
              />
            )}
          />
        </div>
        <div className="w-full mt-2">
          <Controller
            name="confirm_password"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                type="password"
                autoComplete="off"
                placeholder="Min. of 8 characters"
                label="Confirm password"
                error={errors?.confirm_password?.message}
              />
            )}
          />
        </div>
        <div className="w-full mt-8">
          <Button type="submit" disabled={setPasswordLoad}>
            {setPasswordLoad ? <Spinner /> : 'Continue'}
          </Button>
        </div>
      </form>
    </>
  );
};

export default SetPassword;
