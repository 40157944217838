import { Arrow, Edit, Lightning } from 'assets/images';
import { Button } from 'components';
import React, { useState } from 'react';

const AutoReplyInput = ({
  inputLabel = '',
  messageLabel = '',
  valueKey = '',
  watch,
  setValue,
}) => {
  const [showInput, setShowInput] = useState(false);
  const [message, setMessage] = useState('');

  const onSave = (message = '') => {
    setValue(valueKey, message);
    setShowInput(false);
  };
  const onCancel = () => {
    setMessage('');
    setShowInput(false);
  };

  const isValueExist = Boolean(watch(valueKey));
  return (
    <>
      {showInput ? (
        <div className="border border-grey-400 rounded-md bg-grey-100  px-6 pt-[15px] pb-5 ">
          <div className="flex flex-col gap-1">
            <div className="text-grey-800 xxl:text-base xl:text-sm	lg:text-sm md:text-xs font-medium">
              {messageLabel}
            </div>
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              cols="30"
              rows="5"
              className="w-full border  rounded-md  focus:outline-none focus:ring font-medium text-base text-grey-900 resize-none border-grey-400 focus:border-green focus:ring-green-200"
            ></textarea>
          </div>
          <div className="flex justify-end gap-2 mt-6">
            <div className="w-32 ">
              <Button
                background="transparent"
                height="xxl:h-[50px] xl:h-[45px] lg:h-[45px] md:h-[41px]"
                border="border-none"
                type="button"
                onClick={() => onCancel()}
              >
                <div className="flex w-full text-sm text-grey-900 justify-center gap-2 items-center">
                  <Arrow className="rotate-180 text-black" />
                  Cancel
                </div>
              </Button>
            </div>
            <div className="w-[191px]">
              <Button
                height="xxl:h-[50px] xl:h-[45px] lg:h-[45px] md:h-[41px]"
                type="button"
                onClick={() => onSave(message)}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <>
          {isValueExist ? (
            <div className="bg-green-100 rounded-md px-6 pt-[15px] pb-6 flex flex-col gap-2">
              <span className="text-green text-base font-semibold">
                {messageLabel}
              </span>
              <div className="flex w-full justify-between items-end group gap-2">
                <div className="text-green  text-base break-all">
                  {watch(valueKey)}
                </div>
                <span
                  className="cursor-pointer invisible  group-hover:visible"
                  onClick={() => {
                    setMessage(watch(valueKey));
                    setShowInput(true);
                  }}
                >
                  <Edit className="text-grey-700" />
                </span>
              </div>
            </div>
          ) : (
            <div
              className="border group hover:bg-green hover:text-white border-grey-400 rounded-md px-6 py-3 cursor-pointer flex justify-between"
              onClick={() => setShowInput(true)}
            >
              {inputLabel}
              <div className="group-hover:text-white">
                <Lightning height={22} />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default AutoReplyInput;
