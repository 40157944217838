import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery } from "@tanstack/react-query";
import { getWalletAmounts } from "api";
import { Mastercard, visa } from "assets/images";
import { Button, Modal, Skeletons, Spinner } from "components";
// import CustomSelect from "components/CustomSelect";
// import SwitchInput from "components/SwitchInput";
import { useCheckoutWallet, useWalletDetails } from "hooks";
import useSavedCards from "hooks/useSavedCards";
import React, { useEffect, useState, useMemo } from "react";
import { useForm, Controller } from "react-hook-form";
import { NG_CURRENCY_SYMBOL } from "shared/resources";
import * as yup from "yup";
import { dropdownActions, initialModalState } from "./constants";
import PaymentConfirmationModal from "components/PaymentConfirmationModal";

const PaymentMethodArray = [
  {
    label: "New payment method",
    type: "NEW_CARD",
  },
  {
    label: "Pay with Saved cards",
    type: "SAVED_CARD",
  },
];

const cardPlaceholderImage = {
  visa: visa,
  mastercard: Mastercard,
};

// const amountArray = [
//   { label: `${NG_CURRENCY_SYMBOL}1000`, value: 1000 },
//   { label: `${NG_CURRENCY_SYMBOL}2000`, value: 2000 },
//   { label: `${NG_CURRENCY_SYMBOL}5000`, value: 5000 },
//   { label: `${NG_CURRENCY_SYMBOL}10000`, value: 10000 },
// ];

// const runAtAmountsArray = [
//   { label: `${NG_CURRENCY_SYMBOL}100`, value: 100 },
//   { label: `${NG_CURRENCY_SYMBOL}500`, value: 500 },
//   { label: `${NG_CURRENCY_SYMBOL}1000`, value: 1000 },
//   { label: `${NG_CURRENCY_SYMBOL}2000`, value: 2000 },
// ];

// const amountItem = ({ item }) => {
//   return (
//     <div
//       key={item.value}
//       value={item?.value}
//       className="w-full h-full flex  justify-start gap-2 items-center py-2 px-3"
//     >
//       {item?.label}
//     </div>
//   );
// };

const CardItem = ({ data, setSelectedCard, selectedCard }) => {
  useEffect(() => {
    return () => {
      setSelectedCard("");
    };
  }, []);

  return (
    <div
      className="w-full flex flex-col gap-1 cursor-pointer"
      onClick={() => setSelectedCard(data?.uuid)}
    >
      <div className="flex items-center px-4 h-14  justify-between w-full bg-green-50 rounded-md border border-green-100">
        <div className="text-md flex gap-2 items-center ">
          <img
            src={cardPlaceholderImage[data?.card_type?.trim()?.toLowerCase()]}
            alt="MasterCard"
            className="w-8"
          />
          ****{data?.last4}
        </div>
        <div className="flex items-center gap-2">
          <input
            className="checked:bg-green checked:hover:bg-green checked:active:bg-green checked:focus:bg-green focus:outline-none focus:ring-1 focus:ring-green cursor-pointer"
            type="radio"
            checked={data?.uuid === selectedCard}
            onChange={() => setSelectedCard(data?.uuid)}
          />
        </div>
      </div>
    </div>
  );
};

export const validationSchema = yup.object({
  custom_amount: yup
    .number()
    .min(1000, "Minimum value should be 1000")
    .max(50000, "Maximum value should be 50000"),
});

const Wallet = () => {
  const { cardListing, isLoading } = useSavedCards();
  const { data: walletDetails } = useWalletDetails();
  const { data, isLoading: walletAmountLoad } = useQuery({
    queryFn: getWalletAmounts,
    queryKey: ["getWalletAmounts"],
  });
  const [showModal, setShowModal] = useState(initialModalState);

  const [showCustomAmount, setShowCustomAmount] = useState(false);
  const { mutate: checkoutWallet, isPending: checkoutWalletLoad } =
    useCheckoutWallet();
  // const { mutate: walletAutoTopUpMutate, isPending: walletAutoTopUpLoad } =
  //   useWalletAutoTopup();

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      amount: null,
      payment_type: "NEW_CARD",
      is_saved_card: "N",
      card_uuid: "",
      is_auto_recharge: "N",
      run_at_amt: 100,
      auto_recharge_amt: 1000,
      custom_amount: 1000,
      source: "PAYSTACK",
    },
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (
      cardListing &&
      cardListing?.length > 0 &&
      watch("payment_type") === "SAVED_CARD"
    ) {
      const defaultCard = cardListing?.find((item) => item?.is_default === "Y");
      setValue("card_uuid", defaultCard?.uuid || "");
    }
  }, [cardListing?.length, watch("payment_type")]);

  useEffect(() => {
    if (data?.data?.data?.auto_topup) {
      if (data?.data?.data?.auto_topup?.is_auto_recharge === "Y") {
        setValue(
          "is_auto_recharge",
          data?.data?.data?.auto_topup?.is_auto_recharge
        );
        setValue(
          "run_at_amt",
          Number(data?.data?.data?.auto_topup?.run_at_amt)
        );
        setValue(
          "auto_recharge_amt",
          Number(data?.data?.data?.auto_topup?.auto_recharge_amt)
        );
      }
    }
  }, [data?.data?.data?.auto_topup]);

  const ModalLookup = useMemo(() => {
    return {
      [dropdownActions.PURCHASE_CONFIRMATION]: (
        <PaymentConfirmationModal
          setValue={setValue}
          handleClose={handleModalClose}
          continueCallBack={handleSubmit(onSubmit)}
        />
      ),
    };
  }, [showModal]);

  function handleModalClose() {
    setShowModal(initialModalState);
  }

  function onSubmit(values) {
    console.log(values);
    checkoutWallet({
      payment_type: values?.payment_type,
      is_saved_card: values?.is_saved_card,
      card_uuid: values?.card_uuid,
      amount: showCustomAmount ? values?.custom_amount : values?.amount,
      source: values?.source,
    });
  }

  const amountsArr = data?.data?.data?.wallet_amounts
    ? data?.data?.data?.wallet_amounts?.split(",")
    : [];
  const { isShow, modalType } = showModal;

  return (
    <div className="overflow-auto w-full px-6 pt-6 pb-6">
      <div className="flex items-center justify-between mb-5">
        <div className="text-sm text-grey-700">
          Manage wallet in your workspace
        </div>
      </div>
      <form
        className="w-full flex flex-col gap-1"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="w-full rounded-md md:py-3 md:px-7 xl:p-7 border border-grey-400 bg-white min-h-[calc(100vh_-_168px)] ">
          <div className="w-full flex  flex-col  max-w-7xl mx-auto">
            <div className="w-full text-lg font-semibold mb-2">
              Recharge amount
            </div>
            <div className="flex w-full ">
              <div className=" flex w-full md:gap-5 xxl:gap-12  ">
                <div className="grid grid-cols-3 gap-4 w-full">
                  {walletAmountLoad
                    ? Array.from({ length: 3 }, (_, i) => i + 1).map((val) => {
                        return (
                          <div className="w-full" key={val}>
                            <Skeletons height="h-14" />
                          </div>
                        );
                      })
                    : amountsArr.map((val) => {
                        return (
                          <label
                            key={val}
                            className="w-full border border-grey-400 rounded-md p-3 h-20 flex items-center justify-between cursor-pointer"
                          >
                            <div className="text-xl font-medium">
                              {NG_CURRENCY_SYMBOL}
                              {val}
                            </div>
                            <Controller
                              name={"amount"}
                              control={control}
                              render={({ field }) => {
                                return (
                                  <input
                                    {...field}
                                    className="checked:bg-green checked:hover:bg-green checked:active:bg-green checked:focus:bg-green focus:outline-none focus:ring-1 focus:ring-green"
                                    type="radio"
                                    id={val}
                                    value={val}
                                    onChange={(e) => {
                                      setShowCustomAmount(false);
                                      return field.onChange(e.target.value);
                                    }}
                                    checked={watch("amount") === val}
                                  />
                                );
                              }}
                            />
                          </label>
                        );
                      })}
                  <>
                    <label
                      htmlFor="showCustomAmount"
                      className="w-full border border-grey-400 rounded-md p-3 h-20 flex items-center justify-between cursor-pointer"
                    >
                      <div className="text-xl font-medium flex items-center gap-2">
                        {NG_CURRENCY_SYMBOL}
                        <div>
                          <input
                            className="border-grey-400 rounded-md focus:border-green"
                            type="number"
                            value={watch("custom_amount")}
                            min={1000}
                            max={50000}
                            onChange={(e) => {
                              setValue("custom_amount", e.target.value);
                            }}
                          />
                          <div className="flex  flex-col">
                            {errors?.custom_amount?.message ? (
                              <small className="text-danger text-xs	font-normal mt-1">
                                {errors?.custom_amount?.message}
                              </small>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <input
                        className="checked:bg-green checked:hover:bg-green checked:active:bg-green checked:focus:bg-green focus:outline-none focus:ring-1 focus:ring-green"
                        type="radio"
                        id={"showCustomAmount"}
                        value={showCustomAmount}
                        onChange={(e) => {
                          setValue("amount", 0);
                          return setShowCustomAmount(e.target.checked);
                        }}
                        checked={showCustomAmount}
                      />
                    </label>
                  </>
                </div>

                <div className="w-full max-w-xs border border-grey-400 rounded-md h-full p-4 gap-1 flex flex-col items-center justify-center">
                  <div className="text-sm text-grey-800">Wallet balance</div>
                  <div className="text-lg">
                    {NG_CURRENCY_SYMBOL}
                    {walletDetails?.accountBalance || 0}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex w-full gap-5 mt-8">
              {PaymentMethodArray.map((item) => {
                return (
                  <label
                    htmlFor={`payment_type_${item?.type}`}
                    key={item?.type}
                    className="flex items-center gap-1 font-medium text-grey-800 cursor-pointer"
                  >
                    <Controller
                      name={"payment_type"}
                      control={control}
                      render={({ field }) => {
                        return (
                          <input
                            className="checked:bg-green checked:hover:bg-green checked:active:bg-green checked:focus:bg-green focus:outline-none focus:ring-1 focus:ring-green"
                            type="radio"
                            {...field}
                            id={`payment_type_${item?.type}`}
                            value={item?.type}
                            checked={item?.type === watch("payment_type")}
                          />
                        );
                      }}
                    />
                    {item?.label}
                  </label>
                );
              })}
            </div>
            {watch("payment_type") === "SAVED_CARD" ? (
              <div className="max-w-[600px] max-h-36 mt-4 mb-2 overflow-auto w-full flex flex-col gap-2">
                {isLoading
                  ? Array.from({ length: 3 }, (_, i) => i + 1).map((val) => {
                      return (
                        <div className="w-full" key={val}>
                          <Skeletons height="h-14" />
                        </div>
                      );
                    })
                  : cardListing?.length > 0
                  ? cardListing?.map((card) => (
                      <CardItem
                        key={card?.uuid}
                        data={card}
                        setSelectedCard={(val) => setValue("card_uuid", val)}
                        selectedCard={watch("card_uuid")}
                      />
                    ))
                  : "No saved cards"}
              </div>
            ) : null}
            {watch("payment_type") === "NEW_CARD" ? (
              <div className="w-full  mt-4 md:mb-2 lg:mb-5 xxl:mb-32  gap-2 flex items-center">
                <input
                  className="checked:bg-green checked:hover:bg-green checked:active:bg-green checked:focus:bg-green focus:outline-none focus:ring-1 focus:ring-green"
                  type="checkbox"
                  id="is_saved_card"
                  checked={watch("is_saved_card") === "Y"}
                  onChange={(e) => {
                    setValue("is_saved_card", e.target.checked ? "Y" : "N");
                  }}
                />
                <label className="cursor-pointer" htmlFor="is_saved_card">
                  Do you want to save this card for future use
                </label>
              </div>
            ) : null}

            <div className="w-full justify-center flex mt-6">
              <Button
                type="button"
                extraClasses="min-w-[300px] max-w-[300px]"
                disabled={
                  (!watch("amount") && !showCustomAmount) ||
                  (watch("payment_type") === "SAVED_CARD" &&
                    !watch("card_uuid")) ||
                  checkoutWalletLoad
                }
                onClick={() =>
                  setShowModal({
                    modalType: dropdownActions.PURCHASE_CONFIRMATION,
                    isShow: true,
                    elementData: {},
                  })
                }
              >
                {checkoutWalletLoad ? <Spinner /> : "Add to wallet"}
              </Button>
            </div>
            {/* <div className="w-full flex mt-4 gap-8 items-center">
              <Controller
                name={"is_auto_recharge"}
                control={control}
                render={({ field }) => (
                  <div className="flex gap-2 items-center">
                    <SwitchInput
                      {...field}
                      name={"is_auto_recharge"}
                      onChange={(e) => {
                        walletAutoTopUpMutate({
                          is_auto_recharge: e.target.checked ? "Y" : "N", // Y, N
                          auto_recharge_amt: watch("auto_recharge_amt"),
                          run_at_amt: watch("run_at_amt"),
                        });
                        field.onChange(e.target.checked ? "Y" : "N");
                      }}
                      value={watch("is_auto_recharge") === "Y"}
                      checked={watch("is_auto_recharge") === "Y"}
                      disabled={walletAutoTopUpLoad}
                    />
                    <label
                      htmlFor={"is_auto_recharge"}
                      className="ms-3 text-sm font-medium text-grey-900 cursor-pointer"
                    >
                      Auto topup
                    </label>
                  </div>
                )}
              />
              <div className="text-grey-600">
                Auto recharge when wallet balance is less than
              </div>
              <div className="w-full max-w-[100px]">
                <Controller
                  name={"run_at_amt"}
                  control={control}
                  render={({ field }) => (
                    <CustomSelect
                      {...field}
                      optionsList={runAtAmountsArray}
                      ItemComponent={amountItem}
                      valueKey={"value"}
                      labelKey={"label"}
                      onWhitebg={true}
                    />
                  )}
                />
              </div>
              for
              <div className="w-full max-w-[100px]">
                <Controller
                  name={"auto_recharge_amt"}
                  control={control}
                  render={({ field }) => (
                    <CustomSelect
                      {...field}
                      optionsList={amountArray}
                      ItemComponent={amountItem}
                      valueKey={"value"}
                      labelKey={"label"}
                      onWhitebg={true}
                    />
                  )}
                />
              </div>
            </div> */}
          </div>
        </div>
        {isShow ? (
          <Modal
            handleClose={handleModalClose}
            headerComponent={null}
            footerComponent={null}
            shouldCloseOnClickOutside={false}
          >
            {ModalLookup[modalType]}
          </Modal>
        ) : null}
      </form>
    </div>
  );
};

export default Wallet;
