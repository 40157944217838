import { useQuery } from '@tanstack/react-query';
import { getDashboardStats } from 'api';

export default function useDashboardStats({ statsType = 'weekly' }) {
  const Request = useQuery({
    queryFn: ({ queryKey }) => getDashboardStats(queryKey[1] || {}),
    queryKey: ['getDashboardStatsWithCache', { stats_filter: statsType }],
    refetchOnMount: false,
  });

  return { ...Request };
}
