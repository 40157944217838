import { useQuery } from '@tanstack/react-query';
import { myBundleList } from 'api';

export default function useMyBundles() {
  const Request = useQuery({
    queryFn: myBundleList,
    queryKey: ['myBundleList'],
    refetchOnMount: false,
    select: (data) => data?.data?.data,
  });

  return { ...Request };
}
