export const dropdownActions = {
  PLAY_GREETING: 'play_greeting',
  BULK_DELETE_VOICEMAIL: 'bulk_delete_voicemail',
};
export const initialModalState = {
  modalType: '',
  isShow: false,
  elementData: {},
  type: '',
};
