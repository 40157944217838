import React, { useEffect } from 'react';
import MicRecorder from 'mic-recorder-to-mp3';
import moment from 'moment';
import Timer from 'components/Timer';

let mediaRecorder = new MicRecorder({ bitRate: 128 });

const Recorder = ({ getAudioBlob = (data) => data }) => {
  useEffect(() => {
    getMicrophone();
    return () => stopMicrophone();
  }, []);

  const getMicrophone = async () => {
    try {
      mediaRecorder
        .start()
        .then()
        .catch((e) => {
          console.error(e);
        });
    } catch (err) {
      console.log('could not start audio');
    }
  };

  const stopMicrophone = async () => {
    mediaRecorder
      .stop()
      .getMp3()
      .then(([buffer, blob]) => {
        getAudioBlob({ buffer, blob });
      })
      .catch((e) => {
        alert('We could not retrieve your message');
        console.error(e);
      });
  };

  return (
    <div>
      <Timer seconds={moment()} />
    </div>
  );
};

export default Recorder;
