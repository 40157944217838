import { DialerCall } from 'assets/images';
import React from 'react';

export default function Digits({
  onPresshandler = () => null,
  handleMakeCall = () => null,
}) {
  return (
    <div className="flex select-none flex-col justify-between  h-full xxl:gap-4 xl:gap-2 lg:gap-2 md:gap-2 xxl:px-8 xl:px-7 lg:px-4 md:px-7 xxl:mt-8 xl:mt-3 lg:mt-3 md:mt-2 xxl:pb-8 xl:pb-4 lg:pb-4 md:pb-4 xxl:max-h-[417px] xl:max-h-[307px] lg:max-h-[307px] md:max-h-[307px] xxl:min-h-[496px] xl:min-h-[420px] lg:min-h-[420px] md:min-h-[420px]">
      <div className="flex items-center justify-around px-5">
        <div
          className="text-3xl  text-white  flex items-center justify-center cursor-pointer w-16 h-16 rounded-full     "
          onClick={() => onPresshandler('1')}
        >
          1
        </div>
        <div
          className="text-3xl text-white  flex items-center justify-center cursor-pointer w-16 h-16 rounded-full     "
          onClick={() => onPresshandler('2')}
        >
          2
        </div>
        <div
          className="text-3xl text-white  flex items-center justify-center cursor-pointer w-16 h-16 rounded-full     "
          onClick={() => onPresshandler('3')}
        >
          3
        </div>
      </div>
      <div className="flex items-center justify-around px-5">
        <div
          className="text-3xl text-white  flex items-center justify-center cursor-pointer w-16 h-16 rounded-full     "
          onClick={() => onPresshandler('4')}
        >
          4
        </div>
        <div
          className="text-3xl text-white  flex items-center justify-center cursor-pointer w-16 h-16 rounded-full     "
          onClick={() => onPresshandler('5')}
        >
          5
        </div>
        <div
          className="text-3xl text-white  flex items-center justify-center cursor-pointer w-16 h-16 rounded-full     "
          onClick={() => onPresshandler('6')}
        >
          6
        </div>
      </div>
      <div className="flex items-center justify-around px-5">
        <div
          className="text-3xl text-white  flex items-center justify-center cursor-pointer w-16 h-16 rounded-full     "
          onClick={() => onPresshandler('7')}
        >
          7
        </div>
        <div
          className="text-3xl text-white  flex items-center justify-center cursor-pointer w-16 h-16 rounded-full     "
          onClick={() => onPresshandler('8')}
        >
          8
        </div>
        <div
          className="text-3xl text-white  flex items-center justify-center cursor-pointer w-16 h-16 rounded-full     "
          onClick={() => onPresshandler('9')}
        >
          9
        </div>
      </div>
      <div className="flex items-center justify-around px-5">
        <div
          className="text-5xl  text-white  flex items-center justify-center cursor-pointer w-16 h-16 rounded-full     "
          onClick={() => onPresshandler('*')}
        >
          <span className="mt-3"> *</span>
        </div>
        <div
          className="text-3xl text-white  flex items-center justify-center  cursor-pointer w-16 h-16 rounded-full     "
          onClick={() => onPresshandler('0')}
        >
          <div className="flex flex-col justify-center items-center mt-8 leading-3	gap-2">
            0<span className="text-base"> +</span>
          </div>
        </div>
        <div
          className="text-2xl text-white  flex items-center justify-center cursor-pointer w-16 h-16 rounded-full     "
          onClick={() => onPresshandler('#')}
        >
          <span className="">#</span>
        </div>
      </div>
      {/* <div className="flex items-center justify-around px-5">
        <div className="text-3xl text-white  flex items-center justify-center cursor-pointer w-16 h-16">
          &nbsp;
        </div>
        <div
          className="text-3xl text-white  flex items-center justify-center cursor-pointer w-16 h-16 rounded-full     "
          onClick={() => onPresshandler('+')}
        ></div>
      </div> */}

      <div className="flex items-center justify-center px-5 mt-3">
        <div
          className="flex items-center justify-center  cursor-pointer"
          onClick={() => handleMakeCall()}
        >
          <DialerCall className="xxl:w-16 xl:w-16 lg:w-16 md:w-16" />
        </div>
      </div>
    </div>
  );
}
