import { Skeletons } from 'components';
import useIVRListWithCache from 'hooks/useIVRListWithCache';
import React, { useEffect, useState } from 'react';

const initialName = 'Unknown name';

const GetIVRName = ({ id = '' }) => {
  const [name, setName] = useState(initialName);
  const { data, isPending } = useIVRListWithCache();

  useEffect(() => {
    if (id && data?.data?.data.length > 0) {
      const list = data?.data?.data;
      const requestedName = list.find((obj) => obj?.uuid === id)?.name ?? null;
      if (requestedName) {
        setName(requestedName);
      } else {
        setName(initialName);
      }
    }
  }, [id, data?.data?.data]);

  return <>{isPending ? <Skeletons /> : name}</>;
};

export default GetIVRName;
