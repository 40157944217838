import React, { useContext, useEffect } from "react";
import { SipSocketContext } from "hooks/useSip/SipSocketContext";
import { IncomingRing } from "shared/resources";
import { CutCall, DialerCall } from "assets/images";


const RingingScreen = ({ activeCallData = {} }) => {
  const { socket = {} } = useContext(SipSocketContext);
  const { _status = "", callID = "", _direction = "" } = activeCallData;
  const { _terminate = () => null, _answerCall = () => null } = socket;

  useEffect(() => {
    if (["ringing"].includes(_status)) {
      IncomingRing.play();
    } else {
      IncomingRing.pause();
    }
    return () => {
      IncomingRing.pause();
    };
  }, [_status]);

  return (
    <div className="flex items-end justify-center h-full xxl:gap-8 xl:gap-4 xxl:px-8 xl:px-7 xxl:mt-8 xl:mt-3 xxl:pb-8 xl:pb-4 xxl:min-h-[500px] xxl:max-h-[500px] xl:min-h-[404px] xl:max-h-[404px] lg:min-h-[390px] lg:max-h-[390px] md:min-h-[404px] md:max-h-[404px]">
      <div className="flex items-center justify-around w-full">
        <div
          className="flex items-center flex-col gap-2 justify-center cursor-pointer"
          onClick={() => {
            _terminate(callID);
          }}
        >
          <CutCall className="xxl:w-16 xl:w-16 lg:w-16 md:w-16" />
        </div>
        {_direction === "inbound" && (
          <div
            className="flex items-center flex-col gap-2 justify-center cursor-pointer"
            onClick={() => {
              _answerCall(callID);
            }}
          >
            <DialerCall className="xxl:w-16 xl:w-16 lg:w-16 md:w-16 cursor-pointer" />
          </div>
        )}
      </div>
    </div>
  );
};

export default RingingScreen;
