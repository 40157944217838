import { X, RemoveUser } from 'assets/images';
import { Button, Spinner } from 'components';
import React from 'react';

const BulkDeleteCallsModal = ({
  handleClose = () => null,
  deleteFn = () => null,
  isLoading = false,
  data = [],
}) => {
  return (
    <>
      <div className="flex justify-end items-center p-4">
        <button type="button" onClick={() => handleClose()}>
          <X className="text-grey-600 w-6 h-6" />
        </button>
      </div>
      <div className="text-center px-16 pb-5 justify-center">
        <div className="flex justify-center  mt-8">
          <RemoveUser className="text-black" />
        </div>
        <div className="text-4xl text-black font-semibold	 mt-4 mb-1">
          Delete Records
        </div>
        <div className="text-grey-700 text-base	mt-4 w-96">
          Are you sure you want to delete&nbsp;
          <span className="font-medium">{data?.length}</span>&nbsp;records ?
        </div>
        <div className="mt-10 pb-14">
          <Button
            disabled={isLoading}
            background="danger"
            type="button"
            onClick={() =>
              deleteFn({
                type: 'call',
                uuids: data,
              })
            }
          >
            <div className="flex w-full px-5 justify-center items-center">
              {isLoading ? <Spinner /> : 'Delete'}
            </div>
          </Button>
        </div>
      </div>
    </>
  );
};

export default BulkDeleteCallsModal;
