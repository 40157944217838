import { useQuery } from '@tanstack/react-query';
import { userDetails } from 'api';
import { useAuth } from 'hooks/useAuth';

export default function useUserDetails(SIP = {}) {
  const { setUser, setIsUserLoading, user } = useAuth();
  const { isError, isSuccess, data, isLoading, isFetching, isPending } =
    useQuery({
      queryFn: userDetails,
      queryKey: ['userDetails'],
      enabled: !user,
    });

  if (isLoading || isFetching || isPending) {
    setIsUserLoading(true);
  }
  if (isError) {
    setUser(null);
  }

  if (isSuccess) {
    if (!user) {
      setUser(data?.data?.data);
    }
    if (
      SIP &&
      SIP?._status !== 'registered' &&
      Object.keys(SIP).includes('_start')
    ) {
      SIP?._start();
    }
  }
}
