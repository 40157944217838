import {
  ExcelFileIcon,
  GeneralFileIcon,
  PDFIcon,
  TextFileIcon,
  WordFileIcon,
} from 'assets/images';
import CustomAudioPlayer from 'components/CustomAudioPlayer';
import EllipsisTextWithTooltip from 'components/EllipsisTextWithTooltip';
import Skeletons from 'components/Skeletons';
import React, { useEffect, useRef, useState } from 'react';

const loader = () => (
  <div className="w-9 h-9 rounded-full">
    <Skeletons type="circle" />
  </div>
);

const renderIcon = (type) => {
  switch (type) {
    case 'csv':
    case 'xls':
    case 'xlsx':
    case 'ms-excel':
    case 'msexcel':
      return (
        <>
          <ExcelFileIcon className="h-8 w-8 text-green" />
          <span className="uppercase text-xs text-green font-semibold">
            {type}
          </span>
        </>
      );

    case 'pdf':
      return (
        <>
          <PDFIcon className="h-8 w-8 text-danger" />
          <span className="uppercase text-xs text-danger font-semibold">
            {type}
          </span>
        </>
      );

    case 'msword':
    case 'ms-word':
    case 'docx':
    case 'doc':
    case 'rtf':
      return (
        <>
          <WordFileIcon className="h-8 w-8 text-blue-fb" />
          <span className="uppercase text-xs text-blue-fb font-semibold">
            {type}
          </span>
        </>
      );
    case 'txt':
      return (
        <>
          <TextFileIcon className="h-8 w-8 text-grey-600" />
          <span className="uppercase text-xs text-grey-600 font-semibold">
            {type}
          </span>
        </>
      );
    default:
      return (
        <>
          <GeneralFileIcon className="h-8 w-8 text-grey-800" />
          <span className="uppercase text-xs text-grey-800 font-semibold">
            {type}
          </span>
        </>
      );
  }
};

const ImagePreview = ({ file }) => {
  const [preview, setPreview] = useState(null);

  useEffect(() => {
    if (file) {
      const objectUrl = URL.createObjectURL(file);
      setPreview(objectUrl);
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [file]);

  return (
    <div className="flex flex-col">
      <div className="border border-grey-400 flex items-center justify-center rounded-md">
        <img
          src={preview}
          alt="file-preview"
          className="object-contain h-20 w-20 p-1"
        />
      </div>
      <EllipsisTextWithTooltip string={file?.name} charLength={10} />
    </div>
  );
};

const VideoPreview = ({ file }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      const videoURL = URL.createObjectURL(file);
      videoRef.current.src = videoURL;
      videoRef.current.load();
      videoRef.current.onloadeddata = function () {
        videoRef.current.play();
      };
    }
  }, [videoRef, file]);

  return (
    <div className="flex flex-col">
      <div className="border border-grey-400 flex items-center justify-center rounded-md">
        <video
          ref={videoRef}
          controls
          width={80}
          height={80}
          loop
          controlsList="nodownload"
          muted={true}
        >
          <source />
        </video>
      </div>
      <EllipsisTextWithTooltip string={file?.name} charLength={10} />
    </div>
  );
};

const AudioPreview = ({ file }) => {
  return (
    <div className="flex flex-col">
      <div className="border border-grey-400 flex items-center justify-center rounded-md cursor-pointer">
        <div className="h-20 w-20 flex items-center justify-center">
          <CustomAudioPlayer srcfile={file} minimal loadingItem={loader} />
        </div>
      </div>
      <EllipsisTextWithTooltip string={file?.name} charLength={10} />
    </div>
  );
};

const GeneralFile = ({ file }) => {
  const fileExtension = file?.name?.split('.')?.pop() || '';
  return (
    <div className="flex flex-col ">
      <div className="border border-grey-400 flex items-center justify-center rounded-md">
        <div className="h-20 w-20 flex items-center justify-center flex-col gap-1">
          {renderIcon(fileExtension)}
        </div>
      </div>
      <EllipsisTextWithTooltip string={file?.name} charLength={10} />
    </div>
  );
};

const FilePreview = ({ file = null }) => {
  if (!file) return;

  if (file?.type?.includes('image')) {
    return <ImagePreview file={file} />;
  }
  if (file?.type?.includes('video')) {
    return <VideoPreview file={file} />;
  }
  if (file?.type?.includes('audio')) {
    return <AudioPreview file={file} />;
  }
  return <GeneralFile file={file} />;
};

export default FilePreview;
