import { useMutation, useQueryClient } from '@tanstack/react-query';
import { setForwardingSettings } from 'api';
import { toastEmitter } from 'components/Toast';

export default function useSetForwardingSettings({
  handleSuccess = () => null,
} = {}) {
  const queryclient = useQueryClient();

  const Request = useMutation({
    mutationFn: setForwardingSettings,
    mutationKey: ['setForwardingSettings'],
    onSuccess: (data) => {
      const status = data?.status;
      const responseData = data?.data;
      if (status !== 200) {
        return toastEmitter('error', responseData?.error?.message);
      }
      handleSuccess();
      queryclient.invalidateQueries('getDIDListing');
      toastEmitter('success', responseData?.data?.message);
    },
  });
  return { ...Request };
}
