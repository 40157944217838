import { useQuery } from '@tanstack/react-query';
import { teamListing } from 'api';

export default function useTeamListWithCache() {
  const didListRequest = useQuery({
    queryFn: ({ queryKey }) => teamListing(queryKey[1] || {}),
    queryKey: ['getteamListingWithCache', { limit: 1000 }],
    refetchOnMount: false,
  });

  return { ...didListRequest };
}
