import { useEffect, useReducer, useState } from "react";
import { _defaultState, _reducers } from "./constants";
import JsSip from "jssip";
import moment from "moment";
import { io } from "socket.io-client";
import { useAuth } from "hooks/useAuth";
import useSipCredentials from "hooks/useSipCredentials";
import { toastEmitter } from "components/Toast";
import { socket as socketForBackEnd } from "layouts/AuthLayout";

let _ua = null;
let _reffered = null;

const socket_Url = process.env.REACT_APP_WSS;

const useSip = (props) => {
  const shouldRerender = props?.shouldRerender ?? true;
  const [onCall, setOnCall] = useState({});
  const [sipCallSocket, setSipCallSocket] = useState(null);
  const { token, user } = useAuth();
  const [_state, _dispatch] = useReducer(_reducers, _defaultState);
  const { _config, loading: isLoading, _userCreds } = useSipCredentials();

  useEffect(() => {
    if (sipCallSocket && shouldRerender) {
      sipCallSocket.on("conf-upd", (data) => {
        confUpdate(data);
      });

      sipCallSocket.on("presence", (data) => {
        const prevObj = { ...onCall };
        if (data["From"]) {
          const requiredExt = data["From"].replace("sip:", "")?.split("@")?.[0];
          prevObj[requiredExt] = data?.State;
        }
        setOnCall(prevObj);
      });

      sipCallSocket.on("reconnect", () => {
        sipCallSocket.emit("con", {
          doc: {
            userId: _userCreds?.extension,
            domain: _userCreds?.domain,
          },
        });
      });

      sipCallSocket.on("disconnect", (reason) => {
        if (reason === "io server disconnect") {
          sipCallSocket.connect();
        }
      });

      return () => {
        sipCallSocket.off("connect");
        sipCallSocket.off("disconnect");
        sipCallSocket.off("con");
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sipCallSocket]);

  useEffect(() => {
    if (shouldRerender) {
      if (_userCreds && Object.keys(_userCreds).length > 0) {
        const socket = io(socket_Url, {
          query: {
            token: token,
          },
          reconnection: false,
          reconnectionDelay: 1000,
          reconnectionDelayMax: 5000,
          reconnectionAttempts: 99999,
        });

        socket.emit("con", {
          doc: {
            userId: _userCreds?.extension,
            domain: _userCreds?.domain,
          },
        });

        socket.emit("presence", {
          doc: {
            domain: _userCreds?.domain,
          },
        });

        setSipCallSocket(socket);
      }
    }
  }, [_userCreds]);

  function confUpdate(data) {
    console.log("🚀 ~ file: index.js:124 ~ confUpdate ~ data:", data);
    // localStorage.setItem('confID', data[0].confId);
  }
  useEffect(() => {
    if (shouldRerender) {
      if (!_config || !_config.size) return;
      let wss_url = _config.get("wss_url");
      let domain = _config.get("domain");
      let password = _config.get("password");
      let extension = _config.get("extension");
      if (_config.get("logs")) {
        JsSip.debug.enable("JsSIP:*");
      } else {
        JsSip.debug.disable("JsSIP:*");
      }
      let socket = new JsSip.WebSocketInterface(wss_url);
      socket.via_transport = _config.get("via_transport") ?? "tcp";
      _ua = new JsSip.UA({
        sockets: [socket],
        uri: `sip:${extension}@${domain}`,
        password: password,
        session_timers: false,
        registrar_server: `sip:${domain}`,
      });
      if (!_config.get("custom_connection")) {
        _ua.start();
      }
      _ua.on("connecting", () => {
        _dispatch({ _type: "_SET_STATUS", _payload: "connecting" });
      });

      _ua.on("connected", () => {
        _dispatch({ _type: "_SET_STATUS", _payload: "connected" });
      });

      _ua.on("disconnected", () => {
        _dispatch({ _type: "_SET_STATUS", _payload: "disconnected" });
      });

      _ua.on("registered", () => {
        _dispatch({ _type: "_SET_STATUS", _payload: "registered" });
      });

      _ua.on("unregistered", () => {
        _dispatch({ _type: "_SET_STATUS", _payload: "unregistered" });
      });

      _ua.on("registrationFailed", () => {
        _dispatch({ _type: "_SET_STATUS", _payload: "registrationFailed" });
      });

      _ua.on("newRTCSession", ({ originator, session, request }) => {
        let _uaSession, _uiSession;
        let _displayName = session._remote_identity._display_name || null;
        let _number = session._remote_identity._uri._user || null;
        let _callID = request.call_id;
        if (originator === "local") {
          _uaSession = { [_callID]: session };
          _uiSession = {
            [_callID]: {
              _name: _displayName || "Unknown Number",
              _number: _number,
              _status: "connecting",
              _direction: "outbound",
              _started_at: moment.utc().valueOf(),
              _joined_at: null,
              _active: false,
              _recording: false,
            },
          };
        } else {
          _uaSession = { [_callID]: session };
          _uiSession = {
            [_callID]: {
              _name: _displayName || "Unknown Number",
              _number: _number,
              _status: "connecting",
              _direction: "inbound",
              _started_at: moment.utc().valueOf(),
              _joined_at: null,
              _active: false,
              _recording: false,
            },
          };
        }
        _dispatch({ _type: "_NEW_CALL", _payload: { _uaSession, _uiSession } });
        eventHandlers(session, _callID, user);
      });
      return function _cleanup() {
        _ua.stop();
      };
    }
  }, [_config, user]);

  const eventHandlers = (_session, _callID, user) => {
    let iceCandidateTimeout = null;
    const _audioObj = new Audio();
    _session.on("connecting", (event) => {
      event.body = null;
      _setNumber(null);
      _dispatch({
        _type: "_UPDATE_CALL",
        _payload: { _call_id: event.request.call_id, _status: "connecting" },
      });
    });
    _session.on("confirmed", () => {
      _audioObj.srcObject = _session.connection.getRemoteStreams()[0];
      _audioObj.play();
      if (_reffered?._uuid && _callID && _reffered._uuid === _callID) {
        try {
          _session.refer(_reffered._number);
          _reffered = null;
        } catch (error) {
          console.error("ERROR REFER", error);
        }
      }
      _dispatch({
        _type: "_UPDATE_CALL",
        _payload: {
          _call_id: _callID,
          _status: "connected",
        },
      });
      const activeBatchName = localStorage.getItem("activeBatchName");
      const activeCallData = {
        number: localStorage.getItem("number"),
        batch: activeBatchName,
        joined_at: new Date(),
      };
      socketForBackEnd.emit("userOnCall", activeCallData, user?.email);
    });
    _session.on("progress", () => {
      _dispatch({
        _type: "_UPDATE_CALL",
        _payload: { _call_id: _callID, _status: "ringing" },
      });
      socketForBackEnd.emit(
        "userCalling",
        {
          number: localStorage.getItem("number"),
          batch: localStorage.getItem("activeBatchName"),
        },
        user?.email
      );
    });
    _session.on("accepted", () => {
      _dispatch({
        _type: "_UPDATE_CALL",
        _payload: { _call_id: _callID, _status: "connected" },
      });
    });
    _session.on("failed", () => {
      _dispatch({ _type: "_FAILED_CALL", _payload: _callID });
      socketForBackEnd.emit("userOffCall", user?.email);
    });
    _session.on("ended", () => {
      _dispatch({ _type: "_COMPLETE_CALL", _payload: _callID });
      socketForBackEnd.emit("userOffCall", user?.email);
    });
    _session.on("hold", () => {
      _dispatch({ _type: "_HOLD_CALL", _payload: _callID });
    });
    _session.on("unhold", () => {
      setTimeout(() => {
        _audioObj.srcObject = _session.connection.getRemoteStreams()[0];
        _audioObj.play();
      }, 1000);
      _dispatch({ _type: "_UNHOLD_CALL", _payload: _callID });
    });
    _session.on("muted", () => {
      _dispatch({ _type: "_MUTE_CALL", _payload: _callID });
    });
    _session.on("unmuted", () => {
      _audioObj.srcObject = _session.connection.getRemoteStreams()[0];
      _audioObj.play();
      _dispatch({ _type: "_UNMUTE_CALL", _payload: _callID });
    });
    _session.on("newDTMF", (_e) => {
      console.log("DTMF---->", _e);
    });
    _session.on("newInfo", ({ info }) => {
      _dispatch({
        _type: "_INFO",
        _payload: { _call_id: _callID, _info: info?._contentType || "" },
      });
    });

    _session.on("recording", (event) => {
      _dispatch({
        _type: "_RECORDING",
        _payload: { _call_id: _callID, _recording: event },
      });
    });

    _session.on("icecandidate", (candidate) => {
      if (iceCandidateTimeout != null) {
        clearTimeout(iceCandidateTimeout);
      }
      // 2 seconds timeout after the last icecandidate received!
      iceCandidateTimeout = setTimeout(candidate.ready, 3000);
    });
  };

  const _makeCall = (_displayName, callMeAt = null, sessionUuid = null) => {
    const { _number, _status } = _state;
    localStorage.setItem("number", callMeAt ?? _number);
    if (
      !(_number || callMeAt) ||
      // _status !== 'registered' ||
      ["unregistered", "disconnected"].includes(_status) ||
      !_ua ||
      !_config ||
      !_config.size
    ) {
      return toastEmitter("error", "Error while registering SIP..");
    }
    let _stun_url = _config.get("stun_url");
    let _turn_url = _config.get("turn_url");
    let _turn_username = _config.get("turn_username");
    let _turn_password = _config.get("turn_password");
    let pcConfig = null;

    if (_stun_url && _turn_url && _turn_password) {
      pcConfig = {
        iceServers: [
          { urls: _stun_url },
          {
            urls: _turn_url,
            username: _turn_username,
            credential: _turn_password,
          },
        ],
      };
    } else if (_stun_url && !_turn_url) {
      pcConfig = {
        iceServers: [{ urls: _stun_url }],
      };
    } else if (_turn_url && !_stun_url) {
      pcConfig = {
        iceServers: [
          {
            urls: _turn_url,
            username: _turn_username,
            credential: _turn_password,
          },
        ],
      };
    }

    let _options = {
      mediaConstraints: { audio: true, video: false },
      fromDisplayName: _displayName,
      RTCConstraints: {
        optional: [{ DtlsSrtpKeyAgreement: "false" }],
      },
      rtcOfferConstraints: {
        offerToReceiveAudio: 1,
        offerToReceiveVideo: 0,
      },
      extraHeaders: [`X-SESSION-UUID: ${sessionUuid}`],
    };
    if (pcConfig && Object.keys(pcConfig).length) {
      _options = { ..._options, pcConfig: { ...pcConfig } };
    }
    _ua.call(callMeAt ?? _number, _options);
  };

  const _toggleHold = (_uuid) => {
    const { _uaSessions } = _state;
    let _uaSession = _uaSessions[_uuid];
    if (!_uaSession) return;
    if (_uaSession.isOnHold() && _uaSession.isOnHold().local === true) {
      _uaSession.unhold();
    } else {
      _uaSession.hold();
    }
  };
  const _hold = (_uuid) => {
    const { _uaSessions } = _state;
    let _uaSession = _uaSessions[_uuid];
    if (!_uaSession) return;
    if (_uaSession.isOnHold() && _uaSession.isOnHold().local === true) {
      return;
    } else {
      _uaSession.hold();
    }
  };
  const _unHold = (_uuid) => {
    const { _uaSessions } = _state;
    let _uaSession = _uaSessions[_uuid];
    if (!_uaSession) return;
    if (_uaSession.isOnHold() && _uaSession.isOnHold().local === true) {
      _uaSession.unhold();
    } else {
      return;
    }
  };

  const _answerCall = (_uuid) => {
    if (!_uuid || !_state._uaSessions[_uuid] || !_state._uiSessions[_uuid])
      return null;
    _state._uaSessions[_uuid].answer();
  };

  const _terminate = (_uuid) => {
    if (!_uuid || !_state._uaSessions[_uuid] || !_state._uiSessions[_uuid])
      return null;
    if (
      _state._uiSessions[_uuid]._direction === "inbound" &&
      _state._uiSessions[_uuid]._status === "ringing"
    ) {
      _state._uaSessions[_uuid].terminate({
        status_code: 480,
        reason_phrase: "Unavailable",
      });
    } else {
      _state._uaSessions[_uuid].terminate();
    }
  };

  const _muteCall = (_uuid) => {
    const { _uaSessions } = _state;
    let _uaSession = _uaSessions[_uuid];
    if (!_uaSession) return;
    if (_uaSession.isMuted() && _uaSession.isMuted().audio === true) {
      _uaSession.unmute();
    } else {
      _uaSession.mute();
    }
  };

  const _sendDtmf = (_uuid, _dtmf, _optionsInfo = null) => {
    if (!_uuid || !_dtmf) return false;
    let _options = _optionsInfo
      ? _optionsInfo
      : {
          duration: 100,
          interToneGap: 500,
          extraHeaders: [`_uuid: ${_uuid}`],
          transportType: "INFO",
        };
    const { _uaSessions } = _state;
    let _uaSession = _uaSessions[_uuid];
    _uaSession.sendDTMF(_dtmf, _options);
  };

  const _referUser = (_uuid, _number, _accepted = false) => {
    if (!_uuid || !_number) return false;
    const { _uaSessions, _uiSessions } = _state;
    let _uaSession = _uaSessions[_uuid];
    let _uiSession = _uiSessions[_uuid];
    let _options = {
      extraHeaders: [`_uuid:"${_uuid},_type:"_incoming_refer"`],
    };
    if (!_uaSession || !_uiSession) return;
    if (_accepted) {
      _uaSession.refer(_number);
    } else {
      _reffered = { _uuid, _number };
      _uaSession.answer(_options);
    }
  };

  const _setNumber = (_number) => {
    _dispatch({ _type: "_SET_NUMBER", _payload: _number });
  };

  const _passInfo = (_uuid, _info) => {
    if (!_uuid || !_info) return false;
    const { _uaSessions } = _state;
    let _uaSession = _uaSessions[_uuid];
    if (!_uaSession) return;
    _uaSession.sendInfo(_info);
  };

  const _start = () => {
    try {
      _ua?.start();
    } catch (error) {
      console.error("Connection error", error);
    }
  };

  const _stop = () => {
    try {
      _ua.stop();
    } catch (error) {
      console.error("Connection break error", error);
    }
  };

  return {
    ..._state,
    sipCallSocket,
    isLoading,
    onCall,
    _makeCall,
    _setNumber,
    _toggleHold,
    _answerCall,
    _terminate,
    _muteCall,
    _sendDtmf,
    _referUser,
    _passInfo,
    _start,
    _stop,
    _hold,
    _unHold,
    // _attendedTransfer,
  };
};

export default useSip;
