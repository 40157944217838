import { getRequiredText } from 'shared/resources';
import * as yup from 'yup';

export const formInitialValues = {
  phone_details: {
    country: 'NG',
    phone_number: '+234',
    carrier: '',
  },
  billing_details: {
    first_name: '',
    last_name: '',
    dob: '',
    resident_address: '',
    billing_address: '',
    is_billing_resident_address_same: false,
  },
  document_details: {
    id_type: '',
    id_number: '',
    id_expiry_date: '',
    id_file: null,
    passport_photo: null,
    address_proof: null,
  },
};

export const formValidationSchema = yup.object().shape({
  phone_details: yup.object({
    phone_number: yup.string().required(getRequiredText('Phone number')),
    carrier: yup.string().required(getRequiredText('Carrier')),
  }),
  billing_details: yup.object({
    first_name: yup.string().required(getRequiredText('First name')),
    dob: yup.string().required(getRequiredText('Date of birth')),
    resident_address: yup
      .string()
      .required(getRequiredText('Resident address')),
    billing_address: yup.string().required(getRequiredText('Billing address')),
  }),
  document_details: yup.object({
    id_type: yup.string().required(getRequiredText('ID type')),
    id_number: yup.string().required(getRequiredText('ID number')),
    id_expiry_date: yup.string().required(getRequiredText('ID expiry date')),
    id_file: yup.mixed().required(getRequiredText('Valid ID file')),
  }),
});
