import React from 'react';
import RingingScreen from '../RingingScreen';
import ConnectedScreen from '../ConnectedScreen';
import TransferCallComponent from '../TransferCallComponent';
import DTMFKeypadComponent from '../DTTMFKeypadComponent';
import AddCallComponent from '../AddCallComponent';
import { Chevron } from 'assets/images';

export default function ActiveCallScreen({
  activeCallData = {},
  action = '',
  setAction = () => null,
}) {
  const { _status } = activeCallData;
  const handleScreen = (_status = 'connected') => {
    switch (_status) {
      case 'ringing':
      case 'connecting':
        return (
          <RingingScreen
            setAction={setAction}
            activeCallData={activeCallData}
          />
        );
      case 'hold':
      case 'mute':
      case 'connected':
        return (
          <ConnectedScreen
            setAction={setAction}
            activeCallData={activeCallData}
          />
        );

      default:
        break;
    }
  };

  const handleActionComponent = (action) => {
    switch (action) {
      case 'add':
        return <AddCallComponent activeCallData={activeCallData} />;
      case 'transfer':
        return <TransferCallComponent activeCallData={activeCallData} />;
      case 'attended_transfer':
        return (
          <TransferCallComponent
            activeCallData={activeCallData}
            isAttended={true}
          />
        );
      case 'dial':
        return <DTMFKeypadComponent activeCallData={activeCallData} />;
      default:
        break;
    }
  };

  return (
    <>
      {action ? (
        <div
          className="flex items-center absolute top-[15px] left-[9px]  rounded-md  cursor-pointer text-white"
          onClick={() => setAction(null)}
        >
          <Chevron className="rotate-90" />
          Back
        </div>
      ) : null}
      {action ? handleActionComponent(action) : handleScreen(_status)}
    </>
  );
}
