import {
  Button,
  InlineEdit,
  Modal,
  PlayAudioModal,
  Spinner,
  TableManager,
} from 'components';
import React, { useEffect, useMemo, useState } from 'react';
import { dropdownActions, initialModalState } from './constants';
import CreateCustomGreetingModal from './CreateCustomGreetingModal';
import { greetingList } from 'api';
import { ADMIN, OWNER, formatBytes, secondsToHHMMSS } from 'shared/resources';
import { Play, Trash } from 'assets/images';
import DeleteGreetingModal from './DeleteGreetingModal';
import { useDeleteGreeting, useUpdateGreeting } from 'hooks';
import { useQueryClient } from '@tanstack/react-query';
import BulkGreetingDeleteModal from './BulkGreetingDeleteModal';
import useBulkDelete from 'hooks/useBulkDelete';
import { useAuth } from 'hooks/useAuth';

const BulkDeleteComponent = ({ data = [], callback = () => null }) => {
  const { mutate, isPending, status } = useBulkDelete();
  const [showModal, setShowModal] = useState(initialModalState);

  useEffect(() => {
    if (status && status === 'success') {
      callback();
    }
  }, [status]);

  const ModalLookup = {
    [dropdownActions.BULK_DELETE_GREETING]: (
      <BulkGreetingDeleteModal
        handleClose={callback}
        data={data}
        deleteFn={mutate}
        isLoading={isPending}
      />
    ),
  };
  const { isShow, modalType } = showModal;

  return (
    <div className="w-64">
      <Button
        height="h-10"
        type="button"
        background="danger"
        onClick={() =>
          setShowModal({
            isShow: true,
            modalType: dropdownActions.BULK_DELETE_GREETING,
            elementData: {},
          })
        }
      >
        {isPending ? <Spinner /> : `Delete ${data?.length} greeting(s) -`}
      </Button>
      {isShow ? (
        <Modal
          handleClose={callback}
          headerComponent={null}
          footerComponent={null}
          shouldCloseOnClickOutside={false}
        >
          {ModalLookup[modalType]}
        </Modal>
      ) : null}
    </div>
  );
};

function Greetings() {
  const { user } = useAuth();
  const [showModal, setShowModal] = useState(initialModalState);
  const queryclient = useQueryClient();
  const { mutate: deleteGreetingMutate, isPending: deleteGreetingLoad } =
    useDeleteGreeting({ handleSuccess: handleDeleteSuccess });
  const { mutateAsync: updateGreetingMutate } = useUpdateGreeting();

  const columns = useMemo(
    () => [
      {
        accessorKey: 'check',
        header: (props) => {
          return (
            <input
              className="checked:bg-green checked:hover:bg-green checked:active:bg-green checked:focus:bg-green focus:outline-none focus:ring-1 focus:ring-green"
              type="checkbox"
              checked={props?.table?.getIsAllRowsSelected()}
              onChange={props?.table?.getToggleAllRowsSelectedHandler()}
            />
          );
        },
        cell: (props) => {
          return (
            <input
              key={props?.row?.id}
              className="checked:bg-green checked:hover:bg-green checked:active:bg-green checked:focus:bg-green focus:outline-none focus:ring-1 focus:ring-green"
              type="checkbox"
              checked={props?.row?.getIsSelected()}
              onChange={props?.row?.getToggleSelectedHandler()}
            />
          );
        },
        meta: {
          colType: 'action',
          width: '5%',
          hasAccess: [OWNER, ADMIN].includes(user?.role_id),
        },
      },
      {
        accessorKey: 'name',
        header: () => 'Name',
        cell: (props) => {
          const element = props?.row?.original;
          return (
            <InlineEdit
              value={props?.getValue()}
              editFunction={(val) =>
                updateGreetingMutate({
                  uuid: element?.uuid,
                  name: val?.newVal,
                })
              }
            />
          );
        },
        meta: {
          textAlign: 'left',
        },
      },
      {
        accessorKey: 'size',
        header: () => 'Size',
        cell: (props) => <>{formatBytes(props?.getValue())}</>,
      },
      {
        accessorKey: 'duration',
        header: () => 'Duration',
        cell: (props) => {
          return <>{secondsToHHMMSS(Math.floor(props?.getValue()))}</>;
        },
      },
      {
        accessorKey: 'action',
        header: () => '',
        cell: (props) => {
          const element = props?.row?.original || {};
          return (
            <div className="flex gap-3">
              <span
                className="text-green cursor-pointer"
                onClick={() => {
                  setShowModal({
                    isShow: true,
                    modalType: dropdownActions.PLAY_GREETING,
                    elementData: element,
                    type: 'center',
                  });
                }}
              >
                <Play className="w-6 h-6" />
              </span>
              <span
                className="text-danger cursor-pointer"
                onClick={() => {
                  setShowModal({
                    isShow: true,
                    modalType: dropdownActions.DELETE_GREETING,
                    elementData: element,
                    type: 'center',
                  });
                }}
              >
                <Trash className="w-6 h-6" />
              </span>
            </div>
          );
        },
        meta: {
          colType: 'action',
          width: '5%',
        },
      },
    ],
    [],
  );

  const tableActions = [
    {
      id: 1,
      label: 'Add greeting +',
      access: true,
      component: (
        <div className=" w-64">
          <Button
            height="h-10"
            type="button"
            onClick={() =>
              setShowModal({
                isShow: true,
                modalType: dropdownActions.CREATE_GREETING,
                type: 'center',
              })
            }
          >
            Add greeting +
          </Button>
        </div>
      ),
    },
  ];

  function handleDeleteSuccess() {
    handleCloseModal();
    queryclient.invalidateQueries(['greetingList']);
  }

  function handleCloseModal() {
    setShowModal(initialModalState);
  }

  const ModalLookup = {
    [dropdownActions.CREATE_GREETING]: (
      <CreateCustomGreetingModal handleClose={handleCloseModal} />
    ),
    [dropdownActions.EDIT_GREETING]: (
      <CreateCustomGreetingModal
        isEdit={true}
        showModal={showModal}
        handleClose={handleCloseModal}
      />
    ),
    [dropdownActions.DELETE_GREETING]: (
      <DeleteGreetingModal
        showModal={showModal}
        handleClose={handleCloseModal}
        deleteFn={deleteGreetingMutate}
        isLoading={deleteGreetingLoad}
      />
    ),
    [dropdownActions.PLAY_GREETING]: (
      <PlayAudioModal
        fileNameAccessorKey={'filename'}
        labelAccessorKey="name"
        showModal={showModal}
        handleClose={handleCloseModal}
      />
    ),
  };

  const checkboxSelectableFn = () => true;

  const bulkActions = [
    {
      id: 1,
      component: BulkDeleteComponent,
    },
  ];
  const { isShow, type, modalType } = showModal;
  return (
    <>
      <TableManager
        {...{
          fetcherKey: 'greetingList',
          fetcherFn: greetingList,
          columns,
          name: 'Agency',
          showPagination: true,
          isSearchable: true,
          searchLabel: 'Manage custom greetings in your workspace',
          searchPlaceholder: 'Search by name',
          tableActions,
          bulkActions,
          checkboxSelectableFn: checkboxSelectableFn,
          extraParams: {
            action_type: 'G',
          },
        }}
      />
      {isShow && type === 'center' ? (
        <Modal
          handleClose={handleCloseModal}
          headerComponent={null}
          footerComponent={null}
          shouldCloseOnClickOutside={false}
        >
          {ModalLookup[modalType]}
        </Modal>
      ) : null}
    </>
  );
}

export default Greetings;
