import { getDIDListing } from 'api';
import { Arrow, Check, X } from 'assets/images';
import { Avatar, Button, Spinner, TableManager, Tooltip } from 'components';
import { useAuth } from 'hooks/useAuth';
import useBulkDelete from 'hooks/useBulkDelete';
import React, { useEffect, useMemo } from 'react';
import {
  ADMIN,
  NG_CURRENCY_SYMBOL,
  OWNER,
  formatDateTime,
  formatPhoneNumber,
} from 'shared/resources';

const BulkDeleteComponent = ({ data = [], callback = () => null }) => {
  const { mutate, isPending, status } = useBulkDelete();

  useEffect(() => {
    if (status && status === 'success') {
      callback();
    }
  }, [status]);
  return (
    <div className="w-64">
      <Button
        height="h-10"
        type="button"
        background="danger"
        onClick={() =>
          mutate({
            type: 'did',
            uuids: data,
          })
        }
      >
        {isPending ? <Spinner /> : `Delete ${data?.length} Phone number(s) -`}
      </Button>
    </div>
  );
};

export const ChangePlanModal = ({
  showModal = {},
  handleClose = () => null,
}) => {
  const { elementData = {} } = showModal;
  const { user } = useAuth();
  console.log('🚀 ~ elementData:', elementData);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'check',
        header: (props) => {
          return (
            <input
              className="checked:bg-green checked:hover:bg-green checked:active:bg-green checked:focus:bg-green focus:outline-none focus:ring-1 focus:ring-green"
              type="checkbox"
              checked={props?.table?.getIsAllRowsSelected()}
              onChange={props?.table?.getToggleAllRowsSelectedHandler()}
            />
          );
        },
        cell: (props) => {
          return (
            <input
              key={props?.row?.id}
              className="checked:bg-green checked:hover:bg-green checked:active:bg-green checked:focus:bg-green focus:outline-none focus:ring-1 focus:ring-green"
              type="checkbox"
              checked={props?.row?.getIsSelected()}
              onChange={props?.row?.getToggleSelectedHandler()}
            />
          );
        },
        meta: {
          colType: 'action',
          width: '5%',
          hasAccess: [OWNER, ADMIN].includes(user?.role_id),
        },
      },
      {
        accessorKey: 'did_number',
        header: () => 'Phone number',
        cell: (props) => {
          const element = props?.row?.original ?? {};
          const didName = element?.settings?.incoming_call?.name;
          const didIcon = element?.settings?.incoming_call?.icon
            ? `${process.env.REACT_APP_BASE_API_URL}${
                user?.company_uuid || user?.uuid
              }/icon/${element?.settings?.incoming_call?.icon}`
            : null;

          const isDefaultDID = element?.is_active_caller_id === 1;
          return (
            <div className="flex gap-1 items-center">
              <Avatar
                mediaUrl={didIcon}
                name={didName ?? 'Unknown'}
                type="icon"
              />
              {didName ? (
                <div>
                  <div className="flex items-center content-center gap-1">
                    {didName}
                    {isDefaultDID ? (
                      <Tooltip title="Default">
                        <span className="text-xs text-green flex items-center gap-1">
                          <Check className="h-3 w-3" />
                        </span>
                      </Tooltip>
                    ) : null}
                  </div>
                  <div className="text-grey-600 text-xs font-medium ">
                    {formatPhoneNumber(props?.getValue())}
                  </div>
                </div>
              ) : (
                <div>
                  <div className="flex items-center content-center gap-1">
                    {formatPhoneNumber(props?.getValue())}{' '}
                    {isDefaultDID ? (
                      <Tooltip title="Default">
                        <span className="text-xs text-green flex items-center gap-1">
                          <Check className="h-3 w-3" />
                        </span>
                      </Tooltip>
                    ) : null}
                  </div>
                </div>
              )}
            </div>
          );
        },
      },
      {
        accessorKey: 'buy_date',
        header: () => 'Start date',
        cell: (props) => {
          const [date] = formatDateTime(props?.getValue(), false);
          return date;
        },
      },
      {
        accessorKey: 'renewal_date',
        header: () => 'Renewal date',
        cell: (props) => {
          const [date] = formatDateTime(props?.getValue(), false);
          return date;
        },
      },

      {
        accessorKey: 'buy_duration',
        header: () => 'Plan',
        cell: (props) => {
          return (
            <div className="flex items-center gap-1 capitalize">
              {['MONTHLY', 'ANNUALLY'].includes(props?.getValue())
                ? props?.getValue()?.toLowerCase()
                : props?.getValue()}
            </div>
          );
        },
      },

      {
        accessorKey: 'setup_cost',
        header: () => 'Cost',
        cell: (props) => `${NG_CURRENCY_SYMBOL}${props?.getValue()}`,
      },
    ],
    [],
  );

  const bulkActions = [
    {
      id: 1,
      component: BulkDeleteComponent,
    },
  ];

  const checkboxSelectableFn = () => {
    return true;
  };

  return (
    <>
      <div className="flex justify-between items-start py-4 px-6">
        <div className="flex flex-col gap-1">
          <div className="text-xl font-semibold text-black">Delete numbers</div>
          <div className="text-sm  text-grey-700">
            Select the numbers to delete
          </div>
        </div>
        <button type="button" onClick={() => handleClose()}>
          <X className="text-grey-600 w-6 h-6" />
        </button>
      </div>
      <div className="px-6">
        <TableManager
          fetcherFn={getDIDListing}
          fetcherKey={'getDIDListing'}
          columns={columns}
          checkboxSelectableFn={checkboxSelectableFn}
          bulkActions={bulkActions}
          shouldRefresh={false}
        />
      </div>
      <div className="w-full flex items-center justify-end">
        <div className="flex items-center justify-end w-80 gap-4 px-6 pb-6 xxl:mt-36 xl:mt-36 lg:mt-32 md:mt-32">
          <Button
            extraClasses="justify-start  cursor-pointer"
            type="button"
            width="w-[100px]"
            background="transparent"
            onClick={handleClose}
          >
            <div className="text-grey-900 flex items-center gap-2">
              <Arrow className="rotate-180 text-black" />
              Back
            </div>
          </Button>
          <Button type="button">Submit</Button>
        </div>
      </div>
    </>
  );
};
