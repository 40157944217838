import { Button, Modal, Skeletons, Spinner } from "components";
import { useGetCart, useRemoveCartItem } from "hooks";
import React, { useEffect, useState } from "react";
import {
  ANNUALLY,
  MONTHLY,
  NG_CURRENCY_SYMBOL,
  formatPhoneNumber,
} from "shared/resources";
import { dropdownActions, initialModalState } from "./constants";
import NumberDetail from "../PurchaseNumberModal/NumberDetail";
import { useNavigate } from "react-router-dom";
import { Chevron, Edit, Mastercard, Trash, visa } from "assets/images";
import useMakePayment from "hooks/useMakePayment";
import useSavedCards from "hooks/useSavedCards";
import { useForm, Controller } from "react-hook-form";
import PaymentConfirmationModal from "components/PaymentConfirmationModal";

const getPlanText = (type = MONTHLY) => {
  switch (type) {
    case MONTHLY:
      return "Monthly";
    case ANNUALLY:
      return "Annually";

    default:
      return "Monthly";
  }
};
const cardPlaceholderImage = {
  visa: visa,
  mastercard: Mastercard,
};

const PaymentMethodArray = [
  {
    label: "New payment method",
    type: "NEW_CARD",
  },
  {
    label: "Pay with Saved cards",
    type: "SAVED_CARD",
  },
  {
    label: "Pay with Fonu wallet",
    type: "WALLET",
  },
];

const CardItem = ({ data, setSelectedCard, selectedCard }) => {
  useEffect(() => {
    return () => {
      setSelectedCard("");
    };
  }, []);

  return (
    <div
      className="w-full flex flex-col gap-1 cursor-pointer"
      onClick={() => setSelectedCard(data?.uuid)}
    >
      <div className="flex items-center px-4 h-14  justify-between w-full bg-green-50 rounded-md border border-green-100">
        <div className="text-md flex gap-2 items-center ">
          <img
            src={cardPlaceholderImage[data?.card_type?.trim()?.toLowerCase()]}
            alt="MasterCard"
            className="w-8"
          />
          ****{data?.last4}
        </div>
        <div className="flex items-center gap-2">
          <input
            className="checked:bg-green checked:hover:bg-green checked:active:bg-green checked:focus:bg-green focus:outline-none focus:ring-1 focus:ring-green cursor-pointer"
            type="radio"
            checked={data?.uuid === selectedCard}
            onChange={() => setSelectedCard(data?.uuid)}
          />
        </div>
      </div>
    </div>
  );
};
const CartItem = ({
  itemData = {},
  setShowModal,
  removeCartMutate = () => null,
}) => {
  return (
    <div className="border-b border-grey-400 pb-6	 ">
      <div className="flex items-center justify-between">
        <div className="font-semibold text-2xl text-grey-900">
          {formatPhoneNumber(itemData?.did_number)}
        </div>
        <span
          className="cursor-pointer"
          onClick={() => removeCartMutate({ uuid: itemData?.uuid })}
        >
          <Trash width={24} height={24} className="text-danger" />
        </span>
      </div>
      <div className="flex items-center justify-between ">
        <div className="flex items-center gap-3">
          <span className=" text-xl text-grey-600">
            {getPlanText(itemData?.plan_type)}
          </span>
          <span
            className="flex items-center gap-1 cursor-pointer text-green font-semibold"
            onClick={() =>
              setShowModal({
                isShow: true,
                modalType: dropdownActions.NUMBER_DETAIL_MODAL,
                elementData: itemData,
                type: "center",
              })
            }
          >
            <Edit />
            Change plan
          </span>
        </div>

        <span className="font-semibold text-xl mt-1.5">
          {NG_CURRENCY_SYMBOL}
          {itemData?.cost}
        </span>
      </div>
    </div>
  );
};

const Cart = () => {
  const [cartListing, setCartListing] = useState([]);
  const [showModal, setShowModal] = useState(initialModalState);
  const { isPending, mutate: didCheckoutMutate } = useMakePayment({
    handleSuccess: onSuccessFn,
  });
  const { isLoading, isFetching, data: cartData } = useGetCart();
  const navigate = useNavigate();

  const { control, watch, handleSubmit, setValue } = useForm({
    defaultValues: {
      payment_type: "NEW_CARD",
      is_saved_card: "N",
      card_uuid: "",
    },
    mode: "onChange",
  });

  const { cardListing, isLoading: savedCardsLoading } = useSavedCards();

  const { mutate: removeCartMutate } = useRemoveCartItem();

  useEffect(() => {
    const cartListing = cartData?.data?.data ?? [];
    if (cartListing?.length > 0) {
      setCartListing(cartListing);
    } else {
      setCartListing([]);
    }
  }, [cartData]);

  useEffect(() => {
    if (
      cardListing &&
      cardListing?.length > 0 &&
      watch("payment_type") === "SAVED_CARD"
    ) {
      const defaultCard = cardListing?.find((item) => item?.is_default === "Y");
      setValue("card_uuid", defaultCard?.uuid || "");
    }
  }, [cardListing?.length, watch("payment_type")]);

  const totalCartValue = cartData?.data?.meta?.total_cost ?? 0;

  function handleCloseModal() {
    setShowModal(initialModalState);
  }

  function onSubmit(values) {
    const did_uuids = cartListing?.map((cart) => cart?.did_uuid);

    const payload = {
      did_uuids,
      payment_type: values?.payment_type, // NEW_CARD, SAVED_CARD, WALLET
      is_saved_card: values?.is_saved_card, // Y, N
      card_uuid: values?.card_uuid, // required if payment_type is SAVED_CARD
      source: values?.source,
    };
    didCheckoutMutate(payload);
  }

  function onSuccessFn() {
    handleCloseModal();
    navigate("/phone-numbers/active-numbers");
  }

  const ModalLookup = {
    [dropdownActions.NUMBER_DETAIL_MODAL]: (
      <NumberDetail
        selectedNumber={showModal?.elementData}
        handleClose={handleCloseModal}
        fromCart={true}
      />
    ),
    [dropdownActions.PURCHASE_CONFIRMATION]: (
      <PaymentConfirmationModal
        handleClose={handleCloseModal}
        continueCallBack={handleSubmit(onSubmit)}
        setValue={setValue}
      />
    ),
  };
  const { isShow, modalType, type } = showModal;

  return (
    <div className="w-full h-full">
      <div className="h-full flex flex-col items-start">
        <div className="flex items-center gap-1 font-semibold text-xs w-full">
          <div
            className="text-green cursor-pointer"
            onClick={() => navigate("/phone-numbers/active-numbers")}
          >
            Phone numbers
          </div>
          <Chevron className="text-green -rotate-90" />
          <div className="text-grey-600">Cart</div>
        </div>
        <div className="flex justify-center w-full">
          <div className="flex flex-col w-[530px] mt-6">
            {cartListing?.length > 0 ? (
              <>
                <div className="flex justify-between mb-[22px]">
                  <span className="flex items-center gap-3 text-xl text-grey-900 font-semibold">
                    Cart {isFetching && <Spinner />}
                  </span>
                  <span
                    className="text-base text-green font-semibold cursor-pointer"
                    onClick={() =>
                      navigate(
                        "/phone-numbers/active-numbers?action=purchase_number"
                      )
                    }
                  >
                    Continue Shopping
                  </span>
                </div>
                <div className="bg-white p-6">
                  <div>
                    {isLoading ? (
                      <Spinner />
                    ) : (
                      cartListing.map((cartItem) => {
                        return (
                          <CartItem
                            key={cartItem?.uuid}
                            itemData={cartItem}
                            showModal={showModal}
                            setShowModal={setShowModal}
                            removeCartMutate={removeCartMutate}
                          />
                        );
                      })
                    )}
                  </div>
                  <div>
                    <div className=" text-xl text-grey-600 mt-6">Subtotal</div>
                    <div className="text-5xl text-grey-900 font-semibold mt-3 mb-4">
                      {NG_CURRENCY_SYMBOL}
                      {totalCartValue}
                    </div>
                    {/* <div className="flex items-center gap-1 text-base text-grey-600 font-semibold">
                    Monthly renewal on each number{' '}
                    <div className="text-grey-900">
                      {NG_CURRENCY_SYMBOL}
                      {totalCartValue}
                    </div>
                  </div> */}
                  </div>
                </div>
                <div className="flex w-full gap-5 mt-8 px-0">
                  {PaymentMethodArray.map((item) => {
                    return (
                      <label
                        htmlFor={`payment_type_${item?.type}`}
                        key={item?.type}
                        className="flex items-center gap-1 font-medium text-grey-800 text-sm cursor-pointer"
                      >
                        <Controller
                          name={"payment_type"}
                          control={control}
                          render={({ field }) => {
                            return (
                              <input
                                className="checked:bg-green checked:hover:bg-green checked:active:bg-green checked:focus:bg-green focus:outline-none focus:ring-1 focus:ring-green"
                                type="radio"
                                {...field}
                                id={`payment_type_${item?.type}`}
                                value={item?.type}
                                checked={item?.type === watch("payment_type")}
                              />
                            );
                          }}
                        />
                        {item?.label}
                      </label>
                    );
                  })}
                </div>
                {watch("payment_type") === "SAVED_CARD" ? (
                  <div className="w-full px-4 mt-4 mb-3 gap-2 flex items-center max-w-[600px] overflow-auto flex-col max-h-28">
                    {savedCardsLoading
                      ? Array.from({ length: 3 }, (_, i) => i + 1).map(
                          (val) => {
                            return (
                              <div className="w-full" key={val}>
                                <Skeletons height="h-14" />
                              </div>
                            );
                          }
                        )
                      : cardListing?.length > 0
                      ? cardListing?.map((card) => (
                          <CardItem
                            key={card?.uuid}
                            data={card}
                            setSelectedCard={(val) =>
                              setValue("card_uuid", val)
                            }
                            selectedCard={watch("card_uuid")}
                          />
                        ))
                      : "No saved cards"}
                  </div>
                ) : null}
                {watch("payment_type") === "NEW_CARD" ? (
                  <div className="w-full px-4 mt-4 mb-10 gap-2 flex items-center">
                    <input
                      className="checked:bg-green checked:hover:bg-green checked:active:bg-green checked:focus:bg-green focus:outline-none focus:ring-1 focus:ring-green"
                      type="checkbox"
                      id="is_saved_card"
                      checked={watch("is_saved_card") === "Y"}
                      onChange={(e) => {
                        setValue("is_saved_card", e.target.checked ? "Y" : "N");
                      }}
                    />
                    <label className="cursor-pointer" htmlFor="is_saved_card">
                      Do you want to save this card for future use
                    </label>
                  </div>
                ) : null}
              </>
            ) : (
              <div className="bg-white p-6 flex justify-center">
                Your cart is empty
              </div>
            )}

            <div className="w-full mt-6">
              {cartListing && cartListing.length > 0 ? (
                <>
                  <Button
                    extraClasses="justify-center cursor-pointer"
                    type="button"
                    height="xxl:h-[50px] xl:h-[45px] lg:h-[45px] md:h-[41px]"
                    onClick={() => {
                      if (watch("payment_type") === "NEW_CARD") {
                        setShowModal({
                          modalType: dropdownActions.PURCHASE_CONFIRMATION,
                          isShow: true,
                          elementData: {},
                          type: "center",
                        });
                      } else {
                        handleSubmit(onSubmit)();
                      }
                    }}
                    disabled={isPending}
                  >
                    {isPending ? <Spinner /> : "Proceed to checkout"}
                  </Button>
                  <Button
                    extraClasses="justify-center cursor-pointer"
                    type="button"
                    border="border-none"
                    background="transparent"
                  >
                    <div className="text-grey-700 font-semibold">
                      Terms & Conditions
                    </div>
                  </Button>
                </>
              ) : (
                <Button
                  extraClasses="justify-center cursor-pointer"
                  type="button"
                  height="xxl:h-[50px] xl:h-[45px] lg:h-[45px] md:h-[41px]"
                  onClick={() => {
                    navigate(
                      "/phone-numbers/active-numbers?action=purchase_number"
                    );
                  }}
                >
                  Continue shopping
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
      {isShow && type === "center" ? (
        <Modal
          handleClose={handleCloseModal}
          headerComponent={null}
          footerComponent={null}
          shouldCloseOnClickOutside={false}
        >
          {ModalLookup[modalType]}
        </Modal>
      ) : null}
    </div>
  );
};

export default Cart;
