import { Renew } from 'assets/images';
import React from 'react';

import { Controller } from 'react-hook-form';

const BundleItem = ({ control, itemData, valueKey = '' }) => {
  return (
    <label
      htmlFor={itemData?.uuid}
      className="border border-grey-400 rounded-md p-3 flex items-center justify-between  w-full cursor-pointer"
    >
      <div className="flex flex-col  gap-1 w-full">
        <div className="font-medium">
          {valueKey === 'sms_bundle'
            ? `${itemData?.total_bundles} SMS`
            : `${itemData?.total_bundles} Minutes`}
        </div>
        <div className="font-medium flex gap-1 items-center">
          <Renew className="w-4 h-4" />
          {itemData?.cost} /
          <span className="text-grey-600 capitalize flex">
            {`${itemData?.validity}`.toLowerCase()}
          </span>
        </div>
      </div>
      <div className="font-medium">
        <Controller
          name={valueKey}
          control={control}
          render={({ field }) => {
            return (
              <input
                className="checked:bg-green checked:hover:bg-green checked:active:bg-green checked:focus:bg-green focus:outline-none focus:ring-1 focus:ring-green"
                type="radio"
                {...field}
                id={itemData?.uuid}
                value={itemData?.uuid}
              />
            );
          }}
        />
      </div>
    </label>
  );
};

export default BundleItem;
