import Avatar from 'components/Avatar';
import Tooltip from 'components/Tooltip';
import { useAuth } from 'hooks/useAuth';
import React, { useState } from 'react';

const size = 40;

export default function AvatarGroup({
  options = [],
  accessorKey = '',
  id,
  maxOverlaps = 3,
  sepratorComponent: SperatorComponent = () => null,
}) {
  const { user } = useAuth();
  const [show, setShow] = useState(false);
  const group = options?.map((option, index, arr) => {
    if (index < maxOverlaps) {
      return (
        <Tooltip
          key={option?.id ?? option?.uuid}
          title={option?.[accessorKey]}
          position="t"
        >
          <Avatar
            name={option?.[accessorKey]}
            inGroup={true}
            mediaUrl={`${process.env.REACT_APP_BASE_API_URL}${user?.company_uuid || user?.uuid
              }/profile/${option?.profile_pic}`}
          />
        </Tooltip>
      );
    }

    if (arr.length - 1 === index) {
      return (
        <Tooltip
          key={option?.id ?? option?.uuid}
          title={<SperatorComponent list={options} setShow={setShow} />}
          controlled={true}
          position="r"
          show={show}
          setShow={setShow}
        >
          <div
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setShow(true);
            }}
            key={option?.id}
            style={{
              width: `${size}px`,
              height: `${size}px`,
              fontSize: `${size / 2}px`,
            }}
            className={`  rounded-full overflow-hidden cursor-pointe bg-grey-400 cursor-pointer z-10 `}
          >
            <div
              className={`flex items-center rounded-full justify-center  font-bold h-full w-full shadow-md text-sm `}
            >
              + {arr?.length - maxOverlaps}
            </div>
          </div>
        </Tooltip>
      );
    }
  });

  return (
    <>
      <div className="-space-x-3 flex" key={id}>
        {group}
      </div>
    </>
  );
}
