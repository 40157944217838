import { getMemberListing } from 'api';
import Avatar from 'components/Avatar';
import Button from 'components/Button';
import Input from 'components/Input';
import ListManager from 'components/ListManager';
import { useAuth } from 'hooks/useAuth';
import { SipSocketContext } from 'hooks/useSip/SipSocketContext';
import { LoadingItem } from 'pages/Dashboard';
import React, { useContext, useState } from 'react';

const MemberListItem = ({ item, ...rest }) => {
  const { user } = useAuth();
  return (
    <label
      htmlFor={item?.uuid}
      key={item?.uuid}
      className="flex items-center justify-between py-3 border-grey-300 border-b cursor-pointer"
    >
      <div className="flex gap-3">
        <div className="relative">
          <Avatar
            name={`${item?.first_name} ${item?.last_name}`}
            mediaUrl={`${process.env.REACT_APP_BASE_API_URL}${
              user?.company_uuid || user?.uuid
            }/profile/${item?.profile_pic}`}
          />
        </div>
        <div className="flex flex-col gap-1">
          <div className="flex items-center text-sm font-medium	 text-white">
            {item?.first_name} {item?.last_name}&nbsp;&nbsp;
            {/* <span>
              <Confrance className-"text-blue-light" />
            </span> */}
          </div>
          <div className="text-xs text-grey-600">{item?.phone}</div>
        </div>
      </div>
      <div className="flex text-xs text-green gap-1">
        <input
          className="checked:bg-green checked:hover:bg-green checked:active:bg-green checked:focus:bg-green focus:outline-none focus:ring-1 focus:ring-green"
          id={item?.uuid}
          type="radio"
          value={item?.extension}
          onChange={(e) => {
            rest?.onChangeItemHandler(e.target.value);
          }}
          checked={Number(item?.extension) === Number(rest?.itemValue)}
        />
      </div>
    </label>
  );
};
const AddCallComponent = ({ setAction = () => null, activeCallData }) => {
  const { socket, userSipCreds } = useContext(SipSocketContext);
  const [addToNumber, setAddToNumber] = useState('');

  const iConfIdExist = localStorage.getItem('confID') || null;
  const ConfID =
    socket?._uaSessions?.[activeCallData?.callID]?._request?.headers?.[
      'X-Confid'
    ]?.[0]?.raw;
  const CID =
    socket?._uaSessions?.[activeCallData?.callID]?._request?.headers?.[
      'X-Cid'
    ]?.[0]?.raw;
  const _cid = ConfID || CID || activeCallData?.callID;

  const handleAddToCall = (ext) => {
    if (!ext) return;
    let data = {};
    if (activeCallData?._direction === 'outbound') {
      if (!iConfIdExist) {
        data = {
          sipCallId: _cid,
          sipUser: userSipCreds?.extension,
          domain: userSipCreds?.domain,
          newcall: ext,
        };

        socket?.sipCallSocket?.emit('create-conf', {
          data: data,
        });
      }
      data = {
        [ConfID ? 'confId' : 'sipCallId']: _cid,
        sipUser: userSipCreds?.extension,
        domain: userSipCreds?.domain,
        newcall: ext,
      };
      socket?.sipCallSocket?.emit('add-call-conf', {
        data: data,
      });
    } else {
      // 'Receiver adding'
      if (!iConfIdExist) {
        data = {
          sipCallId: _cid,
          sipUser: userSipCreds?.extension,
          domain: userSipCreds?.domain,
          newcall: ext,
        };

        socket?.sipCallSocket?.emit('create-conf', {
          data: data,
        });
      }

      data = {
        [ConfID ? 'confId' : 'sipCallId']: _cid,
        sipUser: userSipCreds?.extension,
        domain: userSipCreds?.domain,
        newcall: ext,
      };
      socket?.sipCallSocket?.emit('add-call-conf', {
        data: data,
      });
    }
    setAction(null);
  };

  return (
    <>
      <div className="relative flex flex-col justify-between h-full  xxl:mt-8 xl:mt-3 xxl:min-h-[584px] xxl:max-h-[584px] xl:min-h-[360px] xl:max-h-[360px]">
        <div className="px-6 py-7 pt-0 pb-5">
          <div className="text-md font-semibold  text-white mb-1">
            Add member
          </div>
          <Input
            background="bg-grey-100"
            extraClasses="font-unset pl-8 mb-0  "
            placeholder="Search users"
            withSearchIcon={true}
          />
        </div>
        <div className="px-6 py-3 border-grey-300 border-t overflow-auto xxl:max-h-[376px] xl:max-h-[323px] lg:max-h-[314px] md:max-h-[322px] xxl:min-h-[376px] xl:min-h-[323px] lg:min-h-[314px] md:min-h-[322px]">
          <ListManager
            actionFn={getMemberListing}
            queryKey="getMemberListing"
            listItem={MemberListItem}
            loadingComponent={LoadingItem}
            onChangeItemHandler={setAddToNumber}
            itemValue={addToNumber}
          />
        </div>
        <div className=" mt-6 px-6 pb-6">
          <Button
            className="mt-10"
            type="button"
            disabled={!addToNumber}
            onClick={() => handleAddToCall(addToNumber)}
          >
            Add member
          </Button>
        </div>
      </div>
    </>
  );
};

export default AddCallComponent;
