export const initialModalState = {
    modalType: '',
    isShow: false,
    elementData: {},
    type: '',
  };
  
  export const dropdownActions = {
    PURCHASE_CONFIRMATION: 'PURCHASE_CONFIRMATION',
  };
  