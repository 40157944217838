import { Facebook } from 'assets/images/signup';
import { Spinner } from 'components';
import { toastEmitter } from 'components/Toast';
import React from 'react';

const useFacebookAuth = ({
  actionFn = () => null,
  isLoading = false,
  socialType = 'FACEBOOK',
}) => {
  const type = 'FACEBOOK';
  const LoadFacebookAuthScript = () => {
    if (!window.FB) return;
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: process.env.REACT_APP_FACEBOOK_APP_ID,
        cookie: true,
        xfbml: true,
        version: 'v18.0',
      });
      window.FB.login(function (response) {
        if (response.authResponse) {
          window.FB.api(
            '/me?fields=id,email,name',
            { scope: 'public_profile,email' },
            function (response) {
              actionFn(response);
            },
          );
        } else {
          return toastEmitter(
            'error',
            'User cancelled login or did not fully authorize.',
          );
        }
      });
    };
  };

  const LoadFacebookSDK = () => {
    const script = document.createElement('script');
    script.src = `https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v18.0&appId=${process.env.REACT_APP_FACEBOOK_APP_ID}&autoLogAppEvents=1`;
    script.async = true;
    script.id = 'facebook-sdk-script';
    script.onload = LoadFacebookAuthScript;
    document.querySelector('body')?.appendChild(script);
  };

  return (
    <div
      onClick={() => LoadFacebookSDK()}
      className="flex items-center justify-center gap-2 border border-solid  border-grey-400 rounded-md xxl:h-[56px] xl:h-[45px] lg:h-[45px] md:h-[41px] sm:h-[40px] xs:h-[40px]   text-blue-fb  w-full cursor-pointer"
    >
      {isLoading && socialType === type ? (
        <Spinner />
      ) : (
        <>
          <Facebook className="w-5" /> Facebook
        </>
      )}
    </div>
  );
};

export default useFacebookAuth;
