import { Check, Refresh } from 'assets/images';
import { Button, Input, Spinner } from 'components';
import { useSendOtp, useVerifyPhone } from 'hooks';
import React, { useEffect, useState } from 'react';
import { numericRegx } from 'shared/resources';
import { useParams } from 'react-router-dom';

const PhoneVerification = ({
  setPhoneVerified = () => null,
  phoneVerified,
  emailVerified,
}) => {
  const { token } = useParams();

  const [isOTPSentSuccessfull, setIsOTPSentSuccessfull] = useState(false);
  const [isConterRunning, setIsConterRunning] = useState(false);
  const [counter, setCounter] = useState(60);
  const [otp, setOtp] = useState('');
  const { mutateAsync: sendOTP, isPending: sendingOTP } = useSendOtp({
    acionFn: setIsOTPSentSuccessfull,
    counterFn: setCounter,
  });

  const { mutate: verifyPhoneMutate, isPending: veryPhoneLoad } =
    useVerifyPhone({ onSuccessFn });
  useEffect(() => {
    if (isOTPSentSuccessfull) {
      if (counter > 0) {
        if (!isConterRunning) {
          setIsConterRunning(true);
        }
        setTimeout(() => setCounter(counter - 1), 1000);
      } else {
        setIsConterRunning(false);
      }
    }
  }, [isOTPSentSuccessfull, counter]);

  const onSubmit = () => {
    verifyPhoneMutate({ token, otp });
  };
  function onSuccessFn() {
    setPhoneVerified(true);
  }
  return (
    <>
      <div>
        <div className="flex gap-6">
          {phoneVerified ? (
            <div className="rounded-full w-10 min-w-[40px] min-h-[40px] h-10 text-white flex items-center justify-center bg-green">
              <Check />
            </div>
          ) : (
            <div className="rounded-full w-10 min-w-[40px] min-h-[40px] h-10 text-green flex items-center justify-center bg-green-200">
              2
            </div>
          )}
          <div className="flex flex-col  w-full max-w-[433px]">
            <div className="xxl:text-3xl xl:text-[28px] lg:text-[24px] md:text-[22px] text-grey-900 font-medium">
              {phoneVerified ? 'Phone verified' : 'Phone verification'}
            </div>
            <div className="xxl:text-base xl:text-[15px] lg:text-[15px] md:text-[14px] font-normal text-grey-700 xxl:mt-[.1em] ">
              {phoneVerified
                ? 'Your phone has been verified'
                : ' Click on send code to get verification code on your phone number'}
            </div>
            <div className="flex flex-col  w-full">
              <div className="flex flex-col gap-4">
                <form autoComplete="off">
                  <div className="flex w-full items-center gap-2">
                    <div className="py-6 w-full">
                      <Input
                        name="otp"
                        required={true}
                        autoComplete="off"
                        label="Verification code"
                        maxLength="6"
                        value={otp}
                        onChange={(e) => {
                          if (numericRegx.test(e.target.value)) {
                            setOtp(e.target.value);
                          } else {
                            return;
                          }
                        }}
                        disabled={!emailVerified}
                      />
                    </div>
                    <div className="w-6/12 xxl:mt-7 xl:mt-6 lg:mt-6 md:mt-5 sm:mt-6 xs:mt-6">
                      <Button
                        disabled={
                          sendingOTP || isConterRunning || !emailVerified
                        }
                        onClick={() =>
                          sendOTP({
                            type: 'send_otp',
                            token,
                            country: 'NG',
                          })
                        }
                      >
                        <div className="flex gap-1 items-center text-nowrap w-40 px-1 justify-around">
                          {sendingOTP ? (
                            <Spinner />
                          ) : (
                            <div className="flex gap-1 items-center text-nowrap w-40 px-1 justify-around">
                              {isOTPSentSuccessfull ? <Refresh /> : null}
                              {isOTPSentSuccessfull
                                ? 'Resend code'
                                : 'Send code'}
                              {isOTPSentSuccessfull ? (
                                counter ? (
                                  <>&nbsp;{counter}s</>
                                ) : null
                              ) : null}
                            </div>
                          )}
                        </div>
                      </Button>
                    </div>
                  </div>
                  <div className="w-full mt-4">
                    <Button
                      type="button"
                      onClick={onSubmit}
                      disabled={
                        veryPhoneLoad ||
                        (!otp && otp?.length < 6) ||
                        !emailVerified
                      }
                    >
                      {veryPhoneLoad ? <Spinner /> : 'Continue'}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PhoneVerification;
