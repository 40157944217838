import { kycData } from 'api';
import { NoRecord } from 'assets/images';
import { EllipsisTextWithTooltip, TableManager } from 'components';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatDateTime } from 'shared/resources';

const colorLookup = {
  pending: 'text-[#E29704]',
  submitted: 'text-[#3333FF]',
  in_review: 'text-[#87909B]',
  completed: 'text-[#4F772D]',
  rejected: 'text-[#E81313]',
};

const filterInitialValues = {
  filter: [
    {
      phone: '',
      a_filter_type: '',
      placeholder: 'Enter phone',
      type: 'text',
      shouldFilterOptionDisabled: false,
      label: 'Phone',
      filterName: 'phone',
      position: 1,
    },
  ],
};

const NoRecordItem = () => {
  return (
    <div className="w-full grid place-content-center text-grey-600 gap-3 ">
      <div className="flex items-center justify-center flex-col h-[calc(100vh_-_309px)]">
        <NoRecord />
        <span className="text-grey-600">
          You have no active phone number, purchase a Fonu number or port an
          existing number
        </span>
      </div>
    </div>
  );
};

export const KycReports = () => {
  const navigate = useNavigate();
  const [openFilter, setOpenFilter] = useState(false);
  const [filters, setFilters] = useState([]);

  const columns = [
    {
      accessorKey: 'uuid',
      header: () => 'Verification reference',
      cell: (props) => <div>{props?.getValue()?.slice(0, 16)}</div>,
    },
    {
      accessorKey: 'created_at',
      header: () => 'Date',
      cell: (props) => {
        const [date] = formatDateTime(props?.getValue());
        return date;
      },
    },
    {
      accessorKey: 'kyc_details.first_name',
      header: () => 'First name',
    },
    {
      accessorKey: 'kyc_details.last_name',
      header: () => 'Last name',
    },
    {
      accessorKey: 'kyc_doc_type',
      header: () => 'Document type',
      cell: (props) => {
        return (
          <span className="capitalize">
            {props?.getValue()?.toLowerCase()?.split('_')?.join(' ')}
          </span>
        );
      },
    },
    {
      accessorKey: 'rejection_reason',
      header: () => 'Rejection reason',
      cell: (props) => (
        <EllipsisTextWithTooltip
          string={props?.getValue() ?? ''}
          charLength={20}
          position="b"
          withTooltip={true}
        />
      ),
    },
    {
      accessorKey: 'status',
      header: () => 'Status',
      cell: (props) => {
        const element = props?.row?.original;
        return (
          <span
            className={` ${
              colorLookup[props?.getValue()?.toLowerCase()]
            } cursor-pointer capitalize`}
            onClick={() => {
              navigate(
                `/KYC/customer-kyc/read-view?documentType=${element?.kyc_doc_type}`,
                {
                  state: {
                    ...element?.kyc_details,
                    status: element?.status?.toLowerCase(),
                    uuid: element?.uuid,
                  },
                },
              );
            }}
          >
            {props?.getValue()?.toLowerCase()?.split('_')?.join(' ')}
          </span>
        );
      },
    },
  ];
  return (
    <>
      <div className="flex items-center justify-between mb-5">
        <div className="text-sm text-grey-700">
          Manage customer KYC report in your workspace
        </div>
      </div>

      <TableManager
        fetcherFn={kycData}
        fetcherKey={'kycData'}
        searchPlaceholder={'Search by name or document type'}
        searchLabel=""
        isSearchable={true}
        shouldFilter={true}
        columns={columns}
        setOpenFilter={setOpenFilter}
        openFilter={openFilter}
        noRecordItem={NoRecordItem}
        filterInitialValues={filterInitialValues}
        setFilters={setFilters}
        filters={filters}
      />
    </>
  );
};
