import { IPRegex, getRequiredText } from 'shared/resources';
import * as yup from 'yup';

export const dropdownActions = {
  ADD_IVR: 'add_ivr',
  UPDATE_IVR: 'update_ivr',
  DELETE_IVR: 'delete_ivr',
  BULK_DELETE_PHONE_MENU: 'bulk_delete_phone_menu',
};

export const initialModalState = {
  modalType: '',
  isShow: false,
  elementData: {},
  type: '',
};

export const addIvrFormInitialValues = {
  name: '',
  greeting_short: '',

  max_failures: 3,
  max_timeouts: 15,
  invalid_sound: '',
  // no_input_sound: '',
  exec_on_max_failures: {
    type: '',
    value: '',
  },
  exec_on_max_timeouts: {
    type: '',
    value: '',
  },
  ivr_option: [
    {
      key: '',
      type: 'EXTENSION',
      value: '',
    },
  ],
};

export const ivrFormValidationSchema = yup.object({
  name: yup.string().required(getRequiredText('Name')),
  greeting_short: yup.string().required(getRequiredText('Greeting message')),
  ivr_option: yup.array().of(
    yup.object().shape({
      key: yup.string().required(getRequiredText('This field')),
      type: yup.string().required(getRequiredText('This field')),
      value: yup
        .string()
        .when('type', {
          is: (val) => val !== 'HANGUP' && val !== 'VOICEMAIL',
          then: yup.string().required(getRequiredText('This field')),
        })
        .when('type', {
          is: (val) => ['IP'].includes(val),
          then: yup
            .string()
            .required(getRequiredText('This field'))
            .matches(IPRegex, 'Invalid IP Address'),
        }),
    }),
  ),
  max_failures: yup
    .number()
    .typeError(getRequiredText('Allow retries'))
    .min(1, 'Minimum value should be 1')
    .required(getRequiredText('Allow retries')),
  max_timeouts: yup
    .number()
    .typeError(getRequiredText('Allow retries'))
    .min(1, 'Minimum value should be 1')
    .required(getRequiredText('Allow retries')),
  invalid_sound: yup.string().required(getRequiredText('Greeting message')),
  // no_input_sound: yup.string().required(getRequiredText('Greeting message')),
  exec_on_max_failures: yup.object({
    type: yup.string().required(getRequiredText('This field')),
    value: yup.string().when('type', {
      is: (val) => val !== 'HANGUP',
      then: yup.string().required(getRequiredText('This field')),
    }),
  }),
  exec_on_max_timeouts: yup.object({
    type: yup.string().required(getRequiredText('This field')),
    value: yup.string().when('type', {
      is: (val) => val !== 'HANGUP',
      then: yup.string().required(getRequiredText('This field')),
    }),
  }),
});
