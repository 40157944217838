import { useMutation } from '@tanstack/react-query';
import { forgotPassword } from '../../api';
import { toastEmitter } from 'components/Toast';
import { useNavigate } from 'react-router-dom';

export default function useForgotPassword({ handleSuccess = () => null } = {}) {
  const navigate = useNavigate();
  const loginRequest = useMutation({
    mutationFn: forgotPassword,
    mutationKey: ['forgotPassword'],
    onSuccess: (data) => {
      const status = data?.status;
      const responseData = data?.data;
      if (status !== 200) {
        return toastEmitter('error', responseData?.error?.message);
      }
      handleSuccess();
      toastEmitter('success', responseData?.data?.message);
      navigate('/', { replace: true });
    },
  });
  return { ...loginRequest };
}
