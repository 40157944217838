import { useMutation } from '@tanstack/react-query';
import { getDIDListing } from 'api';

export default function useDidList() {
  const didListRequest = useMutation({
    mutationFn: getDIDListing,
    mutationKey: ['getDIDListing'],
  });
  return { ...didListRequest };
}
