import { Skeletons } from 'components';
import useDidListWithCache from 'hooks/useDidListWithCache';
import React, { useEffect, useState } from 'react';

const initialName = 'Unknown name';

const GetFonuNumberName = ({ id = '' }) => {
  const [name, setName] = useState(initialName);
  const { data, isPending } = useDidListWithCache();

  useEffect(() => {
    if (id && data?.data?.data.length > 0) {
      const list = data?.data?.data;
      const requestedName =
        list.find((obj) => obj?.did_number === id)?.name ?? id;
      if (requestedName) {
        setName(requestedName);
      } else {
        setName(initialName);
      }
    }
  }, [id, data?.data?.data]);

  return <>{isPending ? <Skeletons /> : name}</>;
};

export default GetFonuNumberName;
