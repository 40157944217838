import { useQuery } from '@tanstack/react-query';
import {
  depositVerification,
  fundVerification,
  paymentVerification,
} from 'api';
import { Check, X } from 'assets/images';
import { Button, Spinner } from 'components';
import React from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

const PaymentVerification = () => {
  // const navigate = useNavigate();
  const params = useSearchParams()?.[0];
  const txnRef = params?.get('trxref') ?? null;
  const reference = params?.get('reference') ?? null;

  const { isLoading: isLoadingPaymentVerification, data: verificationData } =
    useQuery({
      queryFn: ({ queryKey }) => paymentVerification(queryKey[1] || {}),
      queryKey: ['paymentVerification', { reference_id: txnRef }],
      refetchOnMount: false,
      enabled: Boolean(txnRef),
    });

  const { isLoading: isLoadingDepositVerification, data: depositVerfication } =
    useQuery({
      queryFn: ({ queryKey }) => depositVerification(queryKey[1] || {}),
      queryKey: ['depositVerification', { reference_id: txnRef }],
      refetchOnMount: false,
      enabled: verificationData?.data?.success ? true : false,
    });

  const { isLoading: isLoadingFundVerification } = useQuery({
    queryFn: ({ queryKey }) => fundVerification(queryKey[1] || {}),
    queryKey: ['fundVerification', { reference_id: txnRef }],
    refetchOnMount: false,
    enabled: depositVerfication?.data?.success ? true : false,
  });

  const remainingLoading =
    isLoadingDepositVerification || isLoadingFundVerification;

  if (!txnRef || !reference) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  if (
    !isLoadingPaymentVerification &&
    !remainingLoading &&
    (!depositVerfication?.data?.success ||
      !depositVerfication?.data?.success ||
      !verificationData?.data?.success)
  ) {
    return (
      <div className="flex gap-4 items-start justify-center w-full mt-20  ">
        <div className="flex flex-col  w-full max-w-[433px] gap-6">
          <div>
            <div className="xxl:text-3xl xl:text-[28px] lg:text-[24px] md:text-[22px] text-grey-900 font-medium">
              <div className="flex items-center  gap-3 ">
                <div>Payment failed</div>
                <div>
                  <X className="text-danger" />
                </div>
              </div>
            </div>
            <div className="flex gap-4 items-center">
              <div className="xxl:text-base xl:text-[15px] lg:text-[15px] md:text-[14px] font-normal text-grey-700 xxl:mt-[.1em] flex gap-2 items-center">
                Please retry your payment
              </div>
            </div>
          </div>
          <Button type="button" onClick={() => window.location.reload(true)}>
            Retry
          </Button>
        </div>
      </div>
    );
  }
  return (
    <>
      <div className="flex gap-4 items-start justify-center w-full mt-20  ">
        <div className="rounded-full w-10 min-w-[40px] min-h-[40px] h-10 text-green flex items-center justify-center bg-green-200">
          1
        </div>
        <div className="flex flex-col  w-full max-w-[433px] gap-6">
          <div>
            <div className="xxl:text-3xl xl:text-[28px] lg:text-[24px] md:text-[22px] text-grey-900 font-medium">
              <div className="flex items-center  gap-3 ">
                <div>
                  {isLoadingPaymentVerification
                    ? 'Verifying payment...'
                    : 'Payment verified'}
                </div>
                <div>
                  {isLoadingPaymentVerification ? <Spinner /> : <Check />}
                </div>
              </div>
            </div>
            <div className="flex gap-4 items-center">
              <div className="xxl:text-base xl:text-[15px] lg:text-[15px] md:text-[14px] font-normal text-grey-700 xxl:mt-[.1em] flex gap-2 items-center">
                {isLoadingPaymentVerification
                  ? 'Please wait while we verify your payment'
                  : 'Step verified'}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex gap-4 items-start justify-center w-full mt-20  ">
        <div className="rounded-full w-10 min-w-[40px] min-h-[40px] h-10 text-green flex items-center justify-center bg-green-200">
          2
        </div>
        <div className="flex flex-col  w-full max-w-[433px] gap-6">
          <div>
            <div className="xxl:text-3xl xl:text-[28px] lg:text-[24px] md:text-[22px] text-grey-900 font-medium">
              <div className="flex items-center  gap-3 ">
                <div>
                  {remainingLoading ? 'Processing payment...' : 'Success'}
                </div>
                <div>{remainingLoading ? <Spinner /> : <Check />}</div>
              </div>
            </div>
            <div className="flex gap-4 items-center">
              <div className="xxl:text-base xl:text-[15px] lg:text-[15px] md:text-[14px] font-normal text-grey-700 xxl:mt-[.1em] flex gap-2 items-center">
                {remainingLoading
                  ? 'Please wait while we process your payment'
                  : 'Payment completed'}
              </div>
            </div>
          </div>
          <Button
            type="button"
            onClick={() => {
              return (window.location.href = '/');
              // navigate('/', { replace: true });
            }}
            disabled={remainingLoading || isLoadingPaymentVerification}
          >
            Continue
          </Button>
        </div>
      </div>
    </>
  );
};

export default PaymentVerification;
