import { getRequiredText } from 'shared/resources';
import * as yup from 'yup';

export const dropdownActions = {
  UPSERT_TEAM: 'upsert_team',
  INVITE_TO_TEAM: 'invite_to_team',
  DELETE_TEAM: 'delete_team',
  BULK_DELETE_TEAM: 'bulk_delete_team',
};

export const initialModalState = {
  modalType: '',
  isShow: false,
  elementData: {},
};

export const initialEditTeamInstance = {
  isEdit: false,
  editData: {},
};

export const validationSchema = yup.object().shape({
  name: yup.string().required(getRequiredText('Name')),
  // members: yup.array().min(1, `At least 1 ${getRequiredText('member')}`),
});
