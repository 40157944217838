import { useQuery } from '@tanstack/react-query';
import { getVerificationStatus } from 'api';

export default function useGetVerificationStatus({ token = '' }) {
  const Request = useQuery({
    queryKey: ['getVerificationStatus'],
    queryFn: () => getVerificationStatus({ token }),
    enabled: Boolean(token),
  });
  return { ...Request };
}
