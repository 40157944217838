import { Skeletons } from 'components';
import useMemberListWithCache from 'hooks/useMemberListWithCache';
import React, { useEffect, useState } from 'react';

const initialName = 'Unknown name';

const GetMemberName = ({ id = '' }) => {
  const [name, setName] = useState(initialName);
  const { data, isPending } = useMemberListWithCache();

  useEffect(() => {
    if (id && data?.data?.data.length > 0) {
      const list = data?.data?.data;
      const requestedObject = list.find((obj) => obj?.extension === id);
      if (requestedObject && Object.keys(requestedObject).length > 0) {
        const firstName = requestedObject?.first_name ?? '';
        const lastName = requestedObject?.last_name ?? '';
        const name = firstName + ' ' + lastName;
        setName(name);
      } else {
        setName(initialName);
      }
    }
  }, [id, data?.data?.data]);

  return <>{isPending ? <Skeletons /> : name}</>;
};

export default GetMemberName;
