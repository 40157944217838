import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { Button, Input } from 'components';
import { ayt, getRequiredText, numericRegx } from 'shared/resources';
import { useSendOtp, useServerValidator } from 'hooks';
import Spinner from 'components/Spinner';
import { Arrow, Chevron, LogoFullWhite, Refresh } from 'assets/images';
import AutoTypeComponent from 'components/AutoTypeComponent';
import { Vectordown, Vectorup } from 'assets/images/signup';
import useForgotPassword from 'hooks/useForgotPassword';

const formHeading = {
  0: 'Forgot password',
  1: `Create new password`,
};

const initialValues = {
  phone: '+234',
  otp: '',
  password: '',
  confirm_password: '',
  token: '',
};

const FORGOT_PASSWORD = ({
  control,
  errors,
  ifErrors,
  watch,
  clearErrors,
  setError,
  isLoginPending = false,
  setCurrentStep,
  setValue,
}) => {
  const [isOTPSentSuccessfull, setIsOTPSentSuccessfull] = useState(false);
  const [counter, setCounter] = useState(60);
  const [isConterRunning, setIsConterRunning] = useState(false);

  const { mutate: sendOTP, isPending: sendingOTP } = useSendOtp({
    acionFn: setIsOTPSentSuccessfull,
    counterFn: setCounter,
  });
  const [watchPhone, watchVerificationCode] = watch(['phone', 'otp']);

  const { loadingServerError: serverPhoneErrorLoading, isValid } =
    useServerValidator({
      payload: {
        watch: watchPhone,
        value: watchPhone,
        type: 'login_validate',
        key: 'phone',
      },
      successAction: clearErrors,
      errorAction: setError,
      delay: 1000,
      shouldValidate: watchPhone && watchPhone?.length > 9,
    });

  const {
    loadingServerError: serverVerificationErrorLoading,
    isValid: isValidVerificationCode,
  } = useServerValidator({
    payload: {
      otp: watchVerificationCode,
      type: 'otp',
      key: 'otp',
      watch: watchVerificationCode,
      value: watchPhone,
    },
    successAction: clearErrors,
    successCallback: (data) => setValue('token', data),
    errorAction: setError,
    delay: 1000,
  });

  useEffect(() => {
    if (isOTPSentSuccessfull) {
      if (counter > 0) {
        if (!isConterRunning) {
          setIsConterRunning(true);
        }
        setTimeout(() => setCounter(counter - 1), 1000);
      } else {
        setIsConterRunning(false);
      }
    }
  }, [isOTPSentSuccessfull, counter]);

  return (
    <div className="flex flex-col  ">
      <div className="xxl:mt-8 xl:mt-6 lg:mt-5 md:mt-4 sm:mt-4 xs:mt-4">
        <Controller
          name="phone"
          control={control}
          render={({ field }) => {
            const { onChange } = field;
            return (
              <div className="flex flex-col">
                <div className="xs:flex-col sm:flex-row flex  gap-3 relative">
                  <div className="w-full">
                    <Input
                      {...field}
                      label="Phone number"
                      autoComplete="off"
                      maxLength="17"
                      onChange={(e) => {
                        const aytNumber = ayt(e.target.value);
                        onChange(aytNumber);
                      }}
                      error={errors?.phone?.message}
                      isServerValidationLoading={serverPhoneErrorLoading}
                    />
                  </div>

                  <div className=" xs:w-full sm:w-6/12 xxl:mt-7 xl:mt-6 lg:mt-6 md:mt-5 sm:mt-6 xs:mt-1 ">
                    <Button
                      disabled={
                        sendingOTP ||
                        !!errors?.phone?.message ||
                        (watchPhone && watchPhone?.length < 14) ||
                        !isValid ||
                        isConterRunning
                      }
                      onClick={() => {
                        if (errors && Object.keys(errors).length > 0) {
                          clearErrors();
                        }

                        sendOTP({
                          type: 'send_otp',
                          phone: watchPhone,
                          country: 'NG',
                        });
                      }}
                    >
                      {sendingOTP ? (
                        <Spinner />
                      ) : (
                        <div className="flex gap-1 items-center">
                          {isOTPSentSuccessfull ? <Refresh /> : null}
                          {isOTPSentSuccessfull ? 'Resend code' : 'Send code'}
                          {isOTPSentSuccessfull ? (
                            counter ? (
                              <>&nbsp;{counter}s</>
                            ) : null
                          ) : null}
                        </div>
                      )}
                    </Button>
                  </div>
                </div>

                <div className="xxl:text-base xl:text-[15px] lg:text-sm md:text-xs sm:text-sm xs:text-sm text-grey-600  mt-1">
                  We would send you a verification code to confirm this is your
                  phone number.
                </div>
              </div>
            );
          }}
        />
      </div>
      <div className="xxl:mt-8 xl:mt-6 lg:mt-5 md:mt-4 sm:mt-4 xs:mt-4">
        {isOTPSentSuccessfull ? (
          <div>
            <Controller
              name="otp"
              control={control}
              render={({ field }) => {
                const { onChange } = field;
                return (
                  <Input
                    {...field}
                    autoComplete="off"
                    onChange={(e) => {
                      if (numericRegx.test(e.target.value)) {
                        onChange(e.target.value);
                      } else {
                        return;
                      }
                    }}
                    label="Verification code"
                    error={errors?.otp?.message}
                    maxLength="6"
                    isServerValidationLoading={serverVerificationErrorLoading}
                  />
                );
              }}
            />
            <div className="xxl:text-base xl:text-[15px] lg:text-sm md:text-xs sm:text-sm xs:text-sm text-grey-600  mt-1">
              Enter the verification code sent to your phone number.
            </div>
          </div>
        ) : null}
      </div>

      {isOTPSentSuccessfull && isValidVerificationCode ? (
        <div className="xxl:mt-8 xl:mt-6 lg:mt-5 md:mt-4 sm:mt-4 xs:mt-4">
          <Button
            type="button"
            disabled={
              isLoginPending ||
              ifErrors ||
              !isValid ||
              !watch('otp') ||
              watch('otp')?.length !== 6
            }
            onClick={() => setCurrentStep(1)}
          >
            {isLoginPending ? <Spinner /> : 'Continue'}
          </Button>
        </div>
      ) : null}
    </div>
  );
};
const TAKE_PASSWORD = ({
  control,
  errors,
  ifErrors,
  watch,
  clearErrors,
  setError,
  isLoginPending = false,
}) => {
  const [watchPassword, watchConfirmPassword] = watch([
    'password',
    'confirm_password',
  ]);

  const {
    loadingServerError: serverPasswordErrorLoading,
    isValid: isValidServerPassword,
  } = useServerValidator({
    payload: {
      watch: watchPassword,
      value: watchPassword,
      type: 'password',
      key: 'password',
    },
    successAction: clearErrors,
    errorAction: setError,
    delay: 1000,
  });

  return (
    <div className="flex flex-col  ">
      <div className="xxl:mt-8 xl:mt-6 lg:mt-5 md:mt-4 sm:mt-4 xs:mt-4">
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              required={true}
              type="password"
              label="Password"
              autoComplete="new-password"
              isServerValidationLoading={serverPasswordErrorLoading}
              error={errors?.password?.message}
            />
          )}
        />
      </div>
      <div className="xxl:mt-8 xl:mt-6 lg:mt-5 md:mt-4 sm:mt-4 xs:mt-4">
        <Controller
          name="confirm_password"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              type="password"
              required={true}
              label="Confirm Password"
              autoComplete="new-password"
              error={errors?.confirm_password?.message}
            />
          )}
        />
      </div>

      <div className="xxl:mt-8 xl:mt-6 lg:mt-5 md:mt-4 sm:mt-4 xs:mt-4">
        <Button
          type="submit"
          disabled={
            isLoginPending ||
            ifErrors ||
            !isValidServerPassword ||
            !watchConfirmPassword
          }
        >
          {isLoginPending ? <Spinner /> : 'Reset password'}
        </Button>
      </div>
    </div>
  );
};

export default function ForgotPassword() {
  const [currentStep, setCurrentStep] = useState(0);
  const validationSchema = yup.object().shape({
    phone: yup.string().required(getRequiredText('Phone')),
    password: yup.string().required(getRequiredText('Password')),
    confirm_password: yup
      .string()
      .required(getRequiredText('Confirm password'))
      .when('password', {
        is: (password) => password && password.length > 0,
        then: yup
          .string()
          .oneOf([yup.ref('password')], 'Passwords do not match'),
      }),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setError,
    setValue,
    clearErrors,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onChange',
  });

  const formSubHeading = {
    0: 'Fill the form below to reset password',
    1: `Fill the form below to reset password for ${watch('phone')}`,
  };

  const { isPending: isLoginPending, mutate: handleLogin } =
    useForgotPassword();

  async function onSubmit(values) {
    handleLogin(values);
  }

  const ifErrors = errors && Object.keys(errors).length > 0;

  const forgotStep = {
    0: (
      <FORGOT_PASSWORD
        control={control}
        errors={errors}
        setValue={setValue}
        ifErrors={ifErrors}
        watch={watch}
        clearErrors={clearErrors}
        setError={setError}
        isLoginPending={isLoginPending}
        setCurrentStep={setCurrentStep}
      />
    ),
    1: (
      <TAKE_PASSWORD
        control={control}
        errors={errors}
        setValue={setValue}
        ifErrors={ifErrors}
        watch={watch}
        clearErrors={clearErrors}
        setError={setError}
        isLoginPending={isLoginPending}
      />
    ),
  };

  return (
    <div className="flex h-screen ">
      <div className="bg-green-50   w-screen overflow-auto relative xxl:pt-10 xl:pt-10 lg:pt-8 md:pt-5 sm:pt-8 xs:pt-5">
        <div
          onClick={() => (window.location.href = '/')}
          className="flex gap-1  items-center cursor-pointer absolute left-4 md:top-10 xs:top-5"
        >
          <Chevron className="rotate-90 xs:hidden md:flex" />
          <Arrow className="rotate-180 xs:flex md:hidden " />
          <span className='xs:hidden md:flex'>Back</span>

        </div>
        <div className="m-auto xxl:w-6/12 xl:w-6/12 lg:w-6/12 md:w-6/12 sm:w-8/12 xs:w-11/12">
          <div className="flex flex-col ">
            <div className="xxl:text-3xl xl:text-[28px] lg:text-[24px] md:text-[22px] xs:text-3xl text-black font-medium lg:w-6/ xs:mt-8">
              {formHeading[currentStep]}
            </div>
            <div className="xxl:text-base xl:text-[15px] lg:text-[15px] md:text-[14px] font-normal	 text-grey-600 xxl:mt-[.1em] ">
              {formSubHeading[currentStep]}
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            {forgotStep[currentStep]}
          </form>
        </div>
      </div>
      <div className=" h-full px-12 py-10 xxl:min-w-[570px] xl:min-w-[450px] lg:min-w-[380px] md:min-w-[365px] xxl:flex xl:flex lg:flex md:flex sm:hidden xs:hidden relative  flex-col justify-between  	 bg-green">
        <div className="flex gap-3">
          <LogoFullWhite className=" xxl:w-28 xl:w-24 lg:w-[85px] md:w-20" />
        </div>
        <AutoTypeComponent extraClass={'text-black'} />
        <div className="absolute right-0 top-0 xxl:w-auto lg:w-5/6 md:w-4/6">
          <Vectorup className="w-full" />
        </div>
        <div className="absolute left-0 bottom-0 xxl:w-auto lg:w-5/6 md:w-4/6">
          <Vectordown className="w-full" />
        </div>
      </div>
    </div>
  );
}
