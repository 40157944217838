import { useMutation } from '@tanstack/react-query';
import { sendOtp } from 'api';

import { toastEmitter } from 'components/Toast';

export default function useSendOtp(props) {
  const Request = useMutation({
    mutationFn: sendOtp,
    mutationKey: ['sendOtp'],
    onSuccess: (data) => {
      const status = data?.status;
      const responseData = data?.data;
      console.log(responseData);
      if (status !== 200) {
        // props?.acionFn(false);
        // props?.counterFn(0);
        return toastEmitter('error', responseData?.error?.message);
      }
      props?.acionFn(true);
      props?.counterFn(60);
      toastEmitter('success', responseData?.data?.message);
    },
  });
  return { ...Request };
}
