import { toastEmitter } from 'components/Toast';
import { useAuth } from 'hooks/useAuth';
import useUploadMedia from 'hooks/useUploadMedia';
import { useState } from 'react';

function loadAudioFileAsync(url) {
  return new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'arraybuffer';

    xhr.onload = function () {
      if (xhr.status === 200) {
        var arrayBuffer = xhr.response;
        resolve(arrayBuffer);
      } else {
        reject('Error loading audio file');
      }
    };

    xhr.onerror = function () {
      reject('Network error');
    };

    xhr.send();
  });
}

const audioContext = new window.AudioContext();
export default function useUploadFile() {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const { mutateAsync: uploadMediaMutate } = useUploadMedia();
  const uuid = user?.company_uuid || user?.uuid;

  const uploadMedia = async ({ file, type = '', topLeft = true }) => {
    if (!file || !type || !uuid) return;
    setIsLoading(true);
    const payload = {
      uuid,
      filename: file?.name,
      type,
    };
    let duration = null;

    const response = await uploadMediaMutate(payload);

    if (response?.status !== 200 && topLeft) {
      setIsLoading(false);
      return toastEmitter('error', response?.data?.error?.message, 'top-left');
    }

    const { file_name: filename, signed_url: uploadUrl } =
      response?.data?.data ?? {};

    const mimeType = new URL(uploadUrl).searchParams.get('Content-Type');

    if (uploadUrl) {
      const uploadFileResponse = await fetch(response?.data?.data?.signed_url, {
        headers: { 'Content-Type': mimeType },
        method: 'PUT',
        body: file,
      });
      if (uploadFileResponse?.status === 200) {
        setIsLoading(false);
        if (['greeting'].includes(type)) {
          const arrayBuffer = await loadAudioFileAsync(
            URL.createObjectURL(file),
          );
          const decodeAudioData = await audioContext.decodeAudioData(
            arrayBuffer,
          );
          duration = decodeAudioData.duration;
        }
        return {
          file_name: filename,
          duration,
        };
      } else {
        setIsLoading(false);
        return null;
      }
    } else {
      setIsLoading(false);
      return null;
    }
  };

  return { uploadMedia, isLoading };
}
