import { SuspenseLoader } from 'components';
import { useGetVerificationStatus } from 'hooks';
import React from 'react';
import { Outlet, useParams, useLocation, Navigate } from 'react-router-dom';

const VerificationLayout = () => {
  const { token } = useParams();
  const location = useLocation();
  const { isPending, isFetching, data, isLoading } = useGetVerificationStatus({
    token,
  });

  if (isLoading || isFetching || isPending) {
    return <SuspenseLoader />;
  }
  if (!data?.data?.success) {
    return <Navigate to={'/'} state={{ from: location }} replace={true} />;
  }

  if (data?.data?.success) {
    return <Outlet context={{ verificationData: data }} />;
  }
};

export default VerificationLayout;
