import { useMutation } from '@tanstack/react-query';
import { signUp } from 'api';
import { toastEmitter } from 'components/Toast';
import { useNavigate } from 'react-router-dom';

export default function useSignUp() {
  const navigate = useNavigate();
  const Request = useMutation({
    mutationFn: signUp,
    mutationKey: ['signUp'],
    onSuccess: (data) => {
      const status = data?.status;
      const responseData = data?.data;
      if (status !== 200) {
        return toastEmitter('error', responseData?.error?.message);
      }

      navigate('/');
      toastEmitter('success', responseData?.data?.message);
    },
  });
  return { ...Request };
}
