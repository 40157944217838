export const initialModalState = {
  modalType: '',
  isShow: false,
  elementData: {},
  type: '',
};

export const dropdownActions = {
  SET_DEFAULT_CARD: 'set_default_card',
  DELETE_CARD_MODAL: 'delete_card_modal',
};
