import { CloudFile, X } from 'assets/images';
import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Button, Input } from 'components';
import CustomSelect from 'components/CustomSelect';
import DocumentUploadInput from 'pages/PortNumber/PortNumberForm/UploadDocumentsForm/DocumentUploadInput';
import { useNavigate, useLocation } from 'react-router-dom';
import * as yup from 'yup';
import { getRequiredText } from 'shared/resources';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';

const GenderItem = ({ item }) => {
  return (
    <div
      key={item.value}
      value={item?.value}
      className="w-full h-full flex  justify-start gap-2 items-center py-2 px-3"
    >
      {item?.label}
    </div>
  );
};

const genderOption = [
  {
    label: 'Male',
    value: 'M',
  },
  {
    label: 'Female',
    value: 'F',
  },
];

const initialValues = {
  licence_number: '',
  first_name: '',
  middle_name: '',
  last_name: '',
  dob: '',
  gender: '',
  issued_date: '',
  expiry_date: '',
  issue_state: '',
  front_image: null,
  back_image: null,
};

const validationSchema = yup.object().shape({
  licence_number: yup.string().required(getRequiredText('License number')),
  first_name: yup.string().required(getRequiredText('First name')),
  dob: yup
    .date()
    .max(new Date(), 'Date of birth must be in the past')
    .test(
      'is-over-18',
      'Date of birth must be before 18 years ago',
      function (value) {
        const dob = new Date(value);
        const today = new Date();
        const eighteenYearsAgo = new Date(
          today.getFullYear() - 18,
          today.getMonth(),
          today.getDate(),
        );
        return dob <= eighteenYearsAgo;
      },
    )
    .required(getRequiredText('Date of birth')),
  gender: yup.string().required(getRequiredText('Gender')),
  front_image: yup.mixed().required(getRequiredText('Front image')),
  back_image: yup.mixed().required(getRequiredText('Back image')),
});

const DriversLicenseModal = ({ showModal = {}, handleClose = () => null }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { elementData = {} } = showModal;
  const isEdit = elementData && Object.keys(elementData)?.length > 0;

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onChange',
  });

  useEffect(() => {
    if (isEdit) {
      reset(elementData);
    }
  }, [isEdit]);

  const onSubmit = async (values) => {
    navigate(`${pathname}/read-view?documentType=${showModal?.modalType}`, {
      state: { ...values, dob: moment(values?.dob).format('YYYY-MM-DD') },
    });
  };

  return (
    <div className="min-w-[500px]">
      <div className="flex justify-between items-start py-4 px-6">
        <div className="flex flex-col gap-1">
          <div className="text-xl font-semibold text-black">
            Driver&apos;s license
          </div>
          <div className="text-sm  text-grey-700">
            Enter the information to add a valid document
          </div>
        </div>
        <button type="button" onClick={() => handleClose()}>
          <X className="text-grey-600 w-6 h-6" />
        </button>
      </div>
      <div className=" pb-5 pt-1 overflow-auto">
        <form className=" px-6 ">
          <div className="flex flex-col gap-4 ">
            <div className="flex gap-3">
              <Controller
                name="licence_number"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    onWhitebg={true}
                    label="License number"
                    error={errors?.licence_number?.message}
                    maxLength={50}
                    required={true}
                  />
                )}
              />
              <Controller
                name="first_name"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    onWhitebg={true}
                    label="First name"
                    error={errors?.first_name?.message}
                    maxLength={50}
                    required={true}
                  />
                )}
              />
            </div>
            <div className="flex gap-3">
              <Controller
                name="middle_name"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    onWhitebg={true}
                    label="Middle name"
                    error={errors?.middle_name?.message}
                    maxLength={50}
                  />
                )}
              />
              <Controller
                name="last_name"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    onWhitebg={true}
                    label="Surname"
                    error={errors?.last_name?.message}
                    maxLength={50}
                  />
                )}
              />
            </div>
            <div className="flex gap-3">
              <Controller
                name="dob"
                control={control}
                render={({ field }) => {
                  return (
                    <Input
                      {...field}
                      type="date"
                      label="Date of birth"
                      autoComplete="off"
                      max={
                        new Date(
                          new Date().getFullYear() - 18,
                          new Date().getMonth(),
                          new Date().getDate(),
                        )
                          .toISOString()
                          .split('T')[0]
                      }
                      error={errors?.dob?.message}
                      onWhitebg={true}
                      required
                    />
                  );
                }}
              />
              <div className="w-full">
                <Controller
                  name="gender"
                  control={control}
                  render={({ field }) => (
                    <CustomSelect
                      {...field}
                      height="xxl:h-14 xl:h-12"
                      placeholder="Select option"
                      onWhitebg={true}
                      label="Gender"
                      error={errors?.gender?.message}
                      ItemComponent={GenderItem}
                      valueKey={'value'}
                      labelKey={'label'}
                      isMulti={false}
                      maxHeight={false}
                      optionsList={genderOption}
                      required={true}
                    />
                  )}
                />
              </div>
            </div>
            <div className="flex gap-3">
              <Controller
                name="issued_date"
                control={control}
                render={({ field }) => {
                  return (
                    <Input
                      {...field}
                      type="date"
                      label="Issued date"
                      autoComplete="off"
                      max={new Date().toISOString().split('T')[0]}
                      error={errors?.issued_date?.message}
                      onWhitebg={true}
                    />
                  );
                }}
              />
              <Controller
                name="expiry_date"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    type="date"
                    label="Expiry date"
                    autoComplete="off"
                    error={errors?.expiry_date?.message}
                    onWhitebg={true}
                  />
                )}
              />
            </div>
            <div className="flex gap-3 w-1/2 ">
              <Controller
                name="issue_state"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    onWhitebg={true}
                    label="State of issue"
                    error={errors?.issue_state?.message}
                    maxLength={50}
                  />
                )}
              />
            </div>
            <div className="flex gap-3">
              <DocumentUploadInput
                setValue={setValue}
                watch={watch}
                label="Upload front"
                valueKey="front_image"
                readView={false}
                error={errors?.front_image?.message}
                showIIcon={false}
                IconWithText={
                  <CloudFile
                    className={`${
                      errors?.front_image?.message
                        ? 'text-danger'
                        : 'text-green'
                    }`}
                  />
                }
              />

              <DocumentUploadInput
                setValue={setValue}
                watch={watch}
                label="Upload back"
                valueKey="back_image"
                readView={false}
                error={errors?.back_image?.message}
                showIIcon={false}
                IconWithText={
                  <CloudFile
                    className={`${
                      errors?.back_image?.message ? 'text-danger' : 'text-green'
                    }`}
                  />
                }
              />
            </div>
          </div>
        </form>
        <div className="px-6 mt-5">
          <Button type="button" onClick={handleSubmit(onSubmit)}>
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DriversLicenseModal;
