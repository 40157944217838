import { useMutation } from '@tanstack/react-query';
import { getMemberListing } from '../../api';

export default function useMemberList() {
  const memberListRequest = useMutation({
    mutationFn: getMemberListing,
    mutationKey: ['getMemberListing'],
  });
  return { ...memberListRequest };
}
