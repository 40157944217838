import { Download, InfoGrey, X } from 'assets/images';
import { Tooltip } from 'components';
import React from 'react';
import { downloadFile } from 'shared/resources';

const DocumentUploadInput = ({
  label = '',
  valueKey = '',
  setValue = () => null,
  watch = () => null,
  readView = false,
  error = '',
  uploadTypeText = 'document',
  acceptType = 'application/*,image/*',
  showIIcon = true,
  IIconText = '',
  showFileName = false,
  IconWithText = null,
}) => {
  const file = watch(valueKey);

  return (
    <div className={`flex flex-col gap-1 w-full ${readView ? 'mt-4' : ''}`}>
      <div className="flex items-center justify-between">
        <div className="text-grey-800 xxl:text-base xl:text-sm	 lg:text-sm md:text-xs	 font-medium ">
          {label ? label : ''}
        </div>
        {showIIcon ? (
          <Tooltip title={IIconText}>
            <InfoGrey className="text-grey-600" />
          </Tooltip>
        ) : null}
      </div>
      <div>
        {readView ? (
          <>
            {file ? (
              <div className="flex gap-2">
                {typeof file === 'string' ? (
                  <object
                    data={file}
                    className="w-14 h-14"
                    id="doc-file-preview"
                  />
                ) : file?.type?.includes('image') ? (
                  <img
                    className="w-14 h-14 rounded-md"
                    id="doc-file-preview"
                    alt={file?.name}
                    src={URL.createObjectURL(file)}
                  />
                ) : (
                  <object
                    data={URL.createObjectURL(file)}
                    className="w-14 h-14"
                    id="doc-file-preview"
                  />
                )}
                <div
                  className="cursor-pointer"
                  onClick={() => downloadFile(file, typeof file === 'string')}
                >
                  <Download className="text-green" />
                </div>
              </div>
            ) : (
              <div className="text-grey-500 text-sm"> No file selected</div>
            )}
          </>
        ) : (
          <>
            {file ? (
              <div className="xxl:h-[56px] xl:h-[45px] lg:h-[45px] md:h-[41px] sm:h-[40px] xs:h-[40px] flex items-center px-5 justify-between border bg-green border-green  rounded-md">
                <div>&nbsp;</div>
                <div className="text-white flex flex-col items-center">
                  <div>
                    <span className="capitalize">{uploadTypeText}</span>&nbsp;
                    uploaded
                  </div>
                  {showFileName && (
                    <div className="text-xs">( {file?.name} )</div>
                  )}
                </div>
                <div
                  className="cursor-pointer "
                  onClick={(e) => {
                    e.stopPropagation();
                    setValue(valueKey, null, {
                      shouldValidate: true,
                    });
                  }}
                >
                  <X className="text-white" height={14} />
                </div>
              </div>
            ) : (
              <>
                <input
                  type="file"
                  id={valueKey}
                  accept={acceptType}
                  onChange={(e) => {
                    setValue(valueKey, e.target.files?.[0], {
                      shouldValidate: true,
                    });
                    e.target.files = null;
                  }}
                  hidden
                />
                <label htmlFor={valueKey}>
                  <div
                    className={`xxl:h-[56px] xl:h-[45px] lg:h-[45px] md:h-[41px] sm:h-[40px] xs:h-[40px] flex items-center px-5 justify-center border rounded-md gap-2 cursor-pointer ${
                      error ? 'border-danger' : 'border-green'
                    }`}
                  >
                    {IconWithText ? IconWithText : null}
                    <div
                      className={`${
                        error ? 'text-danger' : 'text-green'
                      } flex items-center gap-2 font-medium text-sm `}
                    >
                      Upload {uploadTypeText}
                    </div>
                  </div>
                </label>
              </>
            )}
          </>
        )}
      </div>
      {error ? (
        <small className="text-danger text-xs	font-normal mt-1">{error}</small>
      ) : null}
    </div>
  );
};

export default DocumentUploadInput;
