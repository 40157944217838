import { useClickOutside } from 'hooks';
import React, { useRef } from 'react';

const positionVar = {
  t: 'bottom-full',
  b: 'top-full',
  r: 'left-full',
  l: 'right-full',
};
const positionVarNormalize = {
  t: 'bottom-[-2px]',
  b: 'bottom-[80%]',
  r: '-left-1',
  l: '-right-1',
};
const marginVar = {
  t: 'mb-2',
  b: 'mt-2',
  r: 'ml-2',
  l: 'mr-2',
};

export default function Tooltip({
  children,
  title = '',
  position = 't',
  controlled = false,
  show = false,
  setShow = () => null,
}) {
  const toolTipRef = useRef(null);
  useClickOutside([toolTipRef], () => {
    if (controlled) {
      setShow(false);
    }
  });
  return (
    <div
      className={`relative flex flex-col items-center ${
        controlled ? '' : 'group'
      } justify-center`}
    >
      {children}
      {controlled && show ? (
        <div
          ref={toolTipRef}
          className={`absolute ${positionVar[position]} flex-col items-center justify-center flex   ${marginVar[position]} z-40`}
        >
          <span className="relative  rounded-md overflow-auto xxl:max-h-[330px] xl:max-h-[330px] lg:max-h-[330px] md:max-h-[254px]  z-50 p-4 text-xs  text-white  bg-black shadow-lg min-w-[150px] leading-5 text-center">
            {title}
          </span>

          <span
            className={`w-3 h-3  rotate-45 bg-black absolute ${positionVarNormalize[position]} `}
          ></span>
        </div>
      ) : (
        <div
          className={`absolute ${positionVar[position]} flex-col  items-center justify-center hidden group-hover:flex ${marginVar[position]} z-40`}
        >
          <span className="relative  rounded-md z-50 p-4 overflow-auto xxl:max-h-[330px] xl:max-h-[330px] lg:max-h-[330px] md:max-h-[254px] text-xs  text-white min-w-[150px] bg-black shadow-lg  leading-5 text-center">
            {title}
          </span>

          <span
            className={`w-3 h-3  rotate-45 bg-black absolute ${positionVarNormalize[position]} `}
          ></span>
        </div>
      )}
    </div>
  );
}
