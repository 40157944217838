import GetFonuNumberName from 'hooks/GetFonuNumberName';
import GetIVRName from 'hooks/GetIVRName';
import GetMemberName from 'hooks/GetMemberName';
import GetTeamName from 'hooks/GetTeamName';
import React from 'react';

const GetForwarding = ({ type = '', value = '' }) => {
  if (!type || type === '---') {
    return 'Unknown type';
  }

  if (type === 'TEAM') {
    return <GetTeamName id={value} />;
  }
  if (type === 'EXTENSION') {
    return <GetMemberName id={value} />;
  }
  if (type === 'NUMBER') {
    return value;
  }
  if (type === 'FONU_NUMBER') {
    return <GetFonuNumberName id={value} />;
  }
  if (type === 'HANGUP') {
    return 'Hangup';
  }
  if (type === 'VOICEMAIL') {
    return 'Voicemail';
  }
  if (type === 'IVR') {
    return <GetIVRName id={value} />;
  }
  if (type === 'IP') {
    return value;
  }
};

export default GetForwarding;
