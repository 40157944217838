export const initialModalState = {
  modalType: '',
  isShow: false,
  elementData: {},
};

export const DocumentUploadConstant = {
  NATIONAL_IDENTITY: 'NATIONAL_IDENTITY_NUMBER',
  BANK_VERIFICATION: 'BANK_VERIFICATION_NUMBER',
  DRIVERS_LICENCE: 'DRIVING_LICENSE',
  INTERNATIONAL_PASSPORT: 'PASSPORT',
  VOTERS_CARD: 'VOTER_CARD',
  SELFIE_MODAL: 'selfie_modal',
  IDENTIFICATION_MODAL: 'identification_modal',
  DELETE_KYC: 'delete_kyc',
};
