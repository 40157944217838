import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteTeam } from 'api';
import { toastEmitter } from 'components/Toast';

export default function useTeamDelete({
  onSuccessFn = () => null,
  onErrorFn = () => null,
}) {
  const queryclient = useQueryClient();
  const Request = useMutation({
    mutationFn: deleteTeam,
    mutationKey: ['deleteTeam'],
    onSuccess: (data) => {
      const status = data?.status;
      const responseData = data?.data;
      if (status !== 200) {
        onErrorFn();
        return toastEmitter('error', responseData?.error?.message);
      }
      onSuccessFn();
      queryclient.invalidateQueries(['teamListing']);
      toastEmitter('success', responseData?.data?.message);
    },
  });
  return { ...Request };
}
