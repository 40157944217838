import { getTransactionList } from 'api';
import { TableManager } from 'components';
import React, { useMemo } from 'react';
import { formatDateTime } from 'shared/resources';

export const Transactions = () => {
  const columns = useMemo(
    () => [
      {
        accessorKey: 'created_at',
        header: () => 'Date',
        cell: (props) => {
          const [date] = formatDateTime(props?.getValue());
          return <span>{date}</span>;
        },
      },
      {
        accessorKey: 'bill_no',
        header: () => '	Transaction No',
      },

      {
        accessorKey: 'type',
        header: () => '	Type',
      },
      {
        accessorKey: 'desc',
        header: () => 'Description',
        cell: (props) => (
          <span
            style={{
              textTransform: 'capitalize',
            }}
          >
            {props?.getValue()?.toLowerCase() ?? ''}
          </span>
        ),
      },

      {
        accessorKey: 'total_amount',
        header: () => 'Amount',
        meta: {
          isSortable: true,
        },
      },
      {
        accessorKey: 'status',
        header: () => 'Status',
      },
    ],

    [],
  );

  return (
    <div className="overflow-auto w-full px-6 pt-6 pb-6">
      <TableManager
        {...{
          fetcherKey: 'getTransactionList',
          fetcherFn: getTransactionList,
          columns,
          name: 'Billings',
          showPagination: true,
          isSearchable: true,
          searchLabel: 'Manage billings in your workspace',
          searchPlaceholder: 'Search by Transaction No.',
        }}
      />
    </div>
  );
};
