import { useMutation } from '@tanstack/react-query';
import { login } from '../../api';
import { toastEmitter } from 'components/Toast';
import { useAuth } from 'hooks/useAuth';

export default function useLogin({ acionFn = () => null }) {
  const { setUserToken } = useAuth();
  const loginRequest = useMutation({
    mutationFn: login,
    mutationKey: ['login'],
    onSuccess: (data) => {
      const status = data?.status;
      const responseData = data?.data;
      if (status !== 200) {
        acionFn();
        return toastEmitter('error', responseData?.error?.message);
      }
      setUserToken(responseData?.data?.token);
    },
  });
  return { ...loginRequest };
}
