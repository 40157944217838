import React from "react";
import { ToastContainer, toast } from "react-toastify";

export const toastEmitter = (
  type = "success",
  content = "",
  position = "top-right"
) => {
  if (!["success", "info", "warning", "error"].includes(type)) return;
  toast[`${type}`]?.(content, { position });
};

const Toast = () => {
  return (
    <ToastContainer
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      theme="colored"
      icon={false}
      progress={undefined}
      pauseOnHover={false}
      pauseOnFocusLoss={false}
    />
  );
};

export default Toast;
