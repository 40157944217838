import { useQuery } from '@tanstack/react-query';
import { getDIDListing } from 'api';

export default function useDidListWithCache() {
  const didListRequest = useQuery({
    queryFn: ({ queryKey }) => getDIDListing(queryKey[1] || {}),
    queryKey: ['getDIDListingWithCache', { limit: 1000 }],
    refetchOnMount: false,
  });
  return { ...didListRequest };
}
