import { useMutation } from '@tanstack/react-query';
import { resendVerificationLink } from 'api';
import { toastEmitter } from 'components/Toast';

export default function useResendVerificationLink({
  onSuccessFn = () => null,
  onErrorFn = () => null,
} = {}) {
  const Request = useMutation({
    mutationFn: resendVerificationLink,
    mutationKey: ['resendVerificationLink'],
    onSuccess: (data) => {
      const status = data?.status;
      const responseData = data?.data;
      if (status !== 200) {
        onErrorFn();
        return toastEmitter('error', responseData?.error?.message);
      }
      onSuccessFn();
      toastEmitter('success', responseData?.data?.message);
    },
  });
  return { ...Request };
}
