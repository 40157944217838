import { Check } from 'assets/images';
import {
  CountrySelect,
  CustomSelectServer,
  Input,
  ReadViewInput,
} from 'components';
import React from 'react';
import { Controller } from 'react-hook-form';
import { ayt } from 'shared/resources';

const CarrierItem = ({ item }) => {
  return (
    <div
      key={item.value}
      value={item?.value}
      className="w-full h-full flex  justify-start gap-2 items-center py-2 px-3"
    >
      {item?.label}
    </div>
  );
};
const PhoneNumberForm = ({ control, errors, watch, readView = false }) => {
  const isStepDone =
    watch('phone_details.phone_number')?.length > 4 &&
    watch('phone_details.carrier');

  return (
    <div className="flex gap-6">
      {isStepDone ? (
        <div className="rounded-full w-10 min-w-[40px] min-h-[40px] h-10 text-white flex items-center justify-center bg-green">
          <Check />
        </div>
      ) : (
        <div className="rounded-full w-10 min-w-[40px] min-h-[40px] h-10 text-green flex items-center justify-center bg-green-200">
          1
        </div>
      )}
      <div className="flex flex-col  w-full max-w-[433px]">
        <div className="text-grey-900 text-lg font-semibold">Phone number</div>
        <div className="text-grey-700 text-sm mb-8">
          Existing phone number information
        </div>
        <div className="flex flex-col gap-4">
          <CountrySelect
            height={
              readView
                ? 'unset'
                : 'xxl:h-[56px] xl:h-[45px] lg:h-[45px] md:h-[41px] sm:h-[40px] xs:h-[40px]'
            }
            padding={readView ? 'px-0' : 'px-4'}
            background={readView ? 'bg-transparent' : 'bg-grey-100'}
            border={readView ? 'border-none' : ''}
          />

          {readView ? (
            <div className="mt-4 w-full">
              <ReadViewInput
                labelText="Phone number"
                valueText={watch('phone_details.phone_number')}
              />
            </div>
          ) : (
            <Controller
              name="phone_details.phone_number"
              control={control}
              render={({ field }) => {
                const { onChange } = field;
                return (
                  <Input
                    {...field}
                    label="Phone number"
                    autoComplete="off"
                    maxLength="17"
                    onChange={(e) => {
                      const aytNumber = ayt(e.target.value);
                      onChange(aytNumber);
                    }}
                    error={errors?.phone_details?.phone_number?.message}
                    required={true}
                    onWhitebg={true}
                  />
                );
              }}
            />
          )}
          {readView ? (
            <div className="mt-4 w-full">
              <ReadViewInput
                labelText="Select your carrier"
                valueText={
                  watch('phone_details.carrier') || 'No option selected'
                }
              />
            </div>
          ) : (
            <Controller
              name="phone_details.carrier"
              control={control}
              render={({ field }) => (
                <CustomSelectServer
                  {...field}
                  height="xxl:h-14 xl:h-12"
                  onWhitebg={true}
                  label="Select your carrier"
                  placeholder="Select option"
                  error={errors?.phone_details?.carrier?.message}
                  ItemComponent={CarrierItem}
                  labelKey={'name'}
                  valueKey={'uuid'}
                  isMulti={false}
                  actionFn={() => []}
                  queryKey={'timezone'}
                />
              )}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PhoneNumberForm;
