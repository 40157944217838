import { Chevron, X } from 'assets/images';
import Dropdown from 'components/Dropdown';
import { baseInputLabelClasses } from 'components/Input';
import React from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import Spinner from 'components/Spinner';
import { formatPhoneNumber } from 'shared/resources';
import EllipsisTextWithTooltip from 'components/EllipsisTextWithTooltip';

const CustomSelectServer = (
  {
    isMulti = false,
    ItemComponent = () => null,
    //   optionsList = [],
    label = '',
    placeholder = '',
    error = '',
    labelKey = 'label',
    valueKey = '',
    queryKey = '',
    actionFn = () => null,
    CaretComponent = () => <Chevron />,
    shouldFormat = false,
    maxHeight = true,
    extraParams = {},
    selectedItem = '',
    disableCheckBoxCondition = () => false,
    ...rest
  },
  ref,
) => {
  const observer = React.useRef(null);

  const {
    data = [],
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    isLoading,
  } = useInfiniteQuery({
    queryKey: [`${queryKey}`],
    queryFn: ({ pageParam }) => {
      return actionFn({ page: pageParam, ...extraParams } || {});
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      return lastPage?.data?.pagination?.nextPage;
    },
    select: (data) => {
      return (
        data?.pages
          ?.map((page) => {
            return page?.data?.data || {};
          })
          ?.flat() ?? []
      );
    },
    gcTime: 0,
  });

  const lastRef = React.useCallback(
    (node) => {
      if (!node) return;
      if (observer.current) observer.current?.disconnect();
      observer.current = new IntersectionObserver((nodes) => {
        if (nodes[0].isIntersecting) {
          if (hasNextPage && !isFetchingNextPage) fetchNextPage();
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasNextPage, isFetchingNextPage],
  );

  const optionsList = data || [];
  const handleCheckBoxChange = (value) => {
    if (rest?.value?.includes(value)) {
      const updatedValues = rest?.value?.filter((val) => val !== value);
      rest?.onChange(updatedValues);
    } else {
      rest?.onChange([...rest.value, value]);
    }
  };

  const handleRadioChange = (value) => {
    rest?.onChange(value);
  };

  const MultiDropItem = ({ fieldLabel, fieldValue, item = {} }) => {
    const isDisabled = disableCheckBoxCondition(item);
    return (
      <label
        className="w-full flex cursor-pointer items-center justify-between  py-2 px-3"
        htmlFor={fieldLabel}
        onClick={() => {
          if (isDisabled) {
            return;
          } else {
            handleCheckBoxChange(fieldValue);
          }
        }}
      >
        <ItemComponent key={item?.id} item={item} selectedItem={selectedItem} />
        {isDisabled ? null : (
          <input
            className="checked:bg-green-300  default:bg-green-300 checked:hover:bg-green-300 not-checked:focus:bg-transparent checked:focus:bg-transparent focus:ring-[#8BA873]"
            id={fieldLabel}
            type="checkbox"
            checked={rest?.value?.includes(fieldValue)}
            disabled={isDisabled}
          />
        )}
      </label>
    );
  };

  const SingleDropItem = ({ fieldLabel, fieldValue, item = {} }) => {
    return (
      <label
        className="w-full flex cursor-pointer items-center justify-start"
        htmlFor={fieldLabel}
        onClick={() => handleRadioChange(fieldValue)}
      >
        <ItemComponent item={item} selectedItem={selectedItem} />
      </label>
    );
  };

  const ListItemToBeRendered = isMulti ? MultiDropItem : SingleDropItem;

  const dropListNotification = {
    component: ({ item, listIndex, listArr }) => {
      const fieldValue = item?.[valueKey];
      const fieldLabel = item?.[labelKey];
      if (listIndex + 1 === listArr.length) {
        return (
          <span ref={lastRef}>
            <ListItemToBeRendered
              fieldValue={fieldValue}
              fieldLabel={fieldLabel}
              item={item}
            />
            {isFetchingNextPage && <Spinner />}
          </span>
        );
      }
      return (
        <span>
          <ListItemToBeRendered
            fieldValue={fieldValue}
            fieldLabel={fieldLabel}
            item={item}
          />
        </span>
      );
    },
    data: data,
  };

  const handleRemoveItem = (e, value) => {
    e.preventDefault();
    e.stopPropagation();
    handleCheckBoxChange(value);
  };

  const normalizeValues = (value) => {
    const originalValue = value?.[valueKey] ? value?.[valueKey] : value;

    const option = optionsList?.find((option) => {
      return option[valueKey] === originalValue;
    });
    return (
      <div
        className=" flex items-center justify-center  gap-1 rounded-full bg-green-100 text-green px-2 py-1 h-6 text-sm"
        key={option?.id}
      >
        <div className="flex-none leading-3">
          <EllipsisTextWithTooltip
            string={option?.[labelKey]}
            charLength={20}
            position="b"
            withTooltip={false}
          />
        </div>
        <span
          className=" cursor-pointer mt-0.5"
          onClick={(e) => handleRemoveItem(e, value)}
        >
          <X className="text-green w-[.8rem] " />
        </span>
      </div>
    );
  };

  const values = isMulti ? (
    rest?.value?.length > 0 ? (
      rest?.value?.map(normalizeValues)
    ) : (
      <span className={baseInputLabelClasses}>{placeholder}</span>
    )
  ) : rest?.value ? (
    optionsList?.find((option) => option[valueKey] === rest?.value)?.[labelKey]
  ) : (
    <span className={baseInputLabelClasses}>{placeholder}</span>
  );
  const conditioalClasses = error
    ? ' w-full border px-3 flex gap-1.5 items-center rounded-md   font-medium text-base text-grey-900 border-danger focus:border-danger ring ring-danger-100 focus:ring-danger-100 bg-danger-100 focus:bg-danger-100  xxl:min-h-[56px] xl:min-h-[45px] lg:min-h-[45px] md:min-h-[41px] '
    : ` w-full border px-3 flex gap-1.5 items-center rounded-md py-2  font-medium text-base text-grey-900 border-grey-400 focus:border-green focus:ring-green-200 bg-grey-100  xxl:min-h-[56px] xl:min-h-[45px] lg:min-h-[45px] md:min-h-[41px]     } `;

  return (
    <div className="flex flex-col gap-1 w-full " ref={ref}>
      {label ? <label className={baseInputLabelClasses}>{label}</label> : null}
      <Dropdown
        dropList={dropListNotification}
        caretComponent={CaretComponent}
        showcaret={true}
        closeOnClickOutside={true}
        isLoading={isLoading}
        maxHeight={maxHeight}
      >
        <div role="textarea" className={conditioalClasses}>
          <div className="flex items-center overflow-x-auto flex-wrap w-[410px] gap-1.5">
            {shouldFormat
              ? values !== placeholder
                ? formatPhoneNumber(values)
                : values
              : values}
          </div>
        </div>
      </Dropdown>
      {error ? (
        <small className="text-danger text-xs	font-normal mt-1">{error}</small>
      ) : null}
    </div>
  );
};

export default React.forwardRef(CustomSelectServer);
