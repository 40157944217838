import { useQuery } from '@tanstack/react-query';
import { getCompanyDetails } from 'api';

export default function useGetCompanyDetails() {
  const Request = useQuery({
    queryFn: () => getCompanyDetails(),
    queryKey: ['getCompanyDetails'],
    refetchOnMount: false,
    select: (data) => data?.data?.data,
  });

  return { ...Request };
}
