import { useMutation } from '@tanstack/react-query';
import { contactLists } from 'api';

export default function useContactList() {
  const contactListRequest = useMutation({
    mutationFn: contactLists,
    mutationKey: ['contactLists'],
  });
  return { ...contactListRequest };
}
