import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { router } from 'router';
import 'react-toastify/dist/ReactToastify.css';
import { Toast } from 'components';
import { Auth } from 'hooks/useAuth';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Chat } from 'hooks/useChat';
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

export default function App() {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <Auth>
        <Chat>
          <QueryClientProvider client={queryClient}>
            <Toast />
            <RouterProvider router={router} />
          </QueryClientProvider>
        </Chat>
      </Auth>
    </GoogleOAuthProvider>
  );
}
