import { X, MusicFill } from 'assets/images';
import { Button, Spinner } from 'components';
import React from 'react';

const DeleteGreetingModal = ({
  handleClose = () => null,
  deleteFn = () => null,
  isLoading = false,
  showModal = {},
}) => {
  const itemElement = showModal?.elementData ?? {};

  return (
    <>
      <div className="flex justify-end items-center p-4">
        <button type="button" onClick={() => handleClose()}>
          <X className="text-grey-600 w-6 h-6" />
        </button>
      </div>
      <div className="text-center px-16 pb-5 justify-center">
        <div className="flex justify-center  mt-8">
          <MusicFill className="text-black" />
        </div>
        <div className="text-4xl text-black font-semibold	 mt-4 mb-1">
          Delete custom greeting
        </div>
        <div className="text-grey-700 text-base	mt-4 w-96">
          Are you sure you want to delete this custom greeting &nbsp;
          <span className="font-medium">{itemElement?.name}</span> ?
        </div>
        <div className="mt-10 pb-14">
          <Button
            disabled={isLoading}
            background="danger"
            type="button"
            onClick={() =>
              deleteFn({
                uuid: itemElement?.uuid,
              })
            }
          >
            <div className="flex w-full px-5 justify-center items-center">
              {isLoading ? <Spinner /> : 'Delete'}
            </div>
          </Button>
        </div>
      </div>
    </>
  );
};

export default DeleteGreetingModal;
