import { useMutation } from '@tanstack/react-query';
import { userCredentials } from 'api';
import { toastEmitter } from 'components/Toast';
import { useEffect, useState } from 'react';

export default function useSipCredentials() {
  const [_config, setConfig] = useState(null);
  const [_userCreds, setUserCreds] = useState(null);
  const { mutate, isPending } = useMutation({
    mutationFn: userCredentials,
    mutationKey: ['userCredentials'],
    onSuccess: (data) => {
      const status = data?.status;
      const responseData = data?.data?.data;
      if (status !== 200) {
        return toastEmitter('error', responseData?.error?.message);
      }
      setUserCreds(responseData);
      setConfig(
        new Map([
          ['turn_username', responseData?.turn_username],
          ['turn_password', responseData?.turn_password],
          ['extension', `${responseData?.extension}_web`],
          ['domain', responseData?.domain],
          ['stun_url', responseData?.stun_url],
          ['turn_url', responseData?.turn_url],
          ['password', responseData?.password],
          ['name', `${responseData?.first_name} ${responseData?.last_name}`],
          ['wss_url', responseData?.wss_url],
          ['custom_connection', true],
        ]),
      );
    },
  });

  useEffect(() => {
    mutate();
  }, []);

  return { loading: isPending, _config, _userCreds };
}
