import { useQuery } from '@tanstack/react-query';
import { getCart } from 'api';
import { toastEmitter } from 'components/Toast';

export default function useGetCart() {
  const { isError, isSuccess, data, isLoading, isFetching, isPending } =
    useQuery({
      queryFn: () => getCart({ hey: 'adasd' }),
      queryKey: ['getCart'],
    });

  if (isError || (isSuccess && data?.status !== 200)) {
    toastEmitter('error', data?.data?.error?.message);
  }

  return { data, isLoading, isFetching, isPending, isSuccess };
}
