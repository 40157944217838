import { Camera, Instagram, TwitterIcon } from "assets/images";
import { Facebook } from "assets/images/signup";
import { Avatar, Button, Input, Modal, Spinner } from "components";
import ImageCropper from "components/ImageCropper";
import { toastEmitter } from "components/Toast";
import {
  useGetCompanyDetails,
  useUpdateCompanyDetails,
  useUploadFile,
} from "hooks";
import { useAuth } from "hooks/useAuth";
import DocumentUploadInput from "pages/PortNumber/PortNumberForm/UploadDocumentsForm/DocumentUploadInput";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";

const Business = () => {
  const imagePickerRef = useRef(null);
  const [companyImage, setCompanyImage] = useState("");
  const { user } = useAuth();
  const { data: companyDetails } = useGetCompanyDetails();
  const {
    isPending: updateCompanyDetailsLoad,
    mutateAsync: updateCompanyDetailsMutate,
  } = useUpdateCompanyDetails();
  const { isLoading: mediaUploadLoad, uploadMedia } = useUploadFile();

  const {
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      company_logo: companyDetails?.company_logo ?? "",
      company_name: companyDetails?.company_name ?? "",
      website_link: companyDetails?.website_link ?? "",
      facebook_link: companyDetails?.facebook_link ?? "",
      twitter_link: companyDetails?.twitter_link ?? "",
      instagram_link: companyDetails?.instagram_link ?? "",
      company_rc_number: companyDetails?.company_rc_number ?? "",
      company_cac_doc: companyDetails?.company_cac_doc ?? null,
    },
    mode: "onChange",
  });

  useEffect(() => {
    if (companyDetails && Object.keys(companyDetails).length > 0) {
      setValue("company_logo", companyDetails?.company_logo);
      setValue("company_name", companyDetails?.company_name);
      setValue("website_link", companyDetails?.website_link);
      setValue("facebook_link", companyDetails?.facebook_link);
      setValue("twitter_link", companyDetails?.twitter_link);
      setValue("instagram_link", companyDetails?.instagram_link);
      setValue("company_rc_number", companyDetails?.company_rc_number);
      setValue("company_cac_doc", companyDetails?.company_cac_doc);
    }
  }, [companyDetails]);

  function handleCloseImageCropper() {
    setCompanyImage(null);
  }

  async function handleUploadImage(imageData) {
    fetch(imageData)
      .then((res) => res.blob())
      .then(async (item) => {
        const company_logo = new File(
          [item],
          `company_logo.${item?.type?.split("/")?.[1] || "png"}`
        );
        uploadMedia({
          type: "company_logo",
          file: company_logo,
        })
          .then(async (res) => {
            await updateCompanyDetailsMutate({
              company_logo: res?.file_name,
              company_name: watch("company_name"),
              website_link: watch("website_link"),
              facebook_link: watch("facebook_link"),
              twitter_link: watch("twitter_link"),
              instagram_link: watch("instagram_link"),
            });
            handleCloseImageCropper();
          })
          .catch(() => {
            handleCloseImageCropper();
          });
      });
  }

  function handleFileSelect(e) {
    let file = e.target.files[0];
    e.target.value = "";
    if (file && file?.type?.includes("image")) {
      try {
        const reader = new FileReader();
        reader.onload = () => {
          setCompanyImage(reader.result);
        };
        reader.readAsDataURL(file);
      } catch (error) {
        console.log(error);
      }
    } else {
      toastEmitter("error", "File type not supported.");
    }
  }

  async function onSubmit(values) {
    console.log("here???", values);
    if (typeof values?.company_cac_doc === "string") {
      updateCompanyDetailsMutate({
        company_logo: companyDetails?.company_logo,
        company_name: values?.company_name,
        website_link: values?.website_link,
        facebook_link: values?.facebook_link,
        twitter_link: values?.twitter_link,
        instagram_link: values?.instagram_link,
        company_rc_number: values?.company_rc_number,
        company_cac_doc: companyDetails?.company_cac_doc,
      });
    } else {
      if (values?.company_cac_doc) {
        const res = await uploadMedia({
          file: values?.company_cac_doc,
          type: "company",
        });

        if (res) {
          updateCompanyDetailsMutate({
            company_logo: companyDetails?.company_logo,
            company_name: values?.company_name,
            website_link: values?.website_link,
            facebook_link: values?.facebook_link,
            twitter_link: values?.twitter_link,
            instagram_link: values?.instagram_link,
            company_rc_number: values?.company_rc_number,
            company_cac_doc: res?.file_name,
          });
        }
      } else {
        updateCompanyDetailsMutate({
          company_logo: companyDetails?.company_logo,
          company_name: values?.company_name,
          website_link: values?.website_link,
          facebook_link: values?.facebook_link,
          twitter_link: values?.twitter_link,
          instagram_link: values?.instagram_link,
          company_rc_number: values?.company_rc_number,
        });
      }
    }
  }

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        handleSubmit(onSubmit)();
      }}
    >
      <div className="flex flex-col items-start xxl:w-9/12 xl:w-9/12 lg:w-10/12 md:w-10/12 sm:w-11/12 xs:w-11/12 m-auto mt-[70px] ">
        <div className="flex items-end relative">
          <Avatar
            name={watch("company_name")}
            mediaUrl={`${process.env.REACT_APP_BASE_API_URL}${
              user?.company_uuid || user?.uuid
            }/company_logo/${companyDetails?.company_logo}`}
            size="96"
          />

          <div
            className="text-green cursor-pointer absolute right-0 bottom-1 border border-white rounded-full"
            onClick={() => imagePickerRef?.current?.click()}
          >
            <div className="w-8 h-8 rounded-full border-2 border-white bg-grey-600 flex items-center justify-center text-white">
              <Camera className="w-4 h-4 " />
            </div>
            <Input
              ref={imagePickerRef}
              extraClasses="hidden"
              type="file"
              accept="image/png, image/jpeg"
              id="imagePickerRef"
              onChange={(e) => handleFileSelect(e)}
            />
          </div>
        </div>
        <div className="w-full">
          <div>
            <div className="flex gap-6 items-start mt-10">
              <Controller
                name="company_name"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    onWhitebg={true}
                    label="Company Name"
                    error={errors?.company_name?.message}
                    maxLength={50}
                  />
                )}
              />
              <Controller
                name="website_link"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    onWhitebg={true}
                    label="Website Link"
                    error={errors?.website_link?.message}
                    maxLength={50}
                  />
                )}
              />
            </div>
            <div className="flex gap-6 items-start mt-10">
              <Controller
                name="company_rc_number"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    onWhitebg={true}
                    label="Company RC number"
                    error={errors?.company_rc_number?.message}
                    maxLength={50}
                  />
                )}
              />
              <div className="w-full flex gap-1 items-center relative">
                <DocumentUploadInput
                  setValue={setValue}
                  watch={watch}
                  label="Upload CAC document"
                  valueKey="company_cac_doc"
                  readView={false}
                  showIIcon={false}
                  error={errors?.company_cac_doc?.message}
                />
                <div className="absolute -right-16 bottom-0">
                  {companyDetails?.company_cac_doc ? (
                    <object
                      data={`${process.env.REACT_APP_BASE_API_URL}${
                        user?.company_uuid || user?.uuid
                      }/company/${companyDetails?.company_cac_doc}`}
                      className="w-14 h-14"
                      id="doc-file-preview"
                    />
                  ) : null}
                </div>
              </div>
            </div>
            <div className="flex gap-6 mt-5">
              <div className="w-full">
                <div className="w-full relative">
                  <Controller
                    name="facebook_link"
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        onWhitebg={true}
                        label="Facebook"
                        error={errors?.facebook_link?.message}
                        maxLength={50}
                        extraClasses="pl-16"
                      />
                    )}
                  />
                  <div className="text-green absolute  bg-grey-200 xxl:left-[1.1px] xl:left-[1.1px] lg:left-[1px] md:left-[0.5px] sm:left-[1.1px] xs:left-[1.1px]  xxl:top-[29px] xl:top-[25px] lg:top-[24.5px] md:top-[21px] sm:top-6 xs:top-6 flex justify-center items-center  w-[54px] rounded-l-md cursor-pointer  xxl:h-[54px] xl:h-[43px] lg:h-[43.5px] md:h-[39px] sm:h-[40px] xs:h-[40px]">
                    <Facebook className="w-5" />
                  </div>
                </div>
              </div>
              <div className="w-full">
                <div className="w-full relative">
                  <Controller
                    name="twitter_link"
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        onWhitebg={true}
                        label="Twitter"
                        error={errors?.twitter_link?.message}
                        maxLength={50}
                        extraClasses="pl-16"
                      />
                    )}
                  />
                  <div className="text-green absolute  bg-grey-200 xxl:left-[1.1px] xl:left-[1.1px] lg:left-[1px] md:left-[0.5px] sm:left-[1.1px] xs:left-[1.1px]  xxl:top-[29px] xl:top-[25px] lg:top-[24.5px] md:top-[21px] sm:top-6 xs:top-6 flex justify-center items-center  w-[54px] rounded-l-md cursor-pointer  xxl:h-[54px] xl:h-[43px] lg:h-[43.5px] md:h-[39px] sm:h-[40px] xs:h-[40px]">
                    <TwitterIcon className="w-5 h-5" />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex gap-6 mt-5">
              <div className="w-full">
                <div className="w-full relative">
                  <Controller
                    name="instagram_link"
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        onWhitebg={true}
                        label="Instagram"
                        error={errors?.instagram_link?.message}
                        maxLength={50}
                        extraClasses="pl-16"
                      />
                    )}
                  />
                  <div className="text-green absolute  bg-grey-200 xxl:left-[1.1px] xl:left-[1.1px] lg:left-[1px] md:left-[0.5px] sm:left-[1.1px] xs:left-[1.1px]  xxl:top-[29px] xl:top-[25px] lg:top-[24.5px] md:top-[21px] sm:top-6 xs:top-6 flex justify-center items-center  w-[54px] rounded-l-md cursor-pointer  xxl:h-[54px] xl:h-[43px] lg:h-[43.5px] md:h-[39px] sm:h-[40px] xs:h-[40px]">
                    <img src={Instagram} alt="insta" className="w-5 h-5" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex  items-center justify-center mt-[76px] mb-[1.7rem]">
        <Button
          width="w-[410px]"
          type="submit"
          border="border-green"
          disabled={updateCompanyDetailsLoad || mediaUploadLoad}
        >
          <div className="flex w-fit font-semibold  px-5 justify-center items-center text-white">
            {updateCompanyDetailsLoad || mediaUploadLoad ? <Spinner /> : "Save"}
          </div>
        </Button>
      </div>

      {companyImage ? (
        <Modal
          handleClose={handleCloseImageCropper}
          headerComponent={null}
          footerComponent={null}
          shouldCloseOnClickOutside={false}
        >
          <div className="w-[600px] xxl:h-[670px] lg:h-[660px] md:h-[650px] flex justify-center">
            <div className="w-[500px] h-[500px] mt-12">
              <ImageCropper
                {...{
                  image: companyImage,
                  setImage: setCompanyImage,
                  upload: (data) => handleUploadImage(data),
                  mediaUploadLoad: mediaUploadLoad,
                }}
              />
            </div>
          </div>
        </Modal>
      ) : null}
    </form>
  );
};

export default Business;
