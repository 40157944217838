import { useQuery } from '@tanstack/react-query';
import { kycData } from 'api';

export default function useKYCData({ refetchOnMount = true } = {}) {
  const Request = useQuery({
    queryFn: ({ queryKey }) => kycData(queryKey[1] || {}),
    queryKey: ['kycData'],
    refetchOnMount,
    select: (data) => data?.data?.data || null,
  });

  return { ...Request };
}
