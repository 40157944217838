import React, { useEffect, useState } from 'react';
import { Button, CountrySelect, Input } from 'components';
import { useSendOtp, useServerValidator } from 'hooks';
import { Controller } from 'react-hook-form';
import Spinner from 'components/Spinner';
import { ayt, numericRegx } from 'shared/resources';
import { Refresh } from 'assets/images';

export default function StepTwo({
  control,
  errors,
  handleNextStep,
  step,
  ifErrors,
  watch,
  clearErrors,
  setError,
}) {
  const [isOTPSentSuccessfull, setIsOTPSentSuccessfull] = useState(false);
  const [counter, setCounter] = useState(60);
  const [isConterRunning, setIsConterRunning] = useState(false);
  const { mutate: sendOTP, isPending: sendingOTP } = useSendOtp({
    acionFn: setIsOTPSentSuccessfull,
    counterFn: setCounter,
  });

  const [watchPhone, watchVerificationCode] = watch(['phone', 'otp']);

  const { loadingServerError: serverPhoneErrorLoading, isValid } =
    useServerValidator({
      payload: {
        watch: watchPhone,
        value: watchPhone,
        type: 'phone',
        key: 'phone',
      },
      successAction: clearErrors,
      errorAction: setError,
      delay: 1000,
      shouldValidate: watchPhone && watchPhone?.length > 9,
    });

  const {
    loadingServerError: serverVerificationErrorLoading,
    isValid: isValidVerificationCode,
  } = useServerValidator({
    payload: {
      otp: watchVerificationCode,
      type: 'otp',
      key: 'otp',
      watch: watchVerificationCode,
      value: watchPhone,
    },
    successAction: clearErrors,
    errorAction: setError,
    delay: 1000,
  });

  useEffect(() => {
    if (isOTPSentSuccessfull) {
      if (counter > 0) {
        if (!isConterRunning) {
          setIsConterRunning(true);
        }
        setTimeout(() => setCounter(counter - 1), 1000);
      } else {
        setIsConterRunning(false);
      }
    }
  }, [isOTPSentSuccessfull, counter]);
  return (
    <div className="flex flex-col  xxl:mt-8 xl:mt-5 lg:mt-5 md:mt-4 ">
      <div className="flex flex-col  ">
        <div className="xxl:mt-8 xl:mt-5 lg:mt-5 md:mt-4 sm:mt-4 xs:mt-4">
          <CountrySelect withTooltip={true} tooltipPosition="t" />
        </div>
        <div className="xxl:mt-8 xl:mt-5 lg:mt-5 md:mt-4 sm:mt-4 xs:mt-4">
          <Controller
            name="phone"
            control={control}
            render={({ field }) => {
              const { onChange } = field;
              return (
                <div>
                  <div className="xs:flex-col sm:flex-row flex items-start gap-3 ">
                    <div className="w-full">
                      <Input
                        {...field}
                        label="Phone number"
                        error={errors?.phone?.message}
                        autoComplete="off"
                        maxLength="17"
                        onChange={(e) => {
                          const aytNumber = ayt(e.target.value);
                          onChange(aytNumber);
                        }}
                        isServerValidationLoading={serverPhoneErrorLoading}
                      />
                    </div>
                    <div className="xs:w-full  sm:w-6/12 xxl:mt-7 xl:mt-6 lg:mt-6 md:mt-5 sm:mt-6 xs:mt-1  ">
                      <Button
                        disabled={
                          !!errors?.phone?.message ||
                          (watchPhone && watchPhone?.length < 14) ||
                          !isValid ||
                          isConterRunning
                        }
                        onClick={() =>
                          sendOTP({
                            type: 'send_otp',
                            phone: watchPhone,
                            country: 'NG',
                          })
                        }
                      >
                        {sendingOTP ? (
                          <Spinner />
                        ) : (
                          <div className="flex gap-1 items-center">
                            {isOTPSentSuccessfull ? <Refresh /> : null}
                            {isOTPSentSuccessfull ? 'Resend code' : 'Send code'}
                            {isOTPSentSuccessfull ? (
                              counter ? (
                                <>&nbsp;{counter}s</>
                              ) : null
                            ) : null}
                          </div>
                        )}
                      </Button>
                    </div>
                  </div>
                  <div className="xxl:text-base xl:text-[15px] lg:text-sm md:text-xs sm:text-sm xs:text-sm text-grey-600  mt-1">
                    We would send you a verification code to confirm this is
                    your phone number.
                  </div>
                </div>
              );
            }}
          />
        </div>
      </div>
      <div className="xxl:mt-8 xl:mt-6 lg:mt-5 md:mt-4 sm:mt-4 xs:mt-4">
        {isOTPSentSuccessfull ? (
          <div className='h-full flex flex-col justify-between xs:min-h-[calc(100vh_-_450px)] md:min-h-[100%]' >
            <div className='w-full h-100 flex flex-col'>

              <Controller
                name="otp"
                control={control}
                render={({ field }) => {
                  const { onChange } = field;
                  return (
                    <Input
                      {...field}
                      onChange={(e) => {
                        if (numericRegx.test(e.target.value)) {
                          onChange(e.target.value);
                        } else {
                          return;
                        }
                      }}
                      autoComplete="off"
                      label="Verification code"
                      error={errors?.otp?.message}
                      maxLength="6"
                      isServerValidationLoading={serverVerificationErrorLoading}
                    />
                  );
                }}
              />
              <div className="xxl:text-base xl:text-[15px] lg:text-sm md:text-xs sm:text-sm xs:text-sm text-grey-600  mt-1">
                Enter the verification code sent to your phone number.
              </div>
            </div>
            <div className="mt-7">
              <Button
                disabled={ifErrors || !isValid || !isValidVerificationCode}
                onClick={() => handleNextStep(step)}
              >
                Continue
              </Button>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
