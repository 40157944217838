import { LightningFill } from 'assets/images';

const NumberStatusComponent = () => {
  return (
    <>
      <div className="flex justify-between bg-white border border-grey-400 w-[514px] h-[79px] mt-[31px] shadow-[0_4px_20px_0px_rgba(0,0,0,0.10)] rounded-md px-5 py-[15px]">
        <div className="flex flex-col ">
          <div className="text-grey-600 text-sm">Ported number</div>
          <div className="text-grey-900 text-lg font-medium">
            +234 70 8254 5619
          </div>
        </div>
        <div className="flex gap-[5px] text-grey-900 text-base font-medium items-center ">
          Awaiting customer
          <div className="bg-grey-300 w-6 h-6 rounded-full flex items-center justify-center">
            <LightningFill className="text-grey-700" height={22} />
          </div>
        </div>
      </div>
      <div className="text-green text-base font-semibold flex justify-center mt-[42px] cursor-pointer">
        Cancel this porting
      </div>
    </>
  );
};
export default NumberStatusComponent;
