import React from 'react';
import { Chart as GoogleChart } from 'react-google-charts';

function useChart() {
  const getChart = (
    type = '',
    data = [],
    options = {
      width: '100%',
      chartArea: {
        width: '100%',
        left: 0,
        top: 0,
        bottom: 20,
        height: '100%',
      },
      legend: 'none',
      colors: ['#445626', '#91b888', 'red'],
      isStacked: true,
      tooltip: {
        textStyle: {
          fontName: 'RoobertRegular, system-ui, sans-serif',
        },
      },
      hAxis: {
        textStyle: {
          fontName: 'RoobertRegular, system-ui, sans-serif',
        },
      },
    },
  ) => {
    return (
      <GoogleChart
        className="xxl:max-h-[350px] xxl:min-h-[350px] xl:max-h-[250px] xl:min-h-[250px] lg:max-h-[255px] lg:min-h-[255px] md:max-h-[280px] md:min-h-[280px]"
        chartType={type}
        loader={<div>Loading Chart</div>}
        data={data}
        options={options}
      />
    );
  };
  return { getChart };
}
export default useChart;
