export const dropdownActions = {
  DELETE_BULK_SESSION: "delete_bulk_session",
  PLAY_RECORDING: "play_recording",
};
export const initialModalState = {
  modalType: "",
  isShow: false,
  elementData: {},
  type: "",
};
