import React, { useState } from "react";
import NumberDetail from "./NumberDetail";
import NumberListing from "./NumberListing";

const PurchaseNumberModal = ({
  showModal = {},
  handleClose = () => null,
  isMonthly = true,
}) => {
  const { elementData = {} } = showModal;
  const [selectedNumber, setSelectedNumber] = useState(null);

  return (
    <div className="min-w-[500px]">
      {selectedNumber ? (
        <NumberDetail
          handleClose={handleClose}
          selectedNumber={selectedNumber}
          setSelectedNumber={setSelectedNumber}
          planIndex={elementData["planIndex"] || 0}
          isMonthly={isMonthly}
        />
      ) : (
        <NumberListing
          setSelectedNumber={setSelectedNumber}
          handleClose={handleClose}
          planIndex={elementData["planIndex"] || 0}
          isMonthly={isMonthly}
        />
      )}
    </div>
  );
};

export default PurchaseNumberModal;
