import { X } from 'assets/images';
import CustomAudioPlayer from 'components/CustomAudioPlayer';
import Skeletons from 'components/Skeletons';
import Spinner from 'components/Spinner';
import { useAuth } from 'hooks/useAuth';
import React, { useEffect, useState } from 'react';

const LoadingPlayer = () => {
  return (
    <div className="w-full flex justify-between gap-4 ">
      <div className="flex gap-4 items-center">
        <span className=" w-12 h-12 flex items-center justify-center">
          <Skeletons type="circle" />
        </span>
        <span className="w-40">
          <Skeletons height="h-4" />
          <Skeletons height="h-4" />
        </span>
      </div>
    </div>
  );
};

const PlayAudioModal = ({
  showModal,
  handleClose = () => null,
  labelAccessorKey = '',
  fileNameAccessorKey,
  type = 'greeting',
  customLabel = '',
}) => {
  const { user } = useAuth();
  const itemElement = showModal?.elementData;
  const [mediaUrl, setMediaUrl] = useState('');

  useEffect(() => {
    if (itemElement?.[fileNameAccessorKey]) {
      setMediaUrl(
        `${process.env.REACT_APP_BASE_API_URL}${
          user?.company_uuid || user?.uuid
        }/${type}/${itemElement?.[fileNameAccessorKey]}`,
      );
    }
  }, [itemElement?.[fileNameAccessorKey]]);

  return (
    <div className="min-w-[500px]  p-6">
      <div className="flex items-center justify-between">
        <div className="text-xl font-semibold text-black">
          {customLabel || itemElement?.[labelAccessorKey]}
        </div>
        <X
          className="w-6 h-6 text-grey-700 cursor-pointer"
          onClick={() => handleClose()}
        />
      </div>
      {mediaUrl ? (
        <div className=" w-full border px-3 flex gap-1.5 items-center rounded-md   font-medium text-base text-grey-900 border-grey-400 focus:border-green focus:ring-green-200 bg-grey-100  xxl:h-[56px] xl:h-[45px] lg:h-[45px] md:h-[41px] sm:h-[40px] xs:h-[40px] mt-5">
          <CustomAudioPlayer
            barWidth={2}
            loadingItem={LoadingPlayer}
            srcUrl={mediaUrl}
            withIcon={false}
          />
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default PlayAudioModal;
