import { Input } from 'components';
import React, { useState } from 'react';
import ChatListItem from '../ChatListItem';
import { Outlet } from 'react-router-dom';

const ChatList = () => {
  const [search, setSearch] = useState('');

  return (
    <div className="flex">
      <div className="bg-white  border-grey-300 border-r">
        <div className="pt-6 px-4 pb-4">
          <Input
            type="text"
            name={'search'}
            value={search}
            height="h-10"
            onWhitebg={true}
            onChange={(e) => setSearch(e.target.value)}
            extraClasses="pl-10 mb-0 placeholder:text-grey-600 placeholder:font-normal placeholder:text-sm "
            placeholder={'Search conversation'}
            withSearchIcon={true}
          />
        </div>
        <span className="flex border-grey-300 border-b p-0 w-full "></span>

        <div className="w-96 flex flex-col gap-2 bg-white pt-2.5 h-[calc(100vh_-_161px)] overflow-auto">
          <span>
            {Array.from({ length: 10 }, (_, i) => i).map((val) => {
              return <ChatListItem key={val} item={val} />;
            })}
          </span>
        </div>
      </div>
      <div className="w-full">
        <Outlet />
      </div>
    </div>
  );
};

export default ChatList;
