import { Trash } from 'assets/images';
import { Avatar, EllipsisTextWithTooltip } from 'components';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

const selectedNameClasses = {
  true: 'font-semibold text-sm tracking-[0.41px]',
  false: 'font-medium text-sm text-grey-800 tracking-[0.41px]',
};
const selectedDescriptionClasses = {
  true: 'font-semibold text-xs tracking-[0.41px]',
  false: 'font-medium text-grey-700 text-xs tracking-[0.41px]',
};

const ChatListItem = ({ item }) => {
  const [isSelected, setIsSelected] = useState(false);

  const handleClassName = (active) => {
    setIsSelected(active);
  };

  return (
    <NavLink
      to={`${item}`}
      className={({ isActive }) => handleClassName(isActive)}
    >
      <span
        className={`p-4 flex justify-between   items-center cursor-pointer hover:bg-grey-300 ${
          isSelected ? 'bg-grey-300' : ''
        }`}
      >
        <div className="flex gap-3">
          <span className="flex items-start">
            <Avatar name={`${item} Deanna Curtis`} />
          </span>

          <span className="flex items-start flex-col gap-1">
            <span className={selectedNameClasses[isSelected]}>
              Deanna Curtis
            </span>
            <span className={selectedDescriptionClasses[isSelected]}>
              <EllipsisTextWithTooltip
                withTooltip={false}
                charLength={30}
                string="Please I would like an update on this as soon as possible"
              />
            </span>
          </span>
        </div>

        <span className="flex items-end flex-col gap-1">
          <span className="text-grey-600 font-medium text-xs">9:41 PM</span>
          <div className="flex justify-center items-center gap-2.5">
            <div className="flex justify-center items-center min-w-[18px] min-h-[18px] rounded-full bg-green text-white text-[11px] font-medium px-[6px]  tracking-[0.7px]">
              100
            </div>
            {/* <span className="bg-green text-white text-xs font-medium me-2 px-2.5 py-0.5 rounded-full ">
              1
            </span> */}
            <span>
              <Trash className="text-danger" />
            </span>
          </div>
        </span>
      </span>
    </NavLink>
  );
};

export default ChatListItem;
