import { useEffect, useRef, useState } from 'react';

const width = 320;
const height = 320;

const useSelfieCapture = () => {
  const selfieCamRef = useRef();
  const [selfie, setSelfie] = useState(null);
  const [video, setVideo] = useState(null);
  const [canvas, setCanvas] = useState(null);
  const [stream, setStream] = useState(null);
  const [cameraError, setCameraError] = useState(null);

  const videoTag = <video className="w-full" ref={selfieCamRef} autoPlay />;

  useEffect(() => {
    return () => disableCamera();
  }, []);

  useEffect(() => {
    if (selfieCamRef && selfieCamRef?.current) {
      const video = selfieCamRef?.current;
      setVideo(video);
    } else {
      setVideo(null);
    }
  }, [selfieCamRef?.current]);

  useEffect(() => {
    if (video) {
      startCamera();
    }
  }, [video]);

  const startCamera = () => {
    navigator.mediaDevices
      .getUserMedia({
        video: true,
        audio: false,
      })
      .then(function (stream) {
        setStream(stream);
        window.selfieStream = stream;
        video.srcObject = stream;
        const canvas = document.createElement('canvas');
        canvas.width = stream.getVideoTracks()?.[0]?.getSettings()?.width;
        canvas.height = stream.getVideoTracks()?.[0].getSettings()?.height;
        setCanvas(canvas);
      })
      .catch((err) => {
        console.log({ err });
        if (err?.message === 'Permission denied') {
          setCameraError('Camera permission denied');
          console.log({ err });
        }
      });
  };

  const takeSelfie = () => {
    if (!canvas) return;
    let context = canvas.getContext('2d');
    if (width && height) {
      context.drawImage(video, 0, 0);
      const selfieData = canvas.toDataURL('image/png');
      setSelfie(selfieData);
      disableCamera();
    }
  };

  const clearSelfie = () => {
    setSelfie(null);
  };

  const disableCamera = () => {
    stream && stream.getTracks().forEach((track) => track.stop());
    window.selfieStream &&
      window.selfieStream.getTracks().forEach((track) => track.stop());
  };

  return { selfie, takeSelfie, clearSelfie, videoTag, cameraError };
};

export default useSelfieCapture;
