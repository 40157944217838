import { useMutation } from "@tanstack/react-query";
import { batchLists } from "api";

export default function useBatchList() {
  const { mutate, data, isError, isPending } = useMutation({
    mutationFn: batchLists,
    mutationKey: ["batchLists"],
  });

  return {
    batchMutate: mutate,
    batchData: data,
    isBatchError: isError,
    isBatchPending: isPending,
  };
}
