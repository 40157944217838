import React, { useEffect, useState } from "react";

const CallStatusModal = ({ callDetails, onClose }) => {
  const [formattedTime, setFormattedTime] = useState("00:00:00");

  useEffect(() => {
    if (!callDetails?.joinedAt) return; // Return early if joinedAt is not provided

    const joinedAt = new Date(callDetails?.joinedAt);

    const updateDuration = () => {
      const now = new Date();
      const diff = now - joinedAt;

      const hours = Math.floor(diff / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);

      setFormattedTime(
        `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
          2,
          "0"
        )}:${String(seconds).padStart(2, "0")}`
      );
    };

    // Update the duration every second
    const intervalId = setInterval(updateDuration, 1000);

    // Cleanup the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [callDetails?.joinedAt]); // Depend on joinedAt

  return (
    <div className="fixed inset-0 flex items-center justify-center ">
      <div>
        <div className="flex align-center justify-center">
          <h2 className="text-xl font-bold mb-4">Call Status Details</h2>
          <button
            onClick={onClose}
            className="pl-[100px] pb-3 rounded font-xl bg-red-300"
          >
            <p className="text-2xl" style={{ color: "grey" }}>
              X
            </p>
          </button>
        </div>
        <ul>
          <li>
            <strong>Phone Number:</strong> {callDetails?.phoneNumber || "N/A"}
          </li>
          <li>
            <strong>Status:</strong> {callDetails?.status || "N/A"}
          </li>
          <li>
            <strong>Duration:</strong> {formattedTime || "00:00:00"}
          </li>
          <li>
            <strong>First Name:</strong> {callDetails?.user?.first_name || "N/A"}
          </li>
          <li>
            <strong>Last Name:</strong> {callDetails?.user?.last_name || "N/A"}
          </li>
          <li>
            <strong>Batch Name:</strong> {callDetails?.batch || "N/A"}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default CallStatusModal;
