import { useQuery } from '@tanstack/react-query';
import { getSavedCardsList } from 'api';

export default function useSavedCards() {
  const request = useQuery({
    queryFn: getSavedCardsList,
    queryKey: ['getSavedCardsList'],
  });
  return { ...request, cardListing: request?.data?.data?.data || [] };
}
