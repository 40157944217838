import { useContext, createContext, useMemo, useState, useEffect } from 'react';
import { dummyChatData } from './config';

const ChatContext = createContext({
  getAllChats: () => {
    return [];
  },
});

export const Chat = ({ children }) => {
  const [allChats, setAllChats] = useState([]);

  useEffect(() => {
    setAllChats(dummyChatData);
  }, []);

  function getAllChats() {
    return allChats;
  }

  const values = useMemo(
    () => ({
      getAllChats,
    }),
    [allChats],
  );
  return <ChatContext.Provider value={values}>{children}</ChatContext.Provider>;
};

export const useChat = () => useContext(ChatContext);
