import { useMutation } from '@tanstack/react-query';
import { validate } from 'api';
import useDebounce from 'hooks/useDebounce';
import { useEffect, useState } from 'react';

export default function useServerValidator({
  successAction = () => null,
  successCallback = () => null,
  errorAction = () => null,
  delay = 1000,
  payload = {},
  shouldValidate = true,
}) {
  let initialValidate = !shouldValidate;
  const [isValid, setIsValid] = useState(initialValidate);
  const debouncedValue = useDebounce(payload?.watch, delay);
  const { mutate, isPending } = useMutation({
    mutationFn: (payload) => validate(payload),
    mutationKey: [`validate`],
    onSuccess: (data) => {
      const status = data?.status;
      const responseData = data?.data;
      if (status !== 200) {
        setIsValid(false);
        errorAction(
          `${payload?.key}`,
          {
            type: `${payload?.key}-custom-server-error`,
            message: responseData?.error?.message,
          },
          {
            shouldFocus: true,
          },
        );
        return;
      }

      setIsValid(true);
      successAction([`${payload?.key}`]);
      successCallback(responseData?.data?.data?.token);
    },
  });

  useEffect(() => {
    if (debouncedValue && shouldValidate) {
      delete payload?.watch;
      delete payload?.key;
      mutate(payload);
    }
  }, [debouncedValue]);

  useEffect(() => {
    if (!shouldValidate) {
      if (!payload?.watch) {
        setIsValid(true);
      }
    }
  }, [shouldValidate]);

  return { loadingServerError: isPending, isValid };
}
