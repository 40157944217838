import { Arrow, PortPhoneIcon, X } from 'assets/images';
import { Button } from 'components';
import React from 'react';
import { useNavigate } from 'react-router-dom';
const PortNumberModal = ({ handleClose = () => null }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="flex justify-end items-center p-4">
        <button type="button" onClick={() => handleClose()}>
          <X className="text-grey-600 w-6 h-6" />
        </button>
      </div>
      <div className="text-center px-16 pb-5 justify-center">
        <div className="flex justify-center  mt-8">
          <PortPhoneIcon />
        </div>
        <div className="text-4xl text-black font-semibold	 mt-4 mb-1">
          Port existing number
        </div>
        <div className="text-grey-700 text-base	mt-4 w-96 brea-all">
          If you move a mobile number to Fonu it will become a virtual mobile
          number. This means that we will not be able to provide a SIM card for
          your number. Calls will be received and made from your number via your
          Fonu trunk instead.
        </div>
        <div className="mt-10 pb-14">
          <Button type="button" onClick={() => navigate('form')}>
            <div className="flex w-full px-5 justify-center items-center gap-2">
              Continue porting <Arrow className="text-grey-600" />
            </div>
          </Button>
        </div>
      </div>
    </>
  );
};

export default PortNumberModal;
