import { useUserDetailsByParams } from 'hooks';
import React, { useEffect, useState } from 'react';
import { darkenColor, lightenColor, stringToColour } from 'shared/resources';

const initialName = 'Unknown';

const classes = {
  default: 'rounded-full overflow-hidden cursor-pointer',
  icon: 'rounded-md bg-white border  border-grey-400 p-1',
};
const Avatar = ({
  name = '',
  size = '40',
  mediaUrl = '',
  number = '',
  inGroup = false,
  type = 'default',
  // height = 'sm:h-10',
  width = 'sm:w-10',
}) => {
  const [isBrokenImage, setIsBrokenImage] = useState(false);
  const [userName, serUserName] = useState(initialName);
  const [memberList, setMemberList] = useState({});
  const { mutateAsync, data } = useUserDetailsByParams();

  useEffect(() => {
    if (!data?.data && number && number?.length <= 4) {
      mutateAsync({ extension: number });
    } else {
      setMemberList(data?.data?.data ?? {});
    }
  }, [data, number]);

  useEffect(() => {
    if (number) {
      if (memberList) {
        const selectedUser = memberList;

        serUserName(
          `${selectedUser?.first_name} ${selectedUser?.last_name}` ??
            initialName,
        );
      } else {
        serUserName(initialName);
      }
    } else {
      serUserName(initialName);
    }

    return () => {
      serUserName(initialName);
    };
  }, [number, memberList]);

  const NAME = name || userName || 'Unknown';
  const nameColour = stringToColour(NAME);
  const lightColor = lightenColor(nameColour, 90);
  const darkColor = darkenColor(nameColour, 80);
  return (
    <div
      style={{
        width: `${size}px`,
        height: `${size}px`,
        fontSize: `${size / 2}px`,
      }}
      className={` ${width} rounded-full border border-grey-200  xs:text-xs sm:text-lg ${
        classes[type]
      } ${inGroup ? 'border-white border' : ''}`}
    >
      {mediaUrl ? (
        !isBrokenImage ? (
          <img
            loading="lazy"
            src={mediaUrl}
            onError={() => setIsBrokenImage(true)}
            className="w-full h-full object-contain"
            alt={`${NAME}-alt-avtar`}
          />
        ) : (
          <div
            style={{
              color: lightColor,
              background: darkColor,
              gap: '1.5px',
            }}
            className={`flex items-center rounded-full justify-center text-sm font-bold h-full w-full shadow-md`}
          >
            <span className="">
              {NAME?.split(' ')?.[0]?.charAt(0)?.toUpperCase()}
            </span>
            {NAME?.split(' ')?.[1] ? (
              <span className="">
                {NAME?.split(' ')?.[1]?.charAt(0)?.toUpperCase()}
              </span>
            ) : null}
          </div>
        )
      ) : (
        <div
          style={{
            color: lightColor,
            background: darkColor,
            gap: '1.5px',
          }}
          className={`flex items-center rounded-full justify-center text-sm font-bold h-full w-full shadow-md`}
        >
          <span className="">
            {NAME?.split(' ')?.[0]?.charAt(0)?.toUpperCase()}
          </span>
          {NAME?.split(' ')?.[1] ? (
            <span className="">
              {NAME?.split(' ')?.[1]?.charAt(0)?.toUpperCase()}
            </span>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default React.memo(Avatar);
