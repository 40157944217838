import { Check, Edit, X } from 'assets/images';
import Input from 'components/Input';
import Spinner from 'components/Spinner';
import React, { useEffect, useState } from 'react';

const InlineEdit = ({ value = '', editFunction = () => null }) => {
  const [newLabel, setNewLabel] = useState(value);
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setNewLabel(value);
  }, [value]);

  const handleSubmit = async () => {
    setIsLoading(true);
    setIsEdit(false);
    await editFunction({ newVal: newLabel });
    setIsLoading(false);
  };

  return (
    <div>
      {isEdit ? (
        <div className="group flex items-center">
          <Input
            autoComplete="off"
            onWhitebg={true}
            value={newLabel}
            onChange={(e) => {
              setNewLabel(e.target.value);
            }}
          />
          {isLoading ? (
            <Spinner />
          ) : (
            <div className="flex gap-2 items-center ml-2">
              <span
                className="hidden group-hover:flex cursor-pointer"
                onClick={() => {
                  handleSubmit();
                }}
              >
                <Check className="text-green" />
              </span>
              <span
                className="hidden group-hover:flex cursor-pointer"
                onClick={() => {
                  setIsEdit(false);
                }}
              >
                <X className="text-danger h-5 w-5" />
              </span>
            </div>
          )}
        </div>
      ) : (
        <div className="flex gap-1 group items-center">
          <span>{value}</span>
          {isLoading ? (
            <div className="ml-2">
            <Spinner />
            </div>
          ) : (
            <span
              className="hidden group-hover:flex cursor-pointer"
              onClick={() => {
                setNewLabel(value);
                setIsEdit(true);
              }}
            >
              <Edit className="text-grey-700 h-5 w-5" />
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default InlineEdit;
