import React, { useRef } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import Button from 'components/Button';
import Spinner from 'components/Spinner';

const ImageCropper = ({ image, setImage, upload, mediaUploadLoad }) => {
  const cropperRef = useRef(null);

  const getCropData = async () => {
    if (typeof cropperRef.current?.cropper !== 'undefined') {
      const imageData = await cropperRef.current?.cropper
        .getCroppedCanvas()
        .toDataURL();
      upload(imageData);
    }
  };

  const handleCloseModal = () => {
    setImage(null);
  };

  return (
    <>
      <Cropper
        ref={cropperRef}
        zoomTo={0.5}
        style={{ height: '100%', width: '100%' }}
        initialAspectRatio={1}
        preview=".img-preview"
        src={image}
        viewMode={1}
        minCropBoxHeight={10}
        minCropBoxWidth={10}
        background={false}
        responsive={true}
        autoCropArea={1}
        checkOrientation={false}
        guides={true}
      />

      <div className="flex gap-2 mt-2">
        <Button type="button" onClick={() => getCropData()}>
          {mediaUploadLoad ? <Spinner /> : 'Confirm'}
        </Button>

        <Button
          background="danger"
          type="button"
          onClick={() => handleCloseModal()}
        >
          Close
        </Button>
      </div>
    </>
  );
};

export default ImageCropper;
