import { LogoBlack } from 'assets/images';
import React, { useState } from 'react';
import EmailVerification from './EmailVerification';
import PhoneVerification from './PhoneVerification';
import SetPassword from './SetPassword';
import { useOutletContext } from 'react-router-dom';
const Verification = () => {
  const { verificationData } = useOutletContext();
  const [emailVerified, setEmailVerified] = useState(false);
  const [phoneVerified, setPhoneVerified] = useState(false);

  if (
    verificationData?.data?.data?.verified_email === 'Y' &&
    verificationData?.data?.data?.verified_phone === 'Y'
  ) {
    return (
      <div className="flex h-screen ">
        <div className="bg-green-50 w-screen overflow-auto relative xxl:pt-10 xl:pt-10 lg:pt-8 md:pt-5 sm:pt-8 xs:pt-5">
          <div className="flex gap-1 items-center cursor-pointer pl-4">
            <LogoBlack className="w-20" />
          </div>
          <div className="w-full h-3/4 flex items-center justify-center ">
            <div className="flex flex-col gap-10 max-w-[433px] items-start">
              <SetPassword />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex h-screen ">
      <div className="bg-green-50 w-screen overflow-auto relative xxl:pt-10 xl:pt-10 lg:pt-8 md:pt-5 sm:pt-8 xs:pt-5">
        <div className="flex gap-1 items-center cursor-pointer pl-4">
          <LogoBlack className="w-20" />
        </div>
        <div className="w-full h-3/4 flex items-center justify-center ">
          <div className="flex flex-col gap-10 max-w-[433px] items-start">
            {!(emailVerified && phoneVerified) ? (
              <>
                <EmailVerification
                  emailVerified={emailVerified}
                  setEmailVerified={setEmailVerified}
                />
                <PhoneVerification
                  emailVerified={emailVerified}
                  phoneVerified={phoneVerified}
                  setPhoneVerified={setPhoneVerified}
                />
              </>
            ) : (
              <SetPassword />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Verification;
