import { useVerifyEmail } from 'hooks';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Spinner } from 'components';
import { Check } from 'assets/images';

const EmailVerification = ({ emailVerified, setEmailVerified }) => {
  const { token } = useParams();

  const { isPending: verifyEmailLoad, mutate: verifyEmailMutate } =
    useVerifyEmail({ onSuccessFn, onErrorFn });

  useEffect(() => {
    verifyEmailMutate({ token });
  }, []);

  function onSuccessFn() {
    setEmailVerified(true);
  }
  function onErrorFn() {
    setEmailVerified(false);
  }

  return (
    <>
      <div className="flex gap-6">
        {emailVerified ? (
          <div className="rounded-full w-10 min-w-[40px] min-h-[40px] h-10 text-white flex items-center justify-center bg-green">
            <Check />
          </div>
        ) : (
          <div className="rounded-full w-10 min-w-[40px] min-h-[40px] h-10 text-green flex items-center justify-center bg-green-200">
            1
          </div>
        )}
        <div className="flex flex-col  w-full max-w-[433px]">
          <div className="xxl:text-3xl xl:text-[28px] lg:text-[24px] md:text-[22px] text-grey-900 font-medium">
            {emailVerified ? 'Email verified' : 'Email verification'}
          </div>
          <div className="flex gap-4 items-center">
            <div className="xxl:text-base xl:text-[15px] lg:text-[15px] md:text-[14px] font-normal text-grey-700 xxl:mt-[.1em]">
              {emailVerified
                ? 'Your email has been verified'
                : 'Your email is being verified'}
            </div>
            <div>{!emailVerified || verifyEmailLoad ? <Spinner /> : null}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailVerification;
