import { Button } from 'components';
import CustomSelect from 'components/CustomSelect';
import ForwardToInput from 'pages/PhoneNumbers/SetForwardingModal/ForwardToInput';
import React from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import { Minus, Plus } from 'assets/images';

const initialState = { key: '', type: '', value: '' };

const keyArray = [
  { val: '0' },
  { val: '1' },
  { val: '2' },
  { val: '3' },
  { val: '4' },
  { val: '5' },
  { val: '6' },
  { val: '7' },
  { val: '8' },
  { val: '9' },
];

const ActionComponent = ({
  watch,
  addAction = () => null,
  removeAction = () => null,
  index,
  fields = [],
  control,
  setValue,
  errors,
  clearErrors,
}) => {
  watch(`ivr_option[${index}].key`);

  const RenderKeyItem = ({ item }) => {
    const isDisabled = fields.some((field) => field.key === item?.val);
    return (
      <option
        className="w-full h-full flex  justify-start gap-2 items-center py-2 px-3"
        disabled={isDisabled}
        key={item?.val}
        value={item?.val}
      >
        {item?.val}
      </option>
    );
  };

  return (
    <div className="flex items-center gap-6">
      <div className="min-w-[160px]">
        <Controller
          name={`ivr_option[${index}].key`}
          control={control}
          render={({ field }) => (
            <CustomSelect
              {...field}
              height="xxl:h-14 xl:h-12"
              placeholder="Select key"
              onWhitebg={true}
              label="Key"
              error={errors?.ivr_option?.[index]?.key?.message}
              ItemComponent={RenderKeyItem}
              labelKey={'val'}
              valueKey={'val'}
              isMulti={false}
              optionsList={keyArray || []}
            />
          )}
        />
      </div>
      <div className="min-w-[630px] w-full">
        <ForwardToInput
          clearErrors={clearErrors}
          forward_type_key={`ivr_option[${index}].type`}
          forward_value_key={`ivr_option.[${index}].value`}
          forward_type_error={errors?.ivr_option?.[index]?.type?.message}
          forward_value_error={errors?.ivr_option?.[index]?.value?.message}
          control={control}
          watch={watch}
          label="Destination"
          setValue={setValue}
        />
      </div>
      <div className=" flex items-center">
        {fields?.length !== 1 && ![0, 8].includes(index) && (
          <div className="mt-6">
            <Button
              width="w-[56px]"
              background="transparent"
              type="button"
              onClick={() => removeAction(index, fields)}
            >
              <div className="text-danger text-2xl">
                <Minus />
              </div>
            </Button>
          </div>
        )}
        <div className="mt-6">
          <Button
            type="button"
            width="w-[56px]"
            background="transparent"
            onClick={() => addAction()}
            disabled={fields?.length === 9}
          >
            <div className="text-green text-2xl">
              <Plus />
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
};
const KeyActions = ({ control, watch, setValue, errors, clearErrors }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'ivr_option',
  });

  const addAction = () => {
    append(initialState);
  };
  const removeAction = (index) => {
    remove(index);
  };
  return (
    <>
      <div className="w-full flex flex-col gap-5">
        {fields.map((field, index) => {
          return (
            <ActionComponent
              clearErrors={clearErrors}
              key={field?.id}
              watch={watch}
              addAction={addAction}
              removeAction={removeAction}
              index={index}
              fields={fields}
              control={control}
              setValue={setValue}
              errors={errors}
            />
          );
        })}
      </div>
    </>
  );
};

export default KeyActions;
