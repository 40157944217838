import { Mastercard, Trash, visa } from 'assets/images';
import { Modal, Skeletons } from 'components';
import useSavedCards from 'hooks/useSavedCards';
import React, { useState, useMemo } from 'react';
import { dropdownActions, initialModalState } from './constants';
import SetDefaultCardModal from './SetDefaultCardModal';
import DeleteCardModal from './DeleteCardModal';
import { useDeleteCard, useSetDefaultCard } from 'hooks';

const cardPlaceholderImage = {
  visa: visa,
  mastercard: Mastercard,
};

const CardItem = ({
  data,
  handleSetDefaultCard = () => null,
  deleteCardLoad,
  handleDeleteCard = () => null,
  setDefaultCardLoad,
  showModal,
  setShowModal,
  handleCloseModal = () => null,
}) => {
  const defaultCard = useMemo(
    () => data?.is_default === 'Y',
    [data?.is_default],
  );

  const ModalLookup = {
    [dropdownActions.SET_DEFAULT_CARD]: (
      <SetDefaultCardModal
        actionFn={handleSetDefaultCard}
        handleClose={handleCloseModal}
        showModal={showModal}
        isLoading={setDefaultCardLoad}
      />
    ),
    [dropdownActions.DELETE_CARD_MODAL]: (
      <DeleteCardModal
        deleteFn={handleDeleteCard}
        handleClose={handleCloseModal}
        showModal={showModal}
        isLoading={deleteCardLoad}
      />
    ),
  };

  const { modalType, isShow, type } = showModal;
  return (
    <>
      <div className="w-full flex  gap-1">
        <label
          htmlFor={data?.uuid}
          className="flex items-center px-4 h-14  justify-between w-full bg-green-50 rounded-md border border-green-100 cursor-pointer"
        >
          <div className="text-md flex gap-2 items-center ">
            <img
              src={cardPlaceholderImage[data?.card_type?.trim()?.toLowerCase()]}
              alt="MasterCard"
              className="w-8"
            />
            ****{data?.last4}
          </div>
          {!defaultCard ? (
            <div className="flex items-center gap-2">
              <input
                name={'card'}
                id={data?.uuid}
                className="checked:bg-green checked:hover:bg-green checked:active:bg-green checked:focus:bg-green focus:outline-none focus:ring-1 focus:ring-green"
                type="radio"
                onClick={() => {
                  setShowModal({
                    modalType: dropdownActions.SET_DEFAULT_CARD,
                    isShow: true,
                    elementData: data,
                    type: 'center',
                  });
                }}
              />
            </div>
          ) : (
            <div className="max-w-[90px] min-w-[90px] min-h-[29px] rounded-full bg-green text-white text-xs flex items-center justify-center font-medium cursor-pointer">
              Default card
            </div>
          )}
        </label>

        <div
          className=" min-h-[29px]   text-danger text-xs flex items-center justify-center font-medium cursor-pointer"
          onClick={() =>
            setShowModal({
              modalType: dropdownActions.DELETE_CARD_MODAL,
              isShow: true,
              elementData: data,
              type: 'center',
            })
          }
        >
          <Trash className="w-4 h-4" />
        </div>
      </div>
      {isShow && type === 'center' ? (
        <Modal
          handleClose={handleCloseModal}
          headerComponent={null}
          footerComponent={null}
          shouldCloseOnClickOutside={false}
        >
          {ModalLookup[modalType]}
        </Modal>
      ) : null}
    </>
  );
};

export const SavedCards = () => {
  const [showModal, setShowModal] = useState(initialModalState);

  const { data, isLoading } = useSavedCards();
  const cards = data?.data?.data;
  const { mutate: deleteCardMutate, isPending: deleteCardLoad } = useDeleteCard(
    { handleSuccess },
  );
  const { mutate: setDefaultCardMutate, isPending: setDefaultCardLoad } =
    useSetDefaultCard({ handleSuccess });

  function handleDeleteCard(payload) {
    deleteCardMutate(payload);
  }
  function handleSetDefaultCard(payload) {
    setDefaultCardMutate(payload);
  }
  function handleCloseModal() {
    setShowModal(initialModalState);
  }
  function handleSuccess() {
    handleCloseModal();
  }
  return (
    <div className="overflow-auto w-full px-6 pt-6 pb-6">
      <div className="flex items-center justify-between mb-5">
        <div className="text-sm text-grey-700">Manage your saved cards</div>
      </div>
      <div className="w-full rounded-md p-7 border border-grey-400 bg-white min-h-[calc(100vh_-_168px)] flex justify-center">
        <div className="max-w-[600px] w-full flex flex-col gap-2">
          {isLoading
            ? Array.from({ length: 3 }, (_, i) => i + 1).map((val) => {
                return (
                  <div className="w-full" key={val}>
                    <Skeletons height="h-14" />
                  </div>
                );
              })
            : cards?.length > 0
            ? cards?.map((card) => (
                <CardItem
                  key={card?.uuid}
                  data={card}
                  handleSetDefaultCard={handleSetDefaultCard}
                  handleDeleteCard={handleDeleteCard}
                  handleCloseModal={handleCloseModal}
                  deleteCardLoad={deleteCardLoad}
                  setDefaultCardLoad={setDefaultCardLoad}
                  showModal={showModal}
                  setShowModal={setShowModal}
                />
              ))
            : 'No saved cards'}
        </div>
      </div>
    </div>
  );
};
