import { Refresh, X } from 'assets/images';
import { Button, Input, Spinner } from 'components';
import { useSendOtp, useServerValidator } from 'hooks';
import { useAuth } from 'hooks/useAuth';
import React, { useEffect, useState } from 'react';
import { ayt, getRequiredText, numericRegx } from 'shared/resources';
import * as yup from 'yup';

const headingLookup = {
  email: 'Edit email',
  phone: 'Edit phone number',
};
const subHeadingLookup = {
  email: 'Enter the verification code sent to your email',
  phone: 'Enter the verification code sent to your phone number',
};

const emailSchema = yup.string().required(getRequiredText('Email'));
const phoneSchema = yup.string().required(getRequiredText('Phone'));

const EmailUpdateForm = ({
  email,
  setEmail = () => null,
  serverEmailErrorLoading,
  error,
  setError = () => null,
}) => {
  const onChange = (e) => {
    setEmail(e.target.value);
    emailSchema
      .validate(e.target.value, {
        abortEarly: true,
      })
      .then(() => {
        if (error) {
          setError('');
        }
      })
      .catch((error) => {
        setError(error?.message);
      });
  };
  return (
    <Input
      onWhitebg={true}
      label="Email"
      value={email}
      onChange={onChange}
      error={error}
      maxLength={50}
      isServerValidationLoading={serverEmailErrorLoading}
    />
  );
};

const PhoneUpdateForm = ({
  phone,
  setPhone,
  serverPhoneErrorLoading,
  error,
  setError,
}) => {
  const onChange = (e) => {
    setPhone(e);
    phoneSchema
      .validate(e, {
        abortEarly: true,
      })
      .then(() => {
        if (error) {
          setError('');
        }
      })
      .catch((error) => {
        setError(error?.message);
      });
  };

  return (
    <Input
      required={true}
      label="Phone number"
      autoComplete="off"
      maxLength="17"
      onChange={(e) => {
        const aytNumber = ayt(e.target.value);
        onChange(aytNumber);
      }}
      value={phone}
      error={error}
      isServerValidationLoading={serverPhoneErrorLoading}
    />
  );
};

const CodeVerification = ({
  handleClose,
  type,
  setValue,
  updateProfileFn = () => null,
  updateProfileLoad,
}) => {
  const [otp, setOtp] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const { setUser, user } = useAuth();
  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState('');

  const [activeOption] = useState(type);
  const [isOTPSentSuccessfull, setIsOTPSentSuccessfull] = useState(false);
  const [isConterRunning, setIsConterRunning] = useState(false);
  const [counter, setCounter] = useState(60);

  const { mutateAsync: sendOTP, isPending: sendingOTP } = useSendOtp({
    acionFn: setIsOTPSentSuccessfull,
    counterFn: setCounter,
  });

  const {
    loadingServerError: serverEmailErrorLoading,
  } = useServerValidator({
    payload: {
      watch: email,
      value: email,
      type: 'email',
      key: 'email',
    },
    successAction: () => {
      setEmailError('');
    },
    errorAction: (_, value) => {
      setEmailError(value?.message);
    },
    delay: 700,
    shouldValidate: email && email?.length > 0,
  });

  const {
    loadingServerError: serverPhoneErrorLoading,
  } = useServerValidator({
    payload: {
      watch: phone,
      value: phone,
      type: 'phone',
      key: 'phone',
    },
    successAction: () => {
      setPhoneError('');
    },
    errorAction: (_, value) => {
      setPhoneError(value?.message);
    },
    delay: 700,
    shouldValidate: phone && phone.length > 4,
  });

  useEffect(() => {
    if (isOTPSentSuccessfull) {
      if (counter > 0) {
        if (!isConterRunning) {
          setIsConterRunning(true);
        }
        setTimeout(() => setCounter(counter - 1), 1000);
      } else {
        setIsConterRunning(false);
      }
    }
  }, [isOTPSentSuccessfull, counter]);

  const FormLookup = {
    email: (
      <EmailUpdateForm
        email={email}
        setEmail={setEmail}
        error={emailError}
        setError={setEmailError}
        serverEmailErrorLoading={serverEmailErrorLoading}
      />
    ),
    phone: (
      <PhoneUpdateForm
        phone={phone}
        setPhone={setPhone}
        error={phoneError}
        setError={setPhoneError}
        serverPhoneErrorLoading={serverPhoneErrorLoading}
      />
    ),
  };
  const onSubmit = async () => {
    console.log('Type', type);

    switch (type) {
      case 'email':
        sendOTP({
          type: 'validate_otp',
          otp,
          country: 'NG',
          template_type: 'editEmailProfile',
          email,
        }).then(async (res) => {
          if (res?.data?.success) {
            updateProfileFn({ email }).then((response) => {
              if (response?.data?.success) {
                setUser({ ...user, email });
                setValue(type, email);
              }
              handleClose();
            });
          }
        });
        break;
      case 'phone':
        sendOTP({
          type: 'validate_otp',
          otp,
          country: 'NG',
          phone,
        }).then((res) => {
          if (res?.data?.success) {
            updateProfileFn({ phone }).then((response) => {
              if (response?.data?.success) {
                setUser({ ...user, phone });
                setValue(type, phone);
              }
              handleClose();
            });
          }
        });
        break;

      default:
        break;
    }
  };

  return (
    <div className="min-w-[500px]">
      <div className="flex justify-between items-start py-4 px-6 flex-col">
        <div className="flex justify-between w-full">
          <div className="flex flex-col gap-1">
            <div className="text-xl font-semibold text-black">
              {headingLookup[type]}
            </div>
            <div className="text-sm  text-grey-700">
              {subHeadingLookup[type]}
            </div>
          </div>
          <button type="button" onClick={() => handleClose()}>
            <X className="text-grey-600 w-6 h-6" />
          </button>
        </div>
        <div className="flex w-full items-start gap-2">
          <div className="py-4 w-full"> {FormLookup[activeOption]}</div>
          <div className="w-6/12 xxl:mt-7 xl:mt-6 lg:mt-6 md:mt-5 sm:mt-6 xs:mt-6">
            <label className="h-4 flex"></label>
            <Button
              disabled={
                {
                  email:
                    sendingOTP ||
                    isConterRunning ||
                    !email,
                  phone:
                    sendingOTP ||
                    isConterRunning ||
                    !phone
                }[type]
              }
              onClick={() =>
                sendOTP({
                  type: 'send_otp',
                  [type]: type === 'email' ? email : phone,
                  country: 'NG',
                  template_type:
                    type === 'email' ? 'editEmailProfile' : undefined,
                })
              }
            >
              {sendingOTP && !otp ? (
                <Spinner />
              ) : (
                <div className="flex gap-1 items-center text-nowrap w-40 px-1 justify-around">
                  {isOTPSentSuccessfull ? <Refresh /> : null}
                  {isOTPSentSuccessfull ? 'Resend code' : 'Send code'}
                  {isOTPSentSuccessfull ? (
                    counter ? (
                      <>&nbsp;{counter}s</>
                    ) : null
                  ) : null}
                </div>
              )}
            </Button>
          </div>
        </div>
        <div className="py-1 flex flex-col gap-4  w-full">
          <div className="w-full">
            <div className="flex items-start gap-3  w-full">
              <div className="w-full">
                <Input
                  name="otp"
                  value={otp}
                  onChange={(e) => {
                    if (numericRegx.test(e.target.value)) {
                      setOtp(e.target.value);
                    } else {
                      return;
                    }
                  }}
                  required={true}
                  autoComplete="off"
                  label="Verification code"
                  maxLength="6"
                  // disabled={!isOTPSentSuccessfull}
                />
              </div>
            </div>
          </div>
        </div>

        <div className=" mt-4 w-full">
          <Button
            type="button"
            border="border-green"
            disabled={
              (!otp && otp?.length < 6) || updateProfileLoad || sendingOTP
            }
            onClick={() => onSubmit()}
          >
            {(sendingOTP || updateProfileLoad) && (!email || !phone) ? (
              <Spinner />
            ) : (
              'Confirm'
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CodeVerification;
