import { PaperPlaneTilt, Paperclip, Smiley, X } from 'assets/images';
import { Input } from 'components';
import PreviewModal from 'components/FilePreview/PreviewModal';
import EmojiPicker from 'emoji-picker-react';
import React, { useEffect, useRef, useState } from 'react';
import { wantToSendMessage } from 'shared/resources';
import Mentions from '../../Mentions/indx';

const Footer = ({ chatId = '' }) => {
  console.log('🚀 ~ Footer ~ chatId:', chatId);
  const [message, setMessage] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const filePreviews = selectedFiles && selectedFiles.length > 0;
  const [emojiSelection, setEmojiSelection] = useState(false);
  const [tryingToMention, setTryingToMention] = useState(false);
  const textAreaRef = useRef(null);
  const filePicker = useRef(null);

  useEffect(() => {
    if (textAreaRef?.current) {
      const element = textAreaRef.current;
      element.style.boxSizing = 'border-box';
      const offset = element.offsetHeight - element.clientHeight;
      element.addEventListener('input', function (event) {
        event.target.style.height = 0;
        event.target.style.height = event.target.scrollHeight + offset + 'px';
      });
      return () => {
        if (element) {
          element.removeEventListener('input', () => null, false);
        }
      };
    }
  }, [textAreaRef?.current]);

  useEffect(() => {
    if (!tryingToMention) {
      if (message) {
        textAreaRef.current.focus();
      }
    }
  }, [tryingToMention]);

  useEffect(() => {
    if (message) {
      const tokens = message?.trim()?.split(' ');
      const highlightedTokens = tokens?.map((token) => {
        if (token.charAt(0) === '@') {
          return `<span class="bg-green-100 p-1 rounded-md">${token}</span>`;
        } else {
          return token;
        }
      });
      const newValueTokens = highlightedTokens.join(' ');
      if (textAreaRef?.current) {
        textAreaRef.current.innerHTML = newValueTokens;
      }
      getCursorPositionAtEnd(textAreaRef.current);
    }
  }, [message]);

  function getCursorPositionAtEnd(contentEditableElement) {
    let range, selection;
    if (document.createRange) {
      range = document.createRange();
      range.selectNodeContents(contentEditableElement);
      range.collapse(false);
      selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
    } else if (document.selection) {
      range = document.body.createTextRange();
      range.moveToElementText(contentEditableElement);
      range.collapse(false);
      range.select();
    }
  }

  function handleFileSelect(e) {
    const files = e?.target?.files ?? {};
    if (selectedFiles && selectedFiles.length > 0) {
      setSelectedFiles((prev) => [...prev, ...files]);
    } else {
      setSelectedFiles([...files]);
    }
    e.target.value = null;
  }

  function cleanUpTextArea() {
    if (textAreaRef?.current) {
      const element = textAreaRef.current;
      element.style.boxSizing = 'border-box';
      const offset = element.offsetHeight - element.clientHeight;
      element.innerText = '';
      element.style.height = 0;
      element.style.height = element.scrollHeight + offset + 'px';
    }
    setMessage('');
  }

  function sendMessage(message) {
    if (!message?.trim()) return;
    cleanUpTextArea();
  }

  function handleEmoji(e) {
    setEmojiSelection(false);
    const element = textAreaRef.current;
    if (element) {
      const messageWithEmoji = element.innerText + ' ' + e?.emoji;
      element.innerText = messageWithEmoji;
      setMessage(messageWithEmoji);
      element.focus();
    }
  }

  function handleSendMessage(e) {
    const messageToSend = message?.trim();
    if (wantToSendMessage(e) && messageToSend && !tryingToMention) {
      e.preventDefault();
      e.stopPropagation();
      sendMessage(messageToSend);
    }
  }

  function handleChangeMessage(e) {
    e.preventDefault();
    const message = `${e?.target?.textContent ?? ''}`.trim();
    const messageTokens = message?.trim()?.split(' ');
    const isTryingToMention =
      messageTokens?.pop()?.charAt(0) === '@' ? true : false;

    if (isTryingToMention) {
      setTryingToMention(true);
    } else {
      setTryingToMention(false);
    }
    setMessage(message);
  }
  return (
    <>
      {filePreviews ? (
        <div className="flex gap-2 justify-between">
          <PreviewModal
            files={selectedFiles}
            handleClose={() => setSelectedFiles([])}
            setSelectedFiles={setSelectedFiles}
          />
          <X
            className="text-danger cursor-pointer"
            onClick={() => setSelectedFiles([])}
          />
        </div>
      ) : null}
      <div className="flex items-center gap-4 p-6 bg-grey-100 border-grey-300 border-t">
        <span
          onClick={() => filePicker?.current?.click()}
          className="cursor-pointer"
        >
          <Paperclip className="text-grey-700" />
          <Input
            ref={filePicker}
            multiple
            extraClasses="hidden"
            type="file"
            id="filePicker"
            onChange={handleFileSelect}
          />
        </span>
        <span>
          <Smiley
            className="text-grey-700 cursor-pointer relative"
            onClick={() => setEmojiSelection(true)}
          />
          {emojiSelection ? (
            <span className="absolute bottom-20">
              <div className="flex gap-2">
                <EmojiPicker
                  lazyLoadEmojis={true}
                  onEmojiClick={handleEmoji}
                  emojiStyle="native"
                  defaultSkinTone="neutral"
                  theme="dark"
                  skinTonesDisabled={true}
                  width={350}
                  height={450}
                  previewConfig={{ showPreview: false }}
                  categories={['smileys_people', 'food_drink', 'activities']}
                  className="custom-emoji-picker"
                />
                <X
                  className="text-danger cursor-pointer"
                  onClick={() => setEmojiSelection(false)}
                />
              </div>
            </span>
          ) : null}
        </span>
        <div className="relative w-full flex items-center h-10">
          <span>
            <div
              role="input"
              ref={textAreaRef}
              name="message"
              onKeyDown={(e) => handleSendMessage(e)}
              onInput={(e) => handleChangeMessage(e)}
              contentEditable={true}
              className="w-full border min-h-[40px] p-2 overflow-auto bg-white rounded-md absolute bottom-0 focus:outline-none focus:ring font-medium text-base text-grey-900 resize-none border-grey-400 focus:border-green focus:ring-green-200 "
            />
          </span>
          {tryingToMention ? (
            <Mentions
              setMessage={setMessage}
              setTryingToMention={setTryingToMention}
              message={message}
              textAreaRef={textAreaRef}
            />
          ) : null}
          <PaperPlaneTilt
            onClick={() => sendMessage(message)}
            className={`${
              message ? 'text-green-400' : 'text-grey-400'
            } absolute right-3 cursor-pointer ml-2`}
          />
        </div>
      </div>
    </>
  );
};

export default Footer;
