import React from 'react';
import FilePreview from '..';
import { X } from 'assets/images';

const PreviewModal = ({ files = [], setSelectedFiles }) => {
  function handleRemovefile(fileToRemove) {
    if (!files?.length) return;

    const filteredFiles = files?.filter((_, index) => index !== fileToRemove);
    setSelectedFiles(filteredFiles);
  }
  return (
    <div className="flex justify-between items-start py-4 px-6">
      {files && files.length > 0 ? (
        <div className="flex gap-4">
          {files.map((file, index) => (
            <span key={file?.name} className="relative">
              <X
                className="text-danger cursor-pointer absolute -right-2 -top-2 bg-grey-100 border border-grey-400 rounded-full w-5 h-5 p-1"
                onClick={() => handleRemovefile(index)}
              />
              <FilePreview file={file} />
            </span>
          ))}
        </div>
      ) : (
        "You don't have any files to send."
      )}
    </div>
  );
};

export default PreviewModal;
