import React, { useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Logo, Vectorup, Vectordown, Apple } from 'assets/images/signup';
import {
  alphaNumericRegx,
  getRequiredText,
  passwordRegx,
} from 'shared/resources';
import { Link } from 'react-router-dom';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import { useSignup } from 'hooks';
import useGoogleAuth from 'hooks/useGoogleAuth';
import useFacebookAuth from 'hooks/useFacebookAuth';
import { Arrow, Chevron, LogoBlack } from 'assets/images';
import AutoTypeComponent from 'components/AutoTypeComponent';

const formHeading = {
  0: 'Sign up',
  1: 'Verify phone number',
  2: `What's the name of your Company?`,
};

const formSubHeading = {
  0: 'Fill the form below to get started',
  1: 'Enter your phone number',
  2: `Enter your company name, but you can skip if you’re registering as an individual`,
};

const initialValues = {
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  country: 'NG',
  phone: '+234',
  otp: '',
  company_name: '',
  reg_type: 'WEB',
  auth_id: '',
};

const validationSchema = yup.object().shape({
  first_name: yup
    .string()
    .required(getRequiredText('First name'))
    .matches(alphaNumericRegx, 'Special characters are not allowed.'),
  email: yup.string().required(getRequiredText('Email')),
  last_name: yup
    .string()
    .required(getRequiredText('Last name'))
    .matches(alphaNumericRegx, 'Special characters are not allowed.'),
  password: yup
    .string()
    .required(getRequiredText('Password'))
    .matches(
      passwordRegx,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character i.e Demo@123.',
    ),
  confirm_password: yup
    .string()
    .required(getRequiredText('Confirm password'))
    .when('password', {
      is: (password) => password && password.length > 0,
      then: yup.string().oneOf([yup.ref('password')], 'Passwords do not match'),
    }),
  phone: yup.string().required(getRequiredText('Phone')),
  otp: yup.string().required(getRequiredText('Verification code')),
});

export default function SignUp() {
  const [step, setStep] = useState(0);

  const { mutate: handleSignUp, isPending: isSignupPending } = useSignup();
  const isStepOne = step === 0;
  const isLastStep = step === 2;
  const {
    control,
    handleSubmit,
    trigger,
    formState: { errors },
    watch,
    setError,
    setValue,
    clearErrors,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onChange',
  });

  const handleFacebookResponse = (res) => {
    if (res) {
      if (res?.name) {
        const name = res?.name?.split(' ');
        setValue('first_name', name?.[0] ?? '');
        setValue('last_name', name?.[1] ?? '');
      }
      if (res?.id) {
        setValue('auth_id', res.id);
        setValue('reg_type', 'FACEBOOK');
      }
    }
  };

  const handleGoogleResponse = (res) => {
    if (res) {
      if (res?.name) {
        const name = res?.name?.split(' ');
        setValue('first_name', name?.[0] ?? '');
        setValue('last_name', name?.[1] ?? '');
      }
      if (res?.email) {
        setValue('email', res.email);
      }
      if (res?.sub) {
        setValue('auth_id', res.sub);
        setValue('reg_type', 'GOOGLE');
      }
    }
  };

  const GoogleAuth = useGoogleAuth({ actionFn: handleGoogleResponse });
  const FacebookAuth = useFacebookAuth({ actionFn: handleFacebookResponse });

  const ifErrors = errors && Object.keys(errors).length > 0;

  async function handleNextStep(step) {
    if (step > 2) return;

    if (step === 0) {
      const isValid = await trigger(['first_name', 'last_name', 'password']);

      if (isValid) {
        setStep(step + 1);
      }
    }
    if (step === 1) {
      const isValid = await trigger(['phone', 'otp']);

      if (isValid) {
        setStep(step + 1);
      }
    }
  }
  function handlePreviousStep(step) {
    if (step === 0) return;
    clearErrors();
    setStep(step - 1);
  }

  async function onSubmit(values) {
    handleSignUp(values);
  }

  const signUpSteps = {
    0: (
      <StepOne
        handleNextStep={handleNextStep}
        control={control}
        errors={errors}
        step={step}
        setError={setError}
        clearErrors={clearErrors}
        watch={watch}
        ifErrors={ifErrors}
      />
    ),
    1: (
      <StepTwo
        control={control}
        errors={errors}
        handleNextStep={handleNextStep}
        step={step}
        setError={setError}
        clearErrors={clearErrors}
        watch={watch}
        ifErrors={ifErrors}
      />
    ),
    2: (
      <StepThree
        control={control}
        errors={errors}
        handleNextStep={handleNextStep}
        step={step}
        setError={setError}
        clearErrors={clearErrors}
        watch={watch}
        ifErrors={ifErrors}
        isSignupPending={isSignupPending}
      />
    ),
  };

  return (
    <div className="flex h-full ">
      <div className=" h-full px-12 py-10 xxl:min-w-[570px]  lg:min-w-[380px] md:min-w-[365px] xxl:flex  lg:flex md:flex sm:hidden xs:hidden relative flex-col justify-between bg-black-600">
        <div className="flex gap-3">
          <Logo className=" xxl:w-28  lg:w-[85px] md:w-20" />
        </div>
        <AutoTypeComponent extraClass={'text-green'} />
        <div className="absolute right-0 top-0 xxl:w-auto lg:w-5/6 md:w-4/6">
          <Vectorup className="w-full" />
        </div>
        <div className="absolute left-0 bottom-0 xxl:w-auto lg:w-5/6 md:w-4/6">
          <Vectordown className="w-full" />
        </div>
      </div>


      <div className="bg-green-50   w-screen overflow-auto relative xxl:pt-6  lg:pt-2 md:pt-5 sm:pt-8 xs:pt-5">
        <div className="lg:hidden gap-3 sm:flex xs:flex items-center justify-center mb-5">
          <LogoBlack className="xs:w-20" />
        </div>
        {!isStepOne && !isLastStep ? (
          <div
            className="text-black text-base font-medium	 cursor-pointer flex items-center gap-2  absolute xxl:left-7  lg:left-7 sm:left-7 xs:left-3 xxl:top-9  lg:top-9 sm:top-9 xs:top-5  "
            onClick={() => handlePreviousStep(step)}
          >
            <Chevron className="rotate-90 xs:hidden md:flex" />
            <Arrow className="rotate-180 xs:flex md:hidden" />
            <span className='xs:hidden md:flex'>Back</span>
          </div>
        ) : null}
        <div className="m-auto xxl:w-6/12  lg:w-6/12 md:w-6/12 sm:w-8/12 xs:w-11/12">
          {!isStepOne && !isLastStep ? (
            <div className="flex flex-col mt-8 ">
              <div className="xxl:text-3xl  lg:text-2xl md:text-[22px] xl:leading-[36px] xs:text-3xl text-black font-medium	 lg:w-6/">
                {formHeading[step]}
              </div>
              <div className="xxl:text-base  lg:text-[15px] md:text-sm font-normal text-grey-600 xxl:mt-[.1em]">
                {formSubHeading[step]}
              </div>
            </div>
          ) : (
            <div className="flex flex-col  ">
              <div className="xxl:text-3xl  lg:text-[24px] md:text-[22px] xs:text-3xl xs:font-medium text-black font-medium	 lg:w-6/">
                {formHeading[step]}
              </div>
              <div className="xxl:text-base  lg:text-[15px] md:text-[14px] font-normal	 text-grey-600 xxl:mt-[.1em]">
                {formSubHeading[step]}
              </div>
            </div>
          )}
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            {signUpSteps[step]}
          </form>
          {isStepOne ? (
            <div className="flex flex-col">
              <div className="flex items-center xxl:mt-8  lg:mt-4 md:mt-4 sm:mt-4 xs:mt-4">
                <hr className="h-px w-full  text-grey-500 "></hr>
                <div className="whitespace-nowrap text-center px-2 text-grey-500 ">
                  or continue with
                </div>
                <hr className="h-px w-full  text-grey-500 "></hr>
              </div>
              <div className="flex xs:flex-col md:flex-row items-center   xxl:gap-5 xl:gap-5 lg:gap-5 md:gap-5 sm:gap-2 xs:gap-2   xxl:mt-8  lg:mt-4 md:mt-4 sm:mt-5 xs:mt-5">
                {FacebookAuth}
                {GoogleAuth}
                <div className="flex items-center justify-center gap-2 border border-solid  border-grey-400 rounded-md xxl:h-[56px] xl:h-[45px] lg:h-[45px] md:h-[41px] sm:h-[40px] xs:h-[40px]   text-black  w-full cursor-pointer">
                  <Apple className="w-5" /> Apple
                </div>
              </div>
              <div className="text-sm text-grey-600 text-center leading-6 xxl:mt-5  lg:mt-3 md:mt-3 sm:mt-4 xs:mt-4">
                By creating a new account, you automatically agree to our
                <br></br>
                <a
                  className="text-green-400 cursor-pointer"
                  href="https://www.fonu.com/terms.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms & Conditions
                </a>
                &nbsp;and&nbsp;
                <a
                  className="text-green-400 cursor-pointer"
                  href="https://www.fonu.com/privacy.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy.
                </a>
              </div>
              <div className="text-grey-800  text-center text-base xxl:mt-5  lg:mt-2 md:mt-2 sm:mt-4 xs:mt-2 mb-1">
                Already have an account?&nbsp;
                <Link to="/" className="text-green-400 font-medium">
                  Sign in
                </Link>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
