import React from 'react';
import { Input } from 'components';
import { Controller } from 'react-hook-form';
import { customBusinessHours } from 'pages/PhoneNumbers/constants';
import SwitchInput from 'components/SwitchInput';

const CustomHourForm = ({ control, errors }) => {
  return (
    <div className=" flex flex-col  items-center gap-3">
      {customBusinessHours &&
        customBusinessHours.map(({ label, id }) => {
          return (
            <div key={id} className="w-full flex items-center gap-6">
              <div className="flex items-center gap-3 min-w-[132px]">
                <Controller
                  name={`business_hour.custom_hours.${id}.open`}
                  control={control}
                  render={({ field }) => <SwitchInput {...field} />}
                />
                <label className="text-grey-700 text-sm font-medium">
                  {label}
                </label>
              </div>
              <div className="flex items-center gap-2 w-full">
                <Controller
                  name={`business_hour.custom_hours.${id}.start`}
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      onWhitebg={true}
                      type="time"
                      error={
                        errors?.business_hour?.custom_hours?.[id]?.start
                          ?.message
                      }
                    />
                  )}
                />
              </div>
              <div className="flex items-center gap-2 w-full">
                <label className="text-grey-700 text-sm font-medium">To</label>
                <Controller
                  name={`business_hour.custom_hours.${id}.end`}
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      onWhitebg={true}
                      type="time"
                      error={
                        errors?.business_hour?.custom_hours?.[id]?.end?.message
                      }
                    />
                  )}
                />
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default CustomHourForm;
