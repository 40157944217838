import { Plus, PreviousDoubleLeft, PreviousDoubleRight, PreviousLeft, PreviousRight, Search } from 'assets/images';
import { Input } from 'components';
import React from 'react';

const Blocklist = () => {
  return <div className='overflow-auto w-full px-6 pt-6 pb-6'>
    <div className='flex flex-col w-full'>
      <div className="flex items-center justify-between mb-5">
        <div className="text-sm text-grey-700">Manage your Fonu blocklist</div>
      </div>
      <div className='flex justify-between mb-4'>

        <div className="relative flex items-center w-full">
          <div className="absolute left-2.5  font-medium text-grey-600 z-10">
            <Search />
          </div>
          <Input
            autoComplete="off"
            placeholder="Search by name or number"
            maxLength="16"
            height="40px"
            extraClasses="placeholder:text-grey-400  pl-10"
          />
        </div>
        <div className='w-full'></div>
      </div>
      <div className="w-full rounded-md p-7 border border-grey-400 bg-white min-h-[calc(100vh_-_226px)] flex justify-center">
        <div className='max-w-[700px] w-full flex flex-col gap-2'>
          <div className='w-full flex flex-col gap-2 h-full'>
            <div className='flex w-full justify-between items-center border-b border-grey-400 h-10 pb-2'>
              <div className='text-sm text-grey-600'>+234 70 9332 7543</div>
              <div className='max-w-[80px] min-w-[80px] min-h-[29px] rounded-full bg-danger-100 text-danger text-xs flex items-center justify-center font-medium cursor-pointer'>
                Unblock
              </div>
            </div>
            <div className='flex w-full justify-between items-center border-b border-grey-400 h-10 pb-2'>
              <div className='text-sm text-grey-600'>+234 70 9332 7543</div>
              <div className='max-w-[80px] min-w-[80px] min-h-[29px] rounded-full bg-danger-100 text-danger text-xs flex items-center justify-center font-medium cursor-pointer'>
                Unblock
              </div>
            </div>
            <div className='flex w-full justify-between items-center border-b border-grey-400 h-10 pb-2'>
              <div className='text-sm text-grey-600'>+234 70 9332 7543</div>
              <div className='max-w-[80px] min-w-[80px] min-h-[29px] rounded-full bg-danger-100 text-danger text-xs flex items-center justify-center font-medium cursor-pointer'>
                Unblock
              </div>
            </div>
            <div className='flex w-full justify-between items-center border-b border-grey-400 h-10 pb-2'>
              <div className='text-sm text-grey-600'>+234 70 9332 7543</div>
              <div className='max-w-[80px] min-w-[80px] min-h-[29px] rounded-full bg-danger-100 text-danger text-xs flex items-center justify-center font-medium cursor-pointer'>
                Unblock
              </div>
            </div>
            <div className='flex w-full justify-between items-center border-b border-grey-400 h-10 pb-2'>
              <div className='text-sm text-grey-600'>+234 70 9332 7543</div>
              <div className='max-w-[80px] min-w-[80px] min-h-[29px] rounded-full bg-danger-100 text-danger text-xs flex items-center justify-center font-medium cursor-pointer'>
                Unblock
              </div>
            </div>
            <div className='flex w-full justify-between items-center border-b border-grey-400 h-10 pb-2'>
              <div className='text-sm text-grey-600'>+234 70 9332 7543</div>
              <div className='max-w-[80px] min-w-[80px] min-h-[29px] rounded-full bg-danger-100 text-danger text-xs flex items-center justify-center font-medium cursor-pointer'>
                Unblock
              </div>
            </div>
            <div className='flex w-full justify-between items-center mt-4'>
              <div className='text-sm  text-grey-600'></div>
              <div className='text-md text-green flex items-center gap-2 justify-center font-medium cursor-pointer'>
                <Plus className='w-5 h-5' />
                <div className='text-md font-semibold'>

                  Block new number
                </div>
              </div>
            </div>
          </div>
          <div className='flex justify-between items-center'>
            <div className='text-md'>Page 1 of 1</div>
            <div className='text-md flex gap-3 items-center'>
              <div>
                <button
                  className="disabled:cursor-not-allowed bg-transparent w-[25px] h-[25px] flex justify-center items-center rounded-md text-white cursor-pointer"
                >
                  <PreviousLeft width={18} height={18} />
                </button>
              </div>
              <div>
                <button
                  className="disabled:cursor-not-allowed bg-transparent w-[25px] h-[25px] flex justify-center items-center rounded-md text-white cursor-pointer"
                >
                  <PreviousDoubleLeft width={18} height={18} />
                </button>
              </div>
              <div className='w-5 flex items-center justify-center cursor-pointer'>1</div>
              <div>
                <button
                  className="disabled:cursor-not-allowed bg-transparent w-[25px] h-[25px] flex justify-center items-center rounded-md text-white cursor-pointer"
                >
                  <PreviousRight width={18} height={18} />
                </button>
              </div>
              <div>
                <button
                  className="disabled:cursor-not-allowed bg-transparent w-[25px] h-[25px] flex justify-center items-center rounded-md text-white cursor-pointer"
                >
                  <PreviousDoubleRight width={18} height={18} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>;
};

export default Blocklist;
