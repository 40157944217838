import { Dialer } from 'components';
import MinimizedDialer from 'components/Dialer/MinimizedDialer';
import { SipSocketContext } from 'hooks/useSip/SipSocketContext';
import React, { useContext, useEffect, useState } from 'react';

export default function PhoneSystem({ isDialpadOpen, openDialpad }) {
  const { socket = {} } = useContext(SipSocketContext);
  const [callActiveKey, setCallActiveKey] = useState(null);
  const [action, setAction] = useState('');

  const { _uiSessions = {} } = socket;
  const [callSessions, setCallSessions] = useState([]);

  useEffect(() => {
    if (_uiSessions && Object.keys(_uiSessions).length > 0) {
      const callarray = [];
      Object.keys(_uiSessions)?.forEach((key) => {
        const sessionData = _uiSessions?.[key];
        const { _status } = sessionData;
        if (_status === 'ringing') {
          setCallActiveKey(key);
        }
        callarray.push({ callID: key, ...sessionData });
      });
      setCallSessions(callarray);
    } else {
      setCallSessions([]);
    }
  }, [JSON.stringify(_uiSessions)]);

  useEffect(() => {
    if (callSessions.length === 1) {
      setCallActiveKey(callSessions?.[0]?.callID);
    }
    setAction(null);
  }, [callSessions.length]);

  const isCallExist = callSessions.length > 0;

  return (
    <>
      {isDialpadOpen ? (
        <Dialer
          openDialpad={openDialpad}
          callActiveKey={callActiveKey}
          setCallActiveKey={setCallActiveKey}
          callSessions={callSessions}
          setAction={setAction}
          action={action}
        />
      ) : (
        <>
          {isCallExist && (
            <MinimizedDialer
              callActiveKey={callActiveKey}
              callSessions={callSessions}
              openDialpad={(val) => {
                openDialpad(val);
              }}
              setAction={setAction}
            />
          )}
        </>
      )}
    </>
  );
}
