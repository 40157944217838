import { useQuery } from '@tanstack/react-query';
import { bundleListing } from 'api';

export default function useBundleListing() {
  const Request = useQuery({
    queryFn: bundleListing,
    queryKey: ['bundleListing'],
    refetchOnMount: false,
    select: (data) => {
      const newData = {
        sms_bundle: [],
        call_bundle: [],
      };

      data?.data?.data?.forEach((bundle) => {
        if (bundle?.type === 'CALL') {
          newData.call_bundle.push(bundle);
        } else {
          newData.sms_bundle.push(bundle);
        }
      });

      return newData;
    },
  });

  return { ...Request };
}
