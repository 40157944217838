import { useMutation } from '@tanstack/react-query';
import { getAllBatches } from 'api';

export default function useAllBatches() {
  const { mutate, data, isError } = useMutation({
    mutationFn: getAllBatches,
    mutationKey: ['getAllBatches'],
  });
  return {
    allBatchesMutate: mutate,
    allBatchesData: data,
    isBatchesError: isError,
  };
}
