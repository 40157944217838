import { teamListing } from 'api';
import { X } from 'assets/images';
import { Button, Spinner } from 'components';
import ListManager from 'components/ListManager';
import { useAssignTeam } from 'hooks';
import { LoadingItem } from 'pages/Dashboard';
import React, { useEffect, useMemo, useState } from 'react';
/* eslint-disable no-unused-vars */
const TeamListItem = ({ item, ...rest }) => {
  return (
    <label
      htmlFor={item?.uuid}
      className="flex items-center justify-between py-3 border-grey-300 border-b cursor-pointer "
    >
      <div className="flex gap-3">
        <div className="flex flex-col gap-1">
          <div className="flex items-center text-sm font-medium	 text-grey-900">
            {item?.name}&nbsp;&nbsp;
          </div>
        </div>
      </div>
      <div className="flex text-xs text-green gap-1">
        <input
          className="checked:bg-green checked:hover:bg-green checked:active:bg-green checked:focus:bg-green focus:outline-none focus:ring-1 focus:ring-green"
          id={item?.uuid}
          type="checkbox"
          value={item?.uuid}
          onChange={(e) => {
            if (rest?.itemValue.includes(e.target.value)) {
              rest.itemValue.splice(rest.itemValue.indexOf(e.target.value), 1);
              rest?.onChangeItemHandler([...rest.itemValue]);
            } else {
              rest?.onChangeItemHandler([...rest.itemValue, e.target.value]);
            }
          }}
          checked={rest?.itemValue?.includes(item?.uuid)}
        />
      </div>
    </label>
  );
};

const AssignTeamModal = ({ showModal, handleClose = () => null }) => {
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [hasData, setHasdata] = useState(false);
  const itemElement = useMemo(() => showModal?.elementData, [showModal]);

  const onSuccessFn = () => {
    handleClose();
  };
  const { mutate: assignTeamMutate, isPending: assignTeamLoad } = useAssignTeam(
    { onSuccessFn },
  );

  useEffect(() => {
    if (itemElement && itemElement?.teams && itemElement?.teams?.length > 0) {
      const oldSelectedTeams = itemElement?.teams?.map((item) => item?.uuid);
      setSelectedTeams(oldSelectedTeams);
    }
  }, []);

  const handleAssignTeam = () => {
    const payload = {
      member_uuid: itemElement?.uuid,
      team_uuids: selectedTeams,
    };
    assignTeamMutate(payload);
  };

  return (
    <div className="min-w-[460px]">
      <div className="flex justify-between items-start py-4 px-6">
        <div className="flex flex-col gap-1">
          <div className="text-xl font-semibold text-black">Assign team</div>
          <div className="text-sm  text-grey-700">
            Select the team assigned to {itemElement?.email}
          </div>
        </div>
        <button type="button" onClick={() => handleClose()}>
          <X className="text-grey-600 w-6 h-6" />
        </button>
      </div>
      <div className="  px-6 py-3 border-grey-300 border-t overflow-auto xxl:max-h-[330px] xl:max-h-[330px] lg:max-h-[330px] md:max-h-[234px]">
        <ListManager
          setHasdata={setHasdata}
          actionFn={teamListing}
          queryKey="teamListing"
          listItem={TeamListItem}
          loadingComponent={LoadingItem}
          onChangeItemHandler={setSelectedTeams}
          itemValue={selectedTeams}
        />
      </div>
      <div className=" mt-10 px-6 pb-6">
        <Button
          extraClasses="justify-start pl-4 cursor-pointer"
          type="button"
          onClick={() => handleAssignTeam()}
          disabled={assignTeamLoad || !hasData}
        >
          {assignTeamLoad ? <Spinner /> : 'Submit'}
        </Button>
      </div>
    </div>
  );
};

export default AssignTeamModal;
