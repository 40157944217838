import React, { useContext, useState } from 'react';
import { SipSocketContext } from 'hooks/useSip/SipSocketContext';
import Digits from '../Digits';

const DTMFKeypadComponent = ({ activeCallData }) => {
  const { socket } = useContext(SipSocketContext);
  const { _sendDtmf = () => null } = socket;
  const [dtmf, setDtmf] = useState('');
  const handleKeyPress = (val) => {
    setDtmf((prev) => prev + val);
    _sendDtmf(activeCallData?.callID, val);
  };
  return (
    <>
      <div>
        <input
          autoComplete="off"
          type="text"
          id="phone"
          name="phone"
          value={dtmf}
          onChange={() => null}
          onKeyDown={() => null}
          className="w-full border-none bg-grey-900 focus:shadow-none focus:ring-0 text-4xl text-white  text-center  xxl:mt-8 xl:mt-3"
        />
      </div>
      <Digits onPresshandler={handleKeyPress} />
    </>
  );
};

export default DTMFKeypadComponent;
