import {
  Chevron,
  Hash,
  PhoneCall,
  PhoneIncoming,
  PhoneOutgoing,
} from 'assets/images';
import Dropdown from 'components/Dropdown';
import Skeletons from 'components/Skeletons';
import { useAuth } from 'hooks/useAuth';
import React from 'react';

const Loader = () => {
  return (
    <div className="w-9 h-9 rounded-full">
      <Skeletons type="circle" />
    </div>
  );
};

export default function SubHeader({
  isLoading,
  data,
  statsType,
  setStatsType,
}) {
  const { user } = useAuth();
  const totalCalls = data?.data?.data?.total_calls ?? '0';
  const incomingCalls = data?.data?.data?.total_incoming_calls ?? '0';
  const outgoingCalls = data?.data?.data?.total_outgoing_calls ?? '0';
  const virtualNumbers = data?.data?.data?.virtual_number ?? '0';

  return (
    <div className="sm:bg-black-600 xs:bg-white xxl:px-24 xl:px-24 lg:px-[88px] md:px-12 sm:p-3 xs:p-3  xxl:py-8 xl:py-4 lg:py-4 md:py-4  flex flex-col xxl:gap-8 xl:gap-5 lg:gap-5 md:gap-5  border-grey-800 border-t">
      <div className="flex items-center justify-between">
        <span className="flex items-center text--xl xs:text-black sm:text-white ">
          Welcome,&nbsp;
          <div>{`${user?.first_name ?? ''} ${user?.last_name ?? ''}`}</div>
        </span>

        <div>
          <Dropdown
            dropList={{
              component: ({ item }) => (
                <span
                  key={item?.id}
                  className="w--full d--flex  justify-content--start py-2 px-3"
                  onClick={() => setStatsType(item?.value)}
                >
                  {item?.title}
                </span>
              ),
              data: [
                { title: 'Weekly', value: 'weekly', id: 1 },
                { title: 'Monthly', value: 'monthly', id: 2 },
              ],
            }}
            closeOnClickOutside={true}
          >
            <div className="flex text-sm	 items-center gap-5  bg-grey-900 cursor-pointer text-white px-4 py-2 rounded-full capitalize">
              {statsType}
              <Chevron />
            </div>
          </Dropdown>
        </div>
      </div>
      <div className="xxl:flex xl:flex lg:flex md:flex sm:grid xs:grid xxl:mt-0 xl:mt-0 lg:mt-0 md:mt-0 sm:mt-6 xs:mt-4  xxl:gap-0 xl:gap-0 lg:gap-0 md:gap-0 sm:gap-6 xs:gap-4 sm:grid-cols-4 xs:grid-cols-2 justify-between ">
        <div className="w-full xs:bg-black-600 sm:bg-transparent xs:p-2 sm:p-0 xs:rounded-md sm:rounded-none">
          <PhoneCall className="text-green" />
          <div className="text-sm	 text-white  xxl:mt-6 xl:mt-6 lg:mt-6 md:mt-6 sm:mt-2 xs:mt-2 mb-2">
            Total calls
          </div>
          <div className="	xxl:text-4xl xl:text-[32px] lg:text-3xl md:text-[26px]  text-white">
            {isLoading ? <Loader /> : totalCalls}
          </div>
        </div>
        <div className="w-full xs:bg-black-600 sm:bg-transparent xs:p-2 sm:p-0 xs:rounded-md sm:rounded-none">
          <PhoneIncoming className="text-green" />
          <div className="text-sm	 text-white  xxl:mt-6 xl:mt-6 lg:mt-6 md:mt-6 sm:mt-2 xs:mt-2 mb-2">
            Incoming calls
          </div>
          <div className="	xxl:text-4xl xl:text-[32px] lg:text-3xl md:text-[26px]  text-white">
            {isLoading ? <Loader /> : incomingCalls}
          </div>
        </div>
        <div className="w-full xs:bg-black-600 sm:bg-transparent xs:p-2 sm:p-0 xs:rounded-md sm:rounded-none">
          <PhoneOutgoing className="text-green" />
          <div className="text-sm	 text-white  xxl:mt-6 xl:mt-6 lg:mt-6 md:mt-6 sm:mt-2 xs:mt-2 mb-2">
            Outgoing Calls
          </div>
          <div className="	xxl:text-4xl xl:text-[32px] lg:text-3xl md:text-[26px]  text-white">
            {isLoading ? <Loader /> : outgoingCalls}
          </div>
        </div>
        <div className="w-full xs:bg-black-600 sm:bg-transparent xs:p-2 sm:p-0 xs:rounded-md sm:rounded-none">
          <Hash className="text-green" />
          <div className="text-sm	 text-white  xxl:mt-6 xl:mt-6 lg:mt-6 md:mt-6 sm:mt-2 xs:mt-2 mb-2">
            Virtual number
          </div>
          <div className="	xxl:text-4xl xl:text-[32px] lg:text-3xl md:text-[26px]  text-white">
            {isLoading ? <Loader /> : virtualNumbers}
          </div>
        </div>
      </div>
    </div>
  );
}
