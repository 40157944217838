import { Input } from 'components';
import React from 'react';
import { Controller } from 'react-hook-form';

const EveryDayHoursForm = ({ control, errors }) => {
  return (
    <div className="w-full flex items-center justify-between gap-10">
      <div className="flex items-center gap-2 w-full">
        <label className="text-grey-700 text-sm font-medium">From</label>
        <Controller
          name={`business_hour.every_day.start`}
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              onWhitebg={true}
              height="xxl:h-[48px] xl:h-[45px] lg:h-[45px] md:h-[41px]"
              type="time"
              error={errors?.business_hour?.every_day?.start?.message}
            />
          )}
        />
      </div>
      <div className="flex items-center gap-2 w-full">
        <label className="text-grey-700 text-sm font-medium">To</label>
        <Controller
          name={`business_hour.every_day.end`}
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              onWhitebg={true}
              height="xxl:h-[48px] xl:h-[45px] lg:h-[45px] md:h-[41px]"
              type="time"
              error={errors?.business_hour?.every_day?.end?.message}
            />
          )}
        />
      </div>
    </div>
  );
};

export default EveryDayHoursForm;
