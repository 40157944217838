export const initialModalState = {
  modalType: '',
  isShow: false,
  elementData: {},
  type: '',
};

export const dropdownActions = {
  CREATE_GREETING: 'create_greeting',
  EDIT_GREETING: 'edit_greeting',
  DELETE_GREETING: 'delete_greeting',
  PLAY_GREETING: 'play_greeting',
  BULK_DELETE_CALLS: 'bulk_delete_calls',
};
