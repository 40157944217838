import {
  AddUser,
  EndCallDark,
  HoldDark,
  KeypadDark,
  MuteDark,
  UnholdLight,
  UnmuteLight,
} from 'assets/images';
import Button from 'components/Button';
import { SipSocketContext } from 'hooks/useSip/SipSocketContext';
import React, { useContext, useEffect, useState } from 'react';
import TransferCallComponent from '../TransferCallComponent';
import NameByNumber from 'components/NameByNumber';
import Avatar from 'components/Avatar';
import Timer from 'components/Timer';
import { IncomingRing, formatPhoneNumber } from 'shared/resources';
import { useUserDetailsByParams } from 'hooks';

export default function MinimizedDialer({
  callActiveKey,
  callSessions,
  openDialpad,
  setAction = () => null,
}) {
  const [numberData, setNumberData] = useState(null);
  const [showTransfer, setShowTransfer] = useState(false);
  const { socket = {} } = useContext(SipSocketContext);

  const activeCallData =
    callSessions.filter((call) => call?.callID === callActiveKey)?.[0] || {};

  const {
    _answerCall = () => null,
    _terminate = () => null,
    _muteCall = () => null,
    _toggleHold = () => null,
  } = socket;
  const {
    _status = '',
    _number = '',
    callID = '',
    _joined_at = '',
    _direction = '',
  } = activeCallData;

  const { mutate: userDetailsMutate, data: userDetailsData } =
    useUserDetailsByParams();

  useEffect(() => {
    if (activeCallData && Object.keys(activeCallData).length > 0) {
      const payload = {};

      if (activeCallData?._number?.length > 5) {
        payload['did_number'] = activeCallData?._number;
      } else {
        payload['extension'] = activeCallData?._number;
      }
      userDetailsMutate(payload);
    }
  }, [activeCallData]);

  useEffect(() => {
    if (userDetailsData && userDetailsData?.data?.data) {
      setNumberData(userDetailsData?.data?.data);
    }
  }, [userDetailsData]);

  useEffect(() => {
    if (['ringing'].includes(_status)) {
      IncomingRing.play();
    } else {
      IncomingRing.pause();
    }
    return () => {
      IncomingRing.pause();
    };
  }, [_status]);

  const handleTerminate = (callID) => {
    _terminate(callID);
  };

  const handleCallerID = (activeCallData = {}, numberData = {}) => {
    let callerIDSettings;
    let userData;
    if (activeCallData?._number?.length > 5) {
      callerIDSettings = numberData?.settings?.caller_id;
      userData = numberData?.user;
    } else {
      callerIDSettings = numberData?.did?.settings?.caller_id;
      userData = numberData;
    }

    if (activeCallData?._direction === 'inbound') {
      return (
        <div className="text-white">
          <NameByNumber number={activeCallData?._number} />
        </div>
      );
    } else {
      switch (callerIDSettings?.name_outgoing_call) {
        case 'A':
          return (
            <div className="text-white ">
              <NameByNumber number={activeCallData?._number} />
              {userData?.company_name && (
                <div className="text-xs text-grey-800">
                  {userData.company_name ?? 'NA'}
                </div>
              )}
              {userData?.phone && (
                <div className="text-xs text-grey-800">
                  {formatPhoneNumber(userData?.phone) ?? 'NA'}
                </div>
              )}
            </div>
          );
        case 'PH':
          return (
            <div className="text-white">
              <NameByNumber number={activeCallData?._number} />
              <div className="text-xs text-grey-800">
                {formatPhoneNumber(userData?.phone) ?? 'NA'}
              </div>
            </div>
          );
        case 'CO':
          return (
            <div className="text-white">
              <NameByNumber number={activeCallData?._number} />
              <div className="text-xs text-grey-800">
                {userData?.company_name ?? 'NA'}
              </div>
            </div>
          );
        case 'CU':
          return (
            <div className="text-white">
              <NameByNumber number={activeCallData?._number} />
              <div className="text-xs text-grey-800">
                {callerIDSettings?.custom_name ?? 'NA'}
              </div>
            </div>
          );
        default:
          return (
            <div className="text-white">
              <NameByNumber number={activeCallData?._number} />
            </div>
          );
      }
    }
  };

  return (
    <>
      {['ringing', 'connecting'].includes(_status) ? (
        <div className="bg-black-600 p-4 absolute right-2 bottom-3 rounded-xl w-[345px]	z-50">
          <div className="flex gap-3">
            <div>
              <Avatar number={_number} />
            </div>
            <div className="flex flex-col justify-center gap-1">
              {_direction === 'inbound' ? (
                <div className="flex items-center text-sm font-medium	 text-white">
                  Incoming call from &nbsp;
                  <b>
                    {handleCallerID(activeCallData, numberData)}
                    {/* <NameByNumber number={_number} /> */}
                  </b>
                </div>
              ) : (
                <div className="text-sm font-medium	 text-white">
                  Calling <NameByNumber number={_number} />
                </div>
              )}
            </div>
          </div>
          <div>
            {showTransfer ? (
              <>
                <TransferCallComponent
                  setAction={() => setShowTransfer(false)}
                  activeCallData={activeCallData}
                  fromMinimized={true}
                />
                <div className="px-6 ">
                  <Button
                    border="border-none"
                    type="button"
                    onClick={() => setShowTransfer(false)}
                  >
                    Cancel
                  </Button>
                </div>
              </>
            ) : (
              <div className="flex items-center justify-between gap-2 mt-6">
                {_direction === 'inbound' && (
                  <Button
                    background="green-400"
                    border="border-none"
                    type="button"
                    onClick={() => {
                      openDialpad(true);
                      setAction('transfer');
                    }}
                  >
                    Transfer
                  </Button>
                )}
                <Button
                  background="danger"
                  border="border-none"
                  type="button"
                  onClick={() => handleTerminate(callID)}
                >
                  {_direction === 'inbound' ? 'Reject' : 'Cancel'}
                </Button>

                {_direction === 'inbound' && (
                  <Button
                    background="green"
                    border="border-none"
                    type="button"
                    onClick={() => _answerCall(callID)}
                  >
                    Answer
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="bg-black-600 p-4 absolute right-2 bottom-3 rounded-xl w-[345px]	z-50">
          <div className="flex gap-3 justify-between">
            <div className="flex gap-3">
              <Avatar number={_number} />
              <div className="flex flex-col  text-sm font-medium	justify-start text-white">
                {handleCallerID(activeCallData, numberData)}
                {/* <NameByNumber number={_number} /> */}
                <div className="text-xs flex  gap-1 text-grey-800 ">
                  <span className="capitalize">
                    {_status === 'connected'
                      ? 'Ongoing call..'
                      : `${_status}..`}{' '}
                  </span>
                  <span className="w-12">
                    <Timer seconds={_joined_at} />
                  </span>
                </div>
              </div>
            </div>
            <div
              className="text-white cursor-pointer "
              onClick={() => {
                openDialpad(true);
                setAction('add');
              }}
            >
              <AddUser className="text-white" />
            </div>
          </div>
          <div className="flex items-center justify-between  mt-6">
            <button type="button" onClick={() => _muteCall(callID)}>
              {_status === 'mute' ? <UnmuteLight /> : <MuteDark />}
            </button>
            <button type="button" onClick={() => _toggleHold(callID)}>
              {_status === 'hold' ? <UnholdLight /> : <HoldDark />}
            </button>
            <button type="button" onClick={() => openDialpad(true)}>
              <KeypadDark />
            </button>
            <button type="button" onClick={() => _terminate(callID)}>
              <EndCallDark />
            </button>
          </div>
        </div>
      )}
    </>
  );
}
