import { useQuery } from '@tanstack/react-query';
import { getRequestedPlan } from 'api';

export default function useGetRequestedPlan() {
  const didListRequest = useQuery({
    queryFn: getRequestedPlan,
    queryKey: ['getRequestedPlan'],
    refetchOnMount: false,
  });

  return { ...didListRequest };
}
