import { getRequiredText } from "shared/resources";
import * as yup from "yup";

export const initialModalState = {
  modalType: "",
  isShow: false,
  elementData: {},
  type: "",
};

export const dropdownActions = {
  PURCHASE_NUMBER: "purchase_number",
  ASSIGN_TO: "assign_to",
  SET_FORWARDING: "set_forwarding",
  UPDATE_FORWARDING: "update_forwarding",
  REMOVE_FORWARDING: "remove_forwarding",
  DELETE_NUMBER: "delete_number",
  SETTINGS: "settings",
  SET_DEFAULT_DID: "set_default_did",
  PURCHASE_CONFIRMATION: "PURCHASE_CONFIRMATION",
};

export const weekdayBusinessHours = [
  {
    id: "monday",
    label: "Monday",
  },
  {
    id: "tuesday",
    label: "Tuesday",
  },
  {
    id: "wednesday",
    label: "Wednesday",
  },
  {
    id: "thursday",
    label: "Thursday",
  },
  {
    id: "friday",
    label: "Friday",
  },
];
export const customBusinessHours = [
  {
    id: "monday",
    label: "Monday",
  },
  {
    id: "tuesday",
    label: "Tuesday",
  },
  {
    id: "wednesday",
    label: "Wednesday",
  },
  {
    id: "thursday",
    label: "Thursday",
  },
  {
    id: "friday",
    label: "Friday",
  },
  {
    id: "saturday",
    label: "Saturday",
  },
  {
    id: "sunday",
    label: "Sunday",
  },
];

// Form initial values
export const incomingCallInitialValues = {
  icon: "",
  name: "",
  auto_record_calls: false,
  auto_record_outgoing_calls: false,
};
export const callerSettingsInitialValues = {
  name_incoming_call: "",
  name_outgoing_call: "",
  custom_name: "",
};
export const privacySettingsInitialValues = {
  ringtone_virtual_number: "",
  enable_business_hour: false,
  business_hour: {
    after_hours: "",
    country: "NG",
    timezone: "West Africa Standard Time",
    days: "everyday",
    every_day: {
      start: "08:00",
      end: "20:00",
    },
    week_days: {
      monday: {
        start: "08:00",
        end: "20:00",
      },

      tuesday: {
        start: "08:00",
        end: "20:00",
      },

      wednesday: {
        start: "08:00",
        end: "20:00",
      },

      thursday: {
        start: "08:00",
        end: "20:00",
      },

      friday: {
        start: "08:00",
        end: "20:00",
      },
    },
    custom_hours: {
      monday: {
        open: true,
        start: "08:00",
        end: "20:00",
      },
      tuesday: {
        open: true,
        start: "08:00",
        end: "20:00",
      },
      wednesday: {
        open: true,
        start: "08:00",
        end: "20:00",
      },
      thursday: {
        open: true,
        start: "08:00",
        end: "20:00",
      },
      friday: {
        open: true,
        start: "08:00",
        end: "20:00",
      },
      saturday: {
        open: false,
        start: "",
        end: "",
      },
      sunday: {
        open: false,
        start: "",
        end: "",
      },
    },
  },
};
export const voicemailSettingsInitialValues = {
  voicemail_greeting: "",
};
export const advanceSettingsInitialValues = {
  during_business_hour: {
    incoming_reply: "",
    no_voicemail_reply: "",
    voicemail_reply: "",
  },
  closed_business_hour: {
    incoming_reply: "",
    no_voicemail_reply: "",
    voicemail_reply: "",
  },
  delete_sms: false,
};

// Validation Schemas
export const privacySettingsValidationSchema = yup.object({
  // enable_business_hour: yup.boolean(),
  business_hour: yup.object().when("enable_business_hour", {
    is: true,
    then: yup.object({
      timezone: yup.string().required(getRequiredText("Timezone")),
      days: yup.string().required(getRequiredText("This")),
      custom_hours: yup.object().when("days", {
        is: (val) => val === "custom",
        then: yup.object({
          monday: yup.object({
            start: yup.string().when("open", {
              is: true,
              then: yup.string().required(getRequiredText("This")),
            }),
            end: yup.string().when("open", {
              is: true,
              then: yup.string().required(getRequiredText("This")),
            }),
          }),
          tuesday: yup.object({
            start: yup.string().when("open", {
              is: true,
              then: yup.string().required(getRequiredText("This")),
            }),
            end: yup.string().when("open", {
              is: true,
              then: yup.string().required(getRequiredText("This")),
            }),
          }),
          wednesday: yup.object({
            start: yup.string().when("open", {
              is: true,
              then: yup.string().required(getRequiredText("This")),
            }),
            end: yup.string().when("open", {
              is: true,
              then: yup.string().required(getRequiredText("This")),
            }),
          }),
          thursday: yup.object({
            start: yup.string().when("open", {
              is: true,
              then: yup.string().required(getRequiredText("This")),
            }),
            end: yup.string().when("open", {
              is: true,
              then: yup.string().required(getRequiredText("This")),
            }),
          }),
          friday: yup.object({
            start: yup.string().when("open", {
              is: true,
              then: yup.string().required(getRequiredText("This")),
            }),
            end: yup.string().when("open", {
              is: true,
              then: yup.string().required(getRequiredText("This")),
            }),
          }),
          saturday: yup.object({
            start: yup.string().when("open", {
              is: true,
              then: yup.string().required(getRequiredText("This")),
            }),
            end: yup.string().when("open", {
              is: true,
              then: yup.string().required(getRequiredText("This")),
            }),
          }),
          sunday: yup.object({
            start: yup.string().when("open", {
              is: true,
              then: yup.string().required(getRequiredText("This")),
            }),
            end: yup.string().when("open", {
              is: true,
              then: yup.string().required(getRequiredText("This")),
            }),
          }),
        }),
      }),
      every_day: yup.object().when("days", {
        is: (val) => val === "every_day",
        then: yup.object({
          start: yup.string().required(getRequiredText("This")),
          end: yup.string().required(getRequiredText("This")),
        }),
      }),
      week_days: yup.object().when("days", {
        is: (val) => val === "week_day",
        then: yup.object({
          monday: yup.object({
            start: yup.string().required(getRequiredText("This")),
            end: yup.string().required(getRequiredText("This")),
          }),
          tuesday: yup.object({
            start: yup.string().required(getRequiredText("This")),
            end: yup.string().required(getRequiredText("This")),
          }),
          wednesday: yup.object({
            start: yup.string().required(getRequiredText("This")),
            end: yup.string().required(getRequiredText("This")),
          }),
          thursday: yup.object({
            start: yup.string().required(getRequiredText("This")),
            end: yup.string().required(getRequiredText("This")),
          }),
          friday: yup.object({
            start: yup.string().required(getRequiredText("This")),
            end: yup.string().required(getRequiredText("This")),
          }),
        }),
      }),
    }),
  }),
});

export const incomingCallValidationSchema = yup.object({
  name: yup.string().required(getRequiredText("Name")),
});
export const callerSettingsValidationSchema = yup.object({
  name_incoming_call: yup
    .string()
    .required(getRequiredText("Caller ID name for incoming calls")),
  name_outgoing_call: yup
    .string()
    .required(getRequiredText("Caller ID name for outgoing calls")),
});
export const voicemailSettingsValidationSchema = yup.object({
  voicemail_greeting: yup.string().required(getRequiredText("Voicemail audio")),
});

export const weekDays = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];
