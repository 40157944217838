export const chat = {
  chatId: null,
  messages: [],
  pendingSeen: { isPending: false, count: 0 },
  latestMessage: '',
  canDeleteChat: false,
};

export const initialState = {
  allChats: [],
};

export const message = {
  messageId: null,
};

export const dummyChatData = [
  {
    chatId: 1,
    messages: [],
    pendingSeen: { isPending: false, count: 0 },
    latestMessage: '',
    canDeleteChat: false,
  },
  {
    chatId: 2,
    messages: [],
    pendingSeen: { isPending: false, count: 0 },
    latestMessage: '',
    canDeleteChat: false,
  },
  {
    chatId: 3,
    messages: [],
    pendingSeen: { isPending: false, count: 0 },
    latestMessage: '',
    canDeleteChat: false,
  },
  {
    chatId: 4,
    messages: [],
    pendingSeen: { isPending: false, count: 0 },
    latestMessage: '',
    canDeleteChat: false,
  },
  {
    chatId: 5,
    messages: [],
    pendingSeen: { isPending: false, count: 0 },
    latestMessage: '',
    canDeleteChat: false,
  },
];
