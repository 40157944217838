import { useQueryClient } from '@tanstack/react-query';
import { CloudArrowUp,  X, Microphone, Arrow } from 'assets/images';
import { useCreateGreeting, useUploadFile } from 'hooks';
import RecordForm from 'pages/PhoneNumbers/SettingsModal/PrivacyControlModal/AddVoicemailModal/RecordForm';
import TextToSpeech from 'pages/PhoneNumbers/SettingsModal/PrivacyControlModal/AddVoicemailModal/TextToSpeech';
import UploadFile from 'pages/PhoneNumbers/SettingsModal/PrivacyControlModal/AddVoicemailModal/UploadFile';
import React, { useState } from 'react';

const greetingAddOptions = [
  {
    id: 1,
    label: 'Upload a file',
    value: 'upload',
    icon: <CloudArrowUp />,
  },
  {
    id: 2,
    label: 'Record',
    value: 'record',
    icon: <Microphone />,
  },
  // {
  //   id: 3,
  //   label: 'Text to speech',
  //   value: 'text_to_speech',
  //   icon: <FileText />,
  // },
];
const CreateCustomGreetingModal = ({ handleClose = () => null } = {}) => {
  const queryClient = useQueryClient();
  const [activeOption, setActiveOption] = useState('');

  const { isLoading: mediaUploadLoad, uploadMedia } = useUploadFile();
  const { mutateAsync: createGreetingMutate, isPending: createGreetingLoad } =
    useCreateGreeting({ handleSuccess });

  const FormLookup = {
    text_to_speech: (
      <TextToSpeech
        mediaUploadLoad={createGreetingLoad}
        onSuccess={handleSubmit}
        type="G"
      />
    ),
    upload: (
      <UploadFile
        mediaUploadLoad={mediaUploadLoad || createGreetingLoad}
        mediaUploadAction={uploadMedia}
        acceptType="audio/*"
        onSuccess={handleSubmit}
        type="G"
      />
    ),
    record: (
      <RecordForm
        mediaUploadLoad={mediaUploadLoad || createGreetingLoad}
        mediaUploadAction={uploadMedia}
        onSuccess={handleSubmit}
        type="G"
      />
    ),
  };

  function handleSuccess() {
    setActiveOption('');
    handleClose();
    queryClient.invalidateQueries(['greetingList']);
  }
  async function handleSubmit(data) {
    if (!data) return;
    const payload = {
      name: data?.name,
      filename: data?.filename,
      size: data?.file?.size,
      type: data?.type,
      action_type: data?.action_type,
      duration: data?.duration,
    };
    await createGreetingMutate(payload);
  }

  const handleBack = () => {
    if (mediaUploadLoad || createGreetingLoad) return;
    setActiveOption('');
  };

  return (
    <div className="min-w-[500px]">
      <div className="flex justify-between items-start py-4 px-6">
        <div className="flex flex-col gap-1">
          <div className="text-xl font-semibold text-black">
            Create custom greeting
          </div>
          <div className="text-sm  text-grey-700">
            How do you want to create custom greeting?
          </div>
        </div>
        {activeOption ? (
          <button type="button" onClick={() => handleBack()}>
            <Arrow className="rotate-180 text-black" />
          </button>
        ) : (
          <button type="button" onClick={() => handleClose()}>
            <X className="text-grey-600 w-6 h-6" />
          </button>
        )}
      </div>
      {activeOption ? (
        <div className="p-6  mt-5"> {FormLookup[activeOption]}</div>
      ) : (
        <div className="p-6 flex flex-col gap-4 mt-5">
          {greetingAddOptions.map(({ id, label, value, icon }) => {
            return (
              <div
                key={id}
                className="border group hover:bg-green hover:text-white border-grey-400 rounded-md h-14 flex items-center justify-between px-4 cursor-pointer font-medium"
                onClick={() => setActiveOption(value)}
              >
                {label}
                <div className="text-grey-900 group-hover:text-white">
                  {icon}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default CreateCustomGreetingModal;
