import { useMutation, useQueryClient } from '@tanstack/react-query';
import { makePayment } from 'api';
import { toastEmitter } from 'components/Toast';

export default function useMakePayment({
  handleSuccess = () => null,
  handleError = () => null,
} = {}) {
  const queryClient = useQueryClient();

  const Request = useMutation({
    mutationFn: makePayment,
    mutationKey: ['makePayment'],
    onSuccess: (data) => {
      const status = data?.status;
      const responseData = data?.data;
      if (status !== 200) {
        handleError();
        return toastEmitter('error', responseData?.error?.message);
      }
      handleSuccess();
      if (responseData?.data?.payment_type === 'WALLET') {
        queryClient.refetchQueries({
          queryKey: ['walletDetails'],
          refetchType: 'all',
          exact: true,
        });
        queryClient.invalidateQueries('getDIDListing');
        return toastEmitter('success', responseData?.data?.message);
      }

      if (responseData?.data?.payment_type === 'NEW_CARD') {
        window.open(responseData?.data?.payment_provider_redirect_url, '_self');
      } else {
        window.open(responseData?.data?.callback_url, '_self');
      }
    },
  });
  return { ...Request };
}
