export const dropdownActions = {
  PLAY_GREETING: 'play_greeting',
  BULK_DELETE_RECORDINGS: 'bulks_delete_recordings',
};
export const initialModalState = {
  modalType: '',
  isShow: false,
  elementData: {},
  type: '',
};
