import React from 'react';
import { Button, Input } from 'components';
import { Controller } from 'react-hook-form';

export default function StepThree({
  control,
  errors,
  ifErrors,
  isSignupPending,
}) {
  return (
    <div className="flex flex-col xxl:mt-8 xl:mt-5 lg:mt-5 md:mt-4 ">
      <div className="xxl:mt-8 xl:mt-5 lg:mt-5 md:mt-4 sm:mt-4 xs:mt-4">
        <Controller
          name="company_name"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              autoComplete="off"
              label="Company name"
              error={errors?.company_name?.message}
              maxLength="50"
              optional
            />
          )}
        />
      </div>

      <div className="flex flex-col gap-2 xxl:mt-16 xl:mt-16 lg:mt-5 md:mt-4 sm:mt-5 xs:mt-4 ">
        <Button disabled={ifErrors || isSignupPending} type="submit">
          Continue
        </Button>
        <Button
          border="border-none"
          background="transparent"
          disabled={ifErrors || isSignupPending}
          type="submit"
        >
          <span className="text-green-400">Skip, and continue</span>
        </Button>
      </div>
    </div>
  );
}
