import { Avatar, Skeletons } from 'components';
import { useDidListWithCache } from 'hooks';
import { useAuth } from 'hooks/useAuth';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { formatPhoneNumber } from 'shared/resources';

const loader = (
  <>
    <div className="flex gap-3 items-center">
      <div className="w-9 h-9 rounded-full">
        <Skeletons type="circle" />
      </div>
      <div className="flex flex-col gap-1">
        <div className="flex items-center text-sm font-medium	 text-grey-900">
          <div className="w-24">
            <Skeletons />
          </div>
        </div>
      </div>
    </div>{' '}
    <div className="flex gap-3 items-center">
      <div className="w-9 h-9 rounded-full">
        <Skeletons type="circle" />
      </div>
      <div className="flex flex-col gap-1">
        <div className="flex items-center text-sm font-medium	 text-grey-900">
          <div className="w-24">
            <Skeletons />
          </div>
        </div>
      </div>
    </div>{' '}
    <div className="flex gap-3 items-center">
      <div className="w-9 h-9 rounded-full">
        <Skeletons type="circle" />
      </div>
      <div className="flex flex-col gap-1">
        <div className="flex items-center text-sm font-medium	 text-grey-900">
          <div className="w-24">
            <Skeletons />
          </div>
        </div>
      </div>
    </div>
  </>
);

const CustomLink = ({ nav, index }) => {
  const { user } = useAuth();
  const { number } = useParams();
  const navigate = useNavigate();
  const numberName = nav?.settings?.incoming_call?.name;
  const numberIcon = nav?.settings?.incoming_call?.icon
    ? `${process.env.REACT_APP_BASE_API_URL}${
        user?.company_uuid || user?.uuid
      }/icon/${nav?.settings?.incoming_call?.icon}`
    : '';

  useEffect(() => {
    if (!number && index === 0) {
      navigate(`${nav?.uuid}`);
    }
  }, []);

  return (
    <Link
      key={nav?.uuid}
      to={nav?.uuid}
      className={`xxl:text-base xl:text-base lg:text-sm md:text-sm  flex items-center gap-3 p-2 rounded-md hover:bg-grey-300 cursor-pointer  ${
        nav?.isActive ? 'bg-grey-300' : ''
      }`}
    >
      <Avatar
        mediaUrl={numberIcon}
        name={numberName ?? 'Unknown'}
        type="icon"
      />
      <div className="flex flex-col">
        <span className="font-semibold text-sm">{numberName}</span>
        <span className="text-xs font-medium  text-grey-600">
          {formatPhoneNumber(nav?.did_number)}
        </span>
      </div>
    </Link>
  );
};

const Sidebar = () => {
  const location = useLocation();
  const { isLoading, data } = useDidListWithCache();
  const [mutatedRoutes, setMutatedRoutes] = useState([]);

  useEffect(() => {
    if (data?.data?.data?.length > 0) {
      const mutatedRoutes = data?.data?.data?.map((nav) => {
        if (location.pathname.includes(nav?.uuid)) {
          return { ...nav, isActive: true };
        } else {
          return { ...nav, isActive: false };
        }
      });
      setMutatedRoutes(mutatedRoutes);
    } else {
      setMutatedRoutes([]);
    }
  }, [data?.data?.data, location?.pathname]);

  return (
    <div className=" xxl:min-w-[290px] xl:min-w-[250px] lg:min-w-[256px] md:min-w-[230px] pt-7 flex flex-col border-grey-300 border-r">
      <div className="font-medium text-base text-grey-600 pb-[22px] px-4">
        Inboxes
      </div>
      <div className="flex flex-col gap-2.5 px-4 mb-2">
        {isLoading
          ? loader
          : mutatedRoutes?.map((nav, index) => (
              <CustomLink key={nav?.did_number} nav={nav} index={index} />
            ))}
      </div>
    </div>
  );
};

export default Sidebar;
