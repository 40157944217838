import React from 'react';
import { Input } from 'components';
import { Controller } from 'react-hook-form';
import { weekdayBusinessHours } from 'pages/PhoneNumbers/constants';

const WeekdaysHourForm = ({ control, errors }) => {
  return (
    <div className=" flex flex-col  items-center gap-3">
      {weekdayBusinessHours &&
        weekdayBusinessHours.map(({ label, id }) => {
          return (
            <div key={id} className="w-full flex items-center gap-6">
              <div className="flex items-center gap-3 min-w-[60px]">
                <label className="text-grey-700 text-sm font-medium">
                  {label}
                </label>
              </div>
              <div className="flex items-center gap-2 w-full">
                <Controller
                  name={`business_hour.week_days.${id}.start`}
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      onWhitebg={true}
                      type="time"
                      error={
                        errors?.business_hour?.week_days?.[id]?.start?.message
                      }
                    />
                  )}
                />
              </div>
              <div className="flex items-center gap-2 w-full">
                <label className="text-grey-700 text-sm font-medium">To</label>
                <Controller
                  name={`business_hour.week_days.${id}.end`}
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      onWhitebg={true}
                      type="time"
                      error={
                        errors?.business_hour?.week_days?.[id]?.end?.message
                      }
                    />
                  )}
                />
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default WeekdaysHourForm;
