import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { Button, Input } from 'components';
import { Vectorup, Vectordown, Apple } from 'assets/images/signup';
import { ayt, getRequiredText, numericRegx } from 'shared/resources';
import { Link } from 'react-router-dom';
import { useLogin, useSendOtp, useServerValidator } from 'hooks';
import Spinner from 'components/Spinner';
import {
  Arrow,
  Chevron,
  LogoBlack,
  LogoFullWhite,
  Refresh,
} from 'assets/images';
import AutoTypeComponent from 'components/AutoTypeComponent';
import useGoogleAuth from 'hooks/useGoogleAuth';
import useFacebookAuth from 'hooks/useFacebookAuth';

const formHeading = {
  0: 'Sign in',
};

const formSubHeading = {
  0: 'Fill the form below to get started',
};

const initialValues = {
  phone: '+234',
  otp: '',
  login_type: 'password',
  password: '',
};

const LOGIN_STEP = ({
  control,
  errors,
  ifErrors,
  watch,
  clearErrors,
  setError,
  isLoginPending = false,
}) => {
  const [isOTPSentSuccessfull, setIsOTPSentSuccessfull] = useState(false);
  const [counter, setCounter] = useState(60);
  const [isConterRunning, setIsConterRunning] = useState(false);

  const { mutate: sendOTP, isPending: sendingOTP } = useSendOtp({
    acionFn: setIsOTPSentSuccessfull,
    counterFn: setCounter,
  });
  const [watchPhone, loginType] = watch(['phone', 'login_type']);

  const { loadingServerError: serverPhoneErrorLoading, isValid } =
    useServerValidator({
      payload: {
        watch: watchPhone,
        value: watchPhone,
        type: 'login_validate',
        key: 'phone',
      },
      successAction: clearErrors,
      errorAction: setError,
      delay: 1000,
      shouldValidate: watchPhone && watchPhone?.length > 9,
    });

  useEffect(() => {
    if (isOTPSentSuccessfull) {
      if (counter > 0) {
        if (!isConterRunning) {
          setIsConterRunning(true);
        }
        setTimeout(() => setCounter(counter - 1), 1000);
      } else {
        setIsConterRunning(false);
      }
    }
  }, [isOTPSentSuccessfull, counter]);

  return (
    <div className="flex flex-col  ">
      <div className="xxl:mt-8 xl:mt-6 lg:mt-5 md:mt-4 sm:mt-4 xs:mt-4">
        <Controller
          name="phone"
          control={control}
          render={({ field }) => {
            const { onChange } = field;
            return (
              <div className="flex flex-col">
                <div className="xs:flex-col sm:flex-row flex  gap-3 relative">
                  <div className="w-full">
                    <Input
                      {...field}
                      required={true}
                      label="Phone number"
                      autoComplete="off"
                      maxLength="17"
                      onChange={(e) => {
                        const aytNumber = ayt(e.target.value);
                        onChange(aytNumber);
                      }}
                      error={errors?.phone?.message}
                      isServerValidationLoading={serverPhoneErrorLoading}
                    />
                  </div>
                  {loginType === 'otp' ? (
                    <div className="xs:w-full sm:w-6/12 xxl:mt-7 xl:mt-6 lg:mt-6 md:mt-5 sm:mt-6 xs:mt-1  ">
                      <Button
                        disabled={
                          sendingOTP ||
                          !!errors?.phone?.message ||
                          (watchPhone && watchPhone?.length < 14) ||
                          !isValid ||
                          isConterRunning
                        }
                        onClick={() =>
                          sendOTP({
                            type: 'send_otp',
                            phone: watchPhone,
                            country: 'NG',
                            template_type: 'signIn',
                          })
                        }
                      >
                        {sendingOTP ? (
                          <Spinner />
                        ) : (
                          <div className="flex gap-1 items-center">
                            {isOTPSentSuccessfull ? <Refresh /> : null}
                            {isOTPSentSuccessfull ? 'Resend code' : 'Send code'}
                            {isOTPSentSuccessfull ? (
                              counter ? (
                                <>&nbsp;{counter}s</>
                              ) : null
                            ) : null}
                          </div>
                        )}
                      </Button>
                    </div>
                  ) : null}
                </div>
                {loginType === 'otp' ? (
                  <div className="xxl:text-base xl:text-[15px] lg:text-sm md:text-xs sm:text-sm xs:text-sm text-grey-600  mt-1">
                    We would send you a verification code to confirm this is
                    your phone number.
                  </div>
                ) : null}
              </div>
            );
          }}
        />
      </div>
      <div className="xxl:mt-8 xl:mt-6 lg:mt-5 md:mt-4 sm:mt-4 xs:mt-4 ">
        {loginType === 'otp' ? (
          isOTPSentSuccessfull ? (
            <div className="xs:min-h-[calc(100vh_-_350px)] md:min-h-[100%]">
              <div className="flex flex-col w-full">
                <Controller
                  name="otp"
                  control={control}
                  render={({ field }) => {
                    const { onChange } = field;
                    return (
                      <Input
                        {...field}
                        onChange={(e) => {
                          if (numericRegx.test(e.target.value)) {
                            onChange(e.target.value);
                          } else {
                            return;
                          }
                        }}
                        required={true}
                        autoComplete="off"
                        label="Verification code"
                        error={errors?.otp?.message}
                        maxLength="6"
                      />
                    );
                  }}
                />
                <div className="xxl:text-base xl:text-[15px] lg:text-sm md:text-xs sm:text-sm xs:text-sm text-grey-600  mt-1">
                  Enter the verification code sent to your phone number.
                </div>
              </div>
            </div>
          ) : null
        ) : (
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                disabled={!isValid || watchPhone?.length < 10}
                type="password"
                required={true}
                label="Password"
                autoComplete="new-password"
                error={errors?.confirm_password?.message}
              />
            )}
          />
        )}
      </div>

      {loginType === 'otp' ? (
        isOTPSentSuccessfull ? (
          <div className="xxl:mt-8 xl:mt-6 lg:mt-5 md:mt-4 sm:mt-4 xs:mt-4">
            <Button
              type="submit"
              disabled={
                isLoginPending ||
                ifErrors ||
                !isValid ||
                !watch('otp') ||
                watch('otp')?.length !== 6
              }
            >
              {isLoginPending && !defaultSocialLogin ? <Spinner /> : 'Sign in'}
            </Button>
          </div>
        ) : null
      ) : (
        <div className="xxl:mt-8 xl:mt-6 lg:mt-5 md:mt-4 sm:mt-4 xs:mt-4">
          <Button
            type="submit"
            disabled={
              isLoginPending || ifErrors || !isValid || !watch('password')
            }
          >
            {isLoginPending && !defaultSocialLogin ? <Spinner /> : 'Sign in'}
          </Button>
        </div>
      )}
      {loginType !== 'otp' ? (
        <div className="xxl:mt-6 xl:mt-6 lg:mt-6 md:mt-6 sm:mt-4 xs:mt-4 text-center">
          <Link to="/forgot-password" className="text-green-400 font-medium	">
            Forgot password?
          </Link>
        </div>
      ) : null}
    </div>
  );
};

let defaultSocialLogin = null;

export default function Login() {
  const [retryCount, setRetryCount] = useState(0);
  const validationSchema = yup.object().shape({
    phone: yup.string().required(getRequiredText('Phone')),
    otp: yup.string().when('login_type', {
      is: (type) => type === 'otp',
      then: yup.string().required(getRequiredText('Verification code')),
    }),
    password: yup.string().when('login_type', {
      is: (type) => type === 'password',
      then: yup.string().required(getRequiredText('Password')),
    }),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setError,
    setValue,
    clearErrors,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: 'onChange',
  });
  const { isPending: isLoginPending, mutate: handleLogin } = useLogin({
    acionFn: () => setRetryCount((prev) => prev + 1),
  });

  async function onSubmit(values) {
    defaultSocialLogin = null;
    handleLogin(values);
  }

  const handleFacebookResponse = (res) => {
    if (res) {
      if (res?.id) {
        defaultSocialLogin = 'FACEBOOK';
        handleLogin({ auth_id: res.id });
      }
    }
  };

  const handleGoogleResponse = (res) => {
    if (res) {
      if (res?.sub) {
        defaultSocialLogin = 'GOOGLE';
        handleLogin({ auth_id: res.sub });
      }
    }
  };

  const GoogleAuth = useGoogleAuth({
    actionFn: handleGoogleResponse,
    isLoading: isLoginPending,
    socialType: defaultSocialLogin,
  });
  const FacebookAuth = useFacebookAuth({
    actionFn: handleFacebookResponse,
    isLoading: isLoginPending,
    socialType: defaultSocialLogin,
  });

  const ifErrors = errors && Object.keys(errors).length > 0;

  useEffect(() => {
    if (retryCount && retryCount === 3) {
      setValue('login_type', 'otp');
    }
  }, [retryCount]);

  const loginStep = {
    0: (
      <LOGIN_STEP
        control={control}
        errors={errors}
        setValue={setValue}
        ifErrors={ifErrors}
        watch={watch}
        clearErrors={clearErrors}
        setError={setError}
        isLoginPending={isLoginPending}
        defaultSocialLogin={defaultSocialLogin}
      />
    ),
  };

  return (
    <div className="flex h-screen ">
      <div className="bg-green-50 w-screen overflow-auto relative xxl:pt-10 xl:pt-10 lg:pt-8 md:pt-5 sm:pt-8 xs:pt-5">
        <div className="lg:hidden gap-3 sm:flex xs:flex items-center justify-center mb-5 px-4">
          <LogoBlack className="xs:w-20" />
        </div>
        <div className="m-auto xxl:w-6/12 xl:w-6/12 lg:w-6/12 md:w-6/12 sm:w-8/12 xs:w-11/12">
          <div className="flex flex-col ">
            <div className="xxl:text-3xl xl:text-[28px] lg:text-[24px] md:text-[22px] xs:text-3xl text-black font-medium lg:w-6/">
              {formHeading[0]}
            </div>
            <div className="xxl:text-base xl:text-[15px] lg:text-[15px] md:text-[14px] font-normal	 text-grey-600 xxl:mt-[.1em] ">
              {formSubHeading[0]}
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            {loginStep[0]}
          </form>

          {watch('login_type') === 'password' ? (
            <div className="flex flex-col  ">
              <div className="flex items-center xxl:mt-8 xl:mt-6 lg:mt-5 md:mt-4 sm:mt-5 xs:mt-5 ">
                <hr className="h-px w-full  text-grey-500 "></hr>
                <div className="whitespace-nowrap text-center px-2 text-grey-500 ">
                  or continue with
                </div>
                <hr className="h-px w-full  text-grey-500 "></hr>
              </div>
              <div className="flex xs:flex-col md:flex-row items-center   xxl:gap-5 xl:gap-5 lg:gap-5 md:gap-5 sm:gap-2 xs:gap-2   xxl:mt-8 xl:mt-6 lg:mt-5 md:mt-4 sm:mt-5 xs:mt-5">
                {GoogleAuth}
                {FacebookAuth}
                <div className="flex items-center justify-center gap-2 border border-solid  border-grey-400 rounded-md xxl:h-[56px] xl:h-[45px] lg:h-[45px] md:h-[41px] sm:h-[40px] xs:h-[40px]   text-black  w-full cursor-pointer">
                  <Apple className="w-5" /> Apple
                </div>
              </div>
              <div className="text-sm text-grey-600 text-center leading-6	xxl:mt-8 xl:mt-6 lg:mt-5 md:mt-4 sm:mt-4 xs:mt-4">
                By Signing in, you automatically agree to our
                <br></br>
                <a
                  className="text-green-400 cursor-pointer"
                  href="https://www.fonu.com/terms.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms & Conditions
                </a>
                &nbsp;and&nbsp;
                <a
                  className="text-green-400 cursor-pointer"
                  href="https://www.fonu.com/privacy.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy.
                </a>
              </div>
              <div className="text-base text-grey-800  text-center xxl:mt-8 xl:mt-6 lg:mt-5 md:mt-4 sm:mt-4 xs:mt-4">
                Don&apos;t have an account?&nbsp;
                <Link to="/signup" className="text-green-400 font-medium	">
                  Sign up
                </Link>
              </div>
            </div>
          ) : (
            <div
              onClick={() => (window.location.href = '/')}
              className="flex gap-1  items-center cursor-pointer absolute left-4 md:top-10 xs:top-5"
            >
              <Chevron className="rotate-90 xs:hidden md:flex" />
              <Arrow className="rotate-180 xs:flex md:hidden " />
              <span className="xs:hidden md:flex">Back</span>
            </div>
          )}
        </div>
      </div>
      <div className=" h-full px-12 py-10 xxl:min-w-[570px] xl:min-w-[450px] lg:min-w-[380px] md:min-w-[365px] xxl:flex xl:flex lg:flex md:flex sm:hidden xs:hidden relative  flex-col justify-between  	 bg-green">
        <div className="flex gap-3">
          <LogoFullWhite className=" xxl:w-28 xl:w-24 lg:w-[85px] md:w-20" />
        </div>
        <AutoTypeComponent extraClass={'text-black'} />
        <div className="absolute right-0 top-0 xxl:w-auto lg:w-5/6 md:w-4/6">
          <Vectorup className="w-full" />
        </div>
        <div className="absolute left-0 bottom-0 xxl:w-auto lg:w-5/6 md:w-4/6">
          <Vectordown className="w-full" />
        </div>
      </div>
    </div>
  );
}
