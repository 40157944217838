import { bulkCallSessions } from "api";
import {
  Button,
  Modal,
  PlayAudioModal,
  Spinner,
  TableManager,
} from "components";
import React, { useEffect, useMemo, useState } from "react";
import { ADMIN, OWNER, formatDateTime } from "shared/resources";
import { dropdownActions, initialModalState } from "./constants";
import { useAuth } from "hooks/useAuth";
import { Loader2, Play, X } from "lucide-react";
import useSessionLogs from "hooks/useSessionLogs";
import { Download } from "assets/images";
import { toastEmitter } from "components/Toast";
import axios from "axios";
import { routes } from "api/routes";
import { getToken } from "shared/resources";
import useBulkDeleteSession from "hooks/useBulkDeleteSession";
import BulkDeleteSessionModal from "./BulkDeleteSessionModal";

const BulkDeleteSessionComponent = ({ data = [], callback = () => null }) => {
  const { mutate, isPending, status } = useBulkDeleteSession();
  const [showModal, setShowModal] = useState(initialModalState);

  useEffect(() => {
    if (status && status === "success") {
      callback();
    }
  }, [status]);

  const ModalLookup = {
    [dropdownActions.DELETE_BULK_SESSION]: (
      <BulkDeleteSessionModal
        handleClose={callback}
        data={data}
        deleteFn={() => mutate({ uuid: data.join(",") })}
        isLoading={isPending}
      />
    ),
  };

  const { isShow, modalType } = showModal;

  return (
    <div className="w-64">
      <Button
        height="h-10"
        type="button"
        background="danger"
        onClick={() =>
          setShowModal({
            isShow: true,
            modalType: dropdownActions.DELETE_BULK_SESSION,
            elementData: {},
          })
        }
      >
        {isPending ? <Spinner /> : `Delete ${data?.length} session(s)`}
      </Button>
      {isShow ? (
        <Modal
          handleClose={callback}
          headerComponent={null}
          footerComponent={null}
          shouldCloseOnClickOutside={false}
        >
          {ModalLookup[modalType]}
        </Modal>
      ) : null}
    </div>
  );
};

const filterInitialValues = {
  filter: [
    {
      name: "",
      a_filter_type: "",
      placeholder: "Enter name",
      type: "text",
      shouldFilterOptionDisabled: false,
      label: "Name",
      filterName: "name",
      position: 1,
    },

    {
      dob: "date",
      a_filter_type: "",
      placeholder: "Select date",
      type: "date-input",
      shouldFilterOptionDisabled: true,
      label: "Date",
      filterName: "date",
      position: 3,
    },
  ],
};

function BulkCallDetails() {
  const { user } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [filters, setFilters] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [sessionUuid, setSessionUuid] = useState(null);
  const [memberName, setMemberName] = useState(null);
  const { data, mutate, isPending } = useSessionLogs();
  const [showPlayModal, setPlayModal] = useState(initialModalState);
  const [tab, setTab] = useState("ALL");
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [batchName, setBatchName] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (sessionUuid) {
      mutate({
        page: 1,
        limit: 1000,
        session_uuid: sessionUuid,
      });
    }
  }, [sessionUuid, mutate, memberName]);

  const checkboxSelectableFn = () => {
    return true;
  };

  const singleSessionDownload = async (element) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}${routes.CALL_SESSION_SINGLE_EXPORT.URL}`,
        { session_uuid: element.uuid },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const sanitizedSessionName =
        element.name.replace(/[^a-z0-9]/gi, "_").toLowerCase() +
        "_" +
        element.uuid;
      const sessionFileName = `${sanitizedSessionName}-session.csv`;
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = sessionFileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
        return (window.location.href = "/");
      } else {
        toastEmitter("error", error?.response?.data?.error?.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "check",
        header: (props) => {
          return (
            <input
              className="checked:bg-green checked:hover:bg-green checked:active:bg-green checked:focus:bg-green focus:outline-none focus:ring-1 focus:ring-green"
              type="checkbox"
              checked={props?.table?.getIsAllRowsSelected()}
              onChange={props?.table?.getToggleAllRowsSelectedHandler()}
            />
          );
        },
        cell: (props) => {
          return (
            <input
              key={props?.row?.id}
              className="checked:bg-green checked:hover:bg-green checked:active:bg-green checked:focus:bg-green focus:outline-none focus:ring-1 focus:ring-green"
              type="checkbox"
              checked={props?.row?.getIsSelected()}
              onChange={props?.row?.getToggleSelectedHandler()}
            />
          );
        },
        meta: {
          colType: "action",
          width: "5%",
          hasAccess: [OWNER, ADMIN].includes(user?.role_id),
        },
      },
      {
        accessorKey: "created_at",
        header: () => "Date",
        cell: (props) => {
          const [date, time] = formatDateTime(props?.getValue());
          return (
            <span>
              {date}&nbsp;&#183;&nbsp;{time}
            </span>
          );
        },
      },
      {
        accessorKey: "first_name",
        header: () => "Member",
        cell: (props) => {
          const element = props?.row?.original || {};
          return (
            <span>
              {element.first_name} {element.last_name}
            </span>
          );
        },
      },
      {
        accessorKey: "name",
        header: () => "Batch name",
        cell: (props) => {
          const element = props?.row?.original || {};
          return <span>{element.name}</span>;
        },
      },
      {
        accessorKey: "successful_count",
        header: () => "Successful Calls",
        cell: (props) => {
          const element = props?.row?.original || {};
          return (
            <span>
              {element.successful_count}/
              {element.successful_count + element.failed_count} Successful
            </span>
          );
        },
      },
      {
        accessorKey: "total_contacts",
        header: () => "Total contacts",
        cell: (props) => {
          const element = props?.row?.original || {};
          return <span>{element.total_contacts} contacts</span>;
        },
      },
      {
        accessorKey: "action",
        header: () => "",
        cell: (props) => {
          const element = props?.row?.original || {};
          const fullName = `${element.first_name ? element.first_name : ""} ${
            element.last_name ? `${element.last_name}:` : ""
          }`;
          return (
            <div className="flex gap-3">
              <span
                className="text-green cursor-pointer"
                onClick={() => {
                  setSessionUuid(element?.uuid);
                  setMemberName(fullName);
                  setBatchName(element?.name);
                  setShowModal(true);
                }}
              >
                View session logs
              </span>
            </div>
          );
        },
        meta: {
          colType: "action",
          width: "5%",
        },
      },
      {
        accessorKey: "action",
        header: () => "",
        cell: (props) => {
          const element = props?.row?.original || {};
          return (
            <span
              className="flex justify-end cursor-pointer items-end"
              onClick={(e) => {
                e.preventDefault();
                singleSessionDownload(element);
              }}
            >
              {loading ? <Loader2 className="animate-spin" /> : <Download />}
            </span>
          );
        },
      },
    ],
    [setSessionUuid]
  );
  function handleCloseModal() {
    setShowModal(false);
  }

  const bulkActions = [
    {
      id: 1,
      component: BulkDeleteSessionComponent,
    },
  ];
  const { isShow, type } = showPlayModal;
  function handleClosePlayModal() {
    setPlayModal(initialModalState);
  }

  return (
    <>
      <TableManager
        {...{
          fetcherKey: "bulkCallSessions",
          fetcherFn: bulkCallSessions,
          columns,
          showPagination: true,
          bulkActionsAccKey: "uuid",
          isSearchable: true,
          searchLabel: "Manage bulk call sessions/logs in your workspace",
          searchPlaceholder: "Search with member name..",
          filters,
          setFilters,
          openFilter,
          setOpenFilter,
          filterInitialValues,
          bulkActions,
          checkboxSelectableFn,
          downloadSessions: true,
          calendarBtn: true,
          setTab,
          setFrom,
          setTo,
          fromDate: from,
          toDate: to,
          tabList: [
            {
              id: 1,
              label: "All",
              onclick: () => setTab("ALL"),
              value: "ALL",
            },
            {
              id: 2,
              label: "Daily",
              onclick: () => setTab("DAILY"),
              value: "DAILY",
            },
            {
              id: 3,
              label: "Weekly",
              onclick: () => setTab("WEEKLY"),
              value: "WEEKLY",
            },
            {
              id: 4,
              label: "Monthly",
              onclick: () => setTab("MONTHLY"),
              value: "MONTHLY",
            },
          ],
          currentTab: tab,
          extraParams: {
            type: tab,
            from: from,
            to: to,
          },
        }}
      />
      {showModal && (
        <div
          className="fixed inset-0 z-50 animate-modalAnimation flex items-center justify-center bg-black bg-opacity-50"
          onClick={handleCloseModal}
        >
          <div
            className="bg-white relative rounded-lg xs:w-[400px] w-[500px] sm:w-[600px] h-[600px] flex flex-col justify-start p-2 sm:p-6 overflow-y-auto"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="bg-white mb-10">
              <p className="bg-white z-40 absolute left-6 text-lg font-bold">
                {batchName}
              </p>
              <button
                onClick={() => setShowModal(false)}
                className="right-6 absolute"
              >
                <X size={30} className="text-black/30" />
              </button>
            </div>
            <div className="font-bold my-2 text-base">{memberName}</div>
            <div className="flex flex-col px-4 justify-start overflow-y-auto">
              {isPending ? (
                <div className="flex justify-center items-center">
                  <Loader2 className="animate-spin" size={30} />
                </div>
              ) : data?.data?.data?.length > 0 ? (
                <div className="mt-8 flex justify-center items-center gap-4 flex-col w-full">
                  {data?.data?.data.map((logs, index) => {
                    const [date, time] = formatDateTime(logs.created_at);

                    return (
                      <div
                        key={index}
                        className="flex flex-col gap-2 w-full rounded-md border-b border-2 border-green-400"
                      >
                        <div className="flex justify-between items-center border-b border-black/40 py-3 px-3">
                          <p>Date</p>
                          <p>
                            {date}&nbsp;&#183;&nbsp;{time}
                          </p>
                        </div>
                        <div className="flex justify-between items-center border-b border-black/40 py-3 px-3">
                          <p>From</p>
                          <p>{logs.from ? logs.from : <p>---</p>}</p>
                        </div>
                        <div className="flex justify-between items-center border-b border-black/40 py-3 px-3">
                          <p>To</p>
                          <p>{logs.to ? logs.to : <p>---</p>}</p>
                        </div>
                        <div className="flex justify-between items-center border-b border-black/40 py-3 px-3">
                          <p>Status</p>
                          <p className="uppercase font-medium">
                            {logs.status === 1 ? (
                              <p className="text-green-400">Success</p>
                            ) : (
                              <p className="text-danger">FAILED</p>
                            )}
                          </p>
                        </div>
                        <div className="flex justify-between items-center border-b border-black/40 py-3 px-3">
                          <p>Duration</p>
                          <p>{logs.duration ? logs.duration : "---"}</p>
                        </div>

                        <div className="flex justify-between items-center py-3 px-3">
                          <p>Play Recording</p>
                          {logs?.recording_file ? (
                            <span
                              className="text-green cursor-pointer"
                              onClick={() => {
                                setPlayModal({
                                  isShow: true,
                                  modalType: dropdownActions.PLAY_RECORDING,
                                  elementData: logs,
                                  type: "center",
                                });
                              }}
                            >
                              <Play className="w-6 h-6" />
                            </span>
                          ) : (
                            <p>---</p>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="text-center my-4">No logs available</div>
              )}
            </div>
            {/* </div> */}
          </div>
        </div>
      )}
      {isShow && type === "center" && (
        <Modal
          handleClose={handleClosePlayModal}
          headerComponent={null}
          footerComponent={null}
          shouldCloseOnClickOutside={false}
        >
          <PlayAudioModal
            type="recording"
            fileNameAccessorKey={"recording_file"}
            showModal={showPlayModal}
            handleClose={handleClosePlayModal}
            customLabel="Recording"
          />
        </Modal>
      )}
    </>
  );
}

export default BulkCallDetails;
