import { useMutation } from "@tanstack/react-query";
import { callSessionLogs } from "api";

export default function useSessionLogs() {
  const { mutate, data, isError, isPending } = useMutation({
    mutationFn: callSessionLogs,
    mutationKey: ["callSessionLogs"],
  });

  return { mutate, data, isError, isPending };
}
