import { Button, Dropdown, Modal, Spinner, TableManager } from 'components';
import React, { useEffect, useState } from 'react';
import { dropdownActions, initialModalState } from './constants';
import { Edit, MenuIcon, NoRecordPhone, Trash } from 'assets/images';
import AddIVRModal from './AddIVRModal';
import { getIVRList } from 'api';
import DeleteIVRModal from './DeleteIVRModal';
import { useDeleteIVR } from 'hooks';
import useBulkDelete from 'hooks/useBulkDelete';
import BulkPhoneMenuDeleteModal from './BulkPhoneMenuDeleteModal';
import { ADMIN, OWNER } from 'shared/resources';
import { useAuth } from 'hooks/useAuth';

const BulkDeleteComponent = ({ data = [], callback = () => null }) => {
  const { mutate, isPending, status } = useBulkDelete();
  const [showModal, setShowModal] = useState(initialModalState);

  useEffect(() => {
    if (status && status === 'success') {
      callback();
    }
  }, [status]);

  const ModalLookup = {
    [dropdownActions.BULK_DELETE_PHONE_MENU]: (
      <BulkPhoneMenuDeleteModal
        handleClose={callback}
        data={data}
        deleteFn={mutate}
        isLoading={isPending}
      />
    ),
  };
  const { isShow, modalType } = showModal;

  return (
    <div className="w-64">
      <Button
        height="h-10"
        type="button"
        background="danger"
        onClick={() =>
          setShowModal({
            isShow: true,
            modalType: dropdownActions.BULK_DELETE_PHONE_MENU,
            elementData: {},
          })
        }
      >
        {isPending ? <Spinner /> : `Delete ${data?.length} phone  menu(s) -`}
      </Button>
      {isShow ? (
        <Modal
          handleClose={callback}
          headerComponent={null}
          footerComponent={null}
          shouldCloseOnClickOutside={false}
        >
          {ModalLookup[modalType]}
        </Modal>
      ) : null}
    </div>
  );
};

const NoRecordItem = () => {
  return (
    <div className="w-full grid place-content-center text-grey-600 gap-3 ">
      <div className="flex items-center justify-center flex-col h-[calc(100vh_-_309px)]">
        <NoRecordPhone />
        <span className="text-grey-600">
          You have no phone menu added, add now
        </span>
      </div>
    </div>
  );
};

function PhoneMenu() {
  const [showModal, setShowModal] = useState(initialModalState);
  const { user } = useAuth();
  const { mutate: deleteIVRMutate, isPending: deleteIVRLoad } = useDeleteIVR({
    handleSuccess: handleCloseModal,
  });
  const handleDropListClick = ({ value = '' }, element = {}) => {
    if (value) {
      setShowModal({
        isShow: true,
        modalType: value,
        elementData: element,
        type: 'center',
      });
    }
  };

  const columns = [
    {
      accessorKey: 'check',
      header: (props) => {
        return (
          <input
            className="checked:bg-green checked:hover:bg-green checked:active:bg-green checked:focus:bg-green focus:outline-none focus:ring-1 focus:ring-green"
            type="checkbox"
            checked={props?.table?.getIsAllRowsSelected()}
            onChange={props?.table?.getToggleAllRowsSelectedHandler()}
          />
        );
      },
      cell: (props) => {
        return (
          <input
            key={props?.row?.id}
            className="checked:bg-green checked:hover:bg-green checked:active:bg-green checked:focus:bg-green focus:outline-none focus:ring-1 focus:ring-green"
            type="checkbox"
            checked={props?.row?.getIsSelected()}
            onChange={props?.row?.getToggleSelectedHandler()}
          />
        );
      },
      meta: {
        colType: 'action',
        width: '5%',
        hasAccess: [OWNER, ADMIN].includes(user?.role_id),
      },
    },
    {
      accessorKey: 'name',
      header: () => 'Name',
    },
    {
      accessorKey: 'max_timeouts',
      header: () => 'Timeout duration',
      cell: (props) => `${props.getValue()} sec`,
    },
    {
      accessorKey: 'exec_on_max_timeouts',
      header: () => 'Timeout action',
      cell: (props) => {
        let timeout_action = props.row.original?.exec_on_max_timeouts;
        return timeout_action?.type;
      },
    },
    {
      accessorKey: 'action',
      header: () => '',
      cell: (props) => {
        const element = props?.row?.original || {};
        return (
          <div className="flex items-center gap-2">
            <Dropdown
              menuWidth="w-max"
              closeOnClickOutside={true}
              dropList={{
                component: ({ item }) => {
                  const Icon = item?.icon ?? null;
                  const del = item?.type === 'del' ? 'text-danger' : '';
                  return (
                    <span
                      className={`w-full h-full flex  justify-start gap-2 items-center py-2 px-3 ${del}`}
                      onClick={() => handleDropListClick(item, element)}
                    >
                      {Icon} {item?.title}
                    </span>
                  );
                },
                data: [
                  {
                    id: 1,
                    title: 'Edit phone menu',
                    value: dropdownActions.UPDATE_IVR,
                    icon: <Edit className="text-grey-700" />,
                  },
                  {
                    id: 2,
                    title: 'Delete phone menu',
                    value: dropdownActions.DELETE_IVR,
                    icon: <Trash className=" text-danger " />,
                    type: 'del',
                  },
                ],
              }}
              showcaret={false}
            >
              <div className="cursor-pointer w-1">
                <MenuIcon />
              </div>
            </Dropdown>
          </div>
        );
      },
      meta: {
        width: '5%',
        colType: 'action',
      },
    },
  ];

  const tableActions = [
    {
      id: 1,
      label: 'Add phone menu +',
      access: true,
      component: (
        <div className=" w-64">
          <Button
            height="h-10"
            type="button"
            onClick={() =>
              setShowModal({
                isShow: true,
                modalType: dropdownActions.ADD_IVR,
                type: 'center',
              })
            }
          >
            Add phone menu +
          </Button>
        </div>
      ),
    },
  ];

  function handleCloseModal() {
    setShowModal(initialModalState);
  }

  const ModalLookup = {
    [dropdownActions.ADD_IVR]: (
      <AddIVRModal showModal={showModal} handleClose={handleCloseModal} />
    ),
    [dropdownActions.UPDATE_IVR]: (
      <AddIVRModal
        showModal={showModal}
        handleClose={handleCloseModal}
        isEdit={true}
      />
    ),
    [dropdownActions.DELETE_IVR]: (
      <DeleteIVRModal
        showModal={showModal}
        handleClose={handleCloseModal}
        deleteFn={deleteIVRMutate}
        isLoading={deleteIVRLoad}
      />
    ),
  };
  const checkboxSelectableFn = () => true;
  const bulkActions = [
    {
      id: 1,
      component: BulkDeleteComponent,
    },
  ];
  const { isShow, type, modalType } = showModal;

  return (
    <>
      <TableManager
        {...{
          fetcherKey: 'getIVRList',
          fetcherFn: getIVRList,
          columns,
          name: 'Agency',
          showPagination: true,
          isSearchable: true,
          searchLabel: 'Manage phone menu in your workspace',
          searchPlaceholder: 'Search by name',
          tableActions,
          bulkActions,
          checkboxSelectableFn: checkboxSelectableFn,
          noRecordItem: NoRecordItem,
        }}
      />
      {isShow && type === 'center' ? (
        <Modal
          handleClose={handleCloseModal}
          headerComponent={null}
          footerComponent={null}
          shouldCloseOnClickOutside={false}
        >
          {ModalLookup[modalType]}
        </Modal>
      ) : null}
    </>
  );
}

export default PhoneMenu;
