import { CountrySelect, Input } from 'components';
import CustomSelect from 'components/CustomSelect';
import { useAuth } from 'hooks/useAuth';
import React from 'react';
import { Controller } from 'react-hook-form';

const callerIDNameOptionsIncoming = [
  {
    id: 1,
    label: 'Show the number of the caller',
    value: 'C',
  },
  {
    id: 2,
    label: 'Show the number called',
    value: 'D',
  },
  {
    id: 3,
    label: 'Show no number',
    value: 'N',
  },
];

const ItemComponent = ({ item }) => {
  return (
    <div
      key={item.value}
      value={item?.value}
      className="w-full h-full flex  justify-start gap-2 items-center py-2 px-3"
    >
      {item?.label}
    </div>
  );
};
const CallerIDSetting = ({ control, errors, watch }) => {
  const { user } = useAuth();

  const callerIDNameOptionsOutgoing = [
    {
      id: 1,
      label: 'Automatic',
      value: 'A',
      shouldVisible: true,
    },
    {
      id: 2,
      label: 'Show phone number',
      value: 'PH',
      shouldVisible: true,
    },
    {
      id: 3,
      label: 'Show company name',
      value: user?.company_name,
      shouldVisible: user?.company_name ? true : false,
    },
    {
      id: 4,
      label: 'Show custom name',
      value: 'CU',
      shouldVisible: true,
    },
  ];

  return (
    <>
      <div className="flex flex-col gap-1">
        <div className="text-grey-900 xxl:text-base xl:text-sm	 lg:text-sm md:text-xs	 font-medium">
          Caller ID name for incoming calls
        </div>
        <Controller
          name="name_incoming_call"
          control={control}
          render={({ field }) => (
            <CustomSelect
              {...field}
              height="xxl:h-[56px] xl:h-[45px] lg:h-[45px] md:h-[41px] sm:h-[40px] xs:h-[40px]"
              placeholder="Select option"
              onWhitebg={true}
              error={errors?.name_incoming_call?.message}
              ItemComponent={ItemComponent}
              valueKey={'value'}
              labelKey={'label'}
              isMulti={false}
              optionsList={callerIDNameOptionsIncoming}
            />
          )}
        />
      </div>
      <div className="flex flex-col gap-1 mt-6">
        <div className="text-grey-900 xxl:text-base xl:text-sm	 lg:text-sm md:text-xs	 font-medium">
          Caller ID name for outgoing calls
        </div>
        <Controller
          name="name_outgoing_call"
          control={control}
          render={({ field }) => (
            <CustomSelect
              {...field}
              height="xxl:h-[56px] xl:h-[45px] lg:h-[45px] md:h-[41px] sm:h-[40px] xs:h-[40px]"
              placeholder="Select option"
              onWhitebg={true}
              error={errors?.name_outgoing_call?.message}
              ItemComponent={ItemComponent}
              valueKey={'value'}
              labelKey={'label'}
              isMulti={false}
              optionsList={callerIDNameOptionsOutgoing?.filter(
                (item) => item.shouldVisible === true,
              )}
            />
          )}
        />
      </div>
      {watch('name_outgoing_call') === user?.company_name ? (
        <div className="flex flex-col gap-1 mt-6">
          <div className="text-grey-900 xxl:text-base xl:text-sm	 lg:text-sm md:text-xs	 font-medium">
            Company name
          </div>
          <CountrySelect
            background="bg-grey-100"
            title={user?.company_name}
            withFlag={false}
            withLabel={false}
          />
        </div>
      ) : null}
      {watch('name_outgoing_call') === 'CU' ? (
        <div className="flex flex-col gap-1 mt-6">
          <div className="text-grey-900 xxl:text-base xl:text-sm	 lg:text-sm md:text-xs	 font-medium">
            Enter custom name
          </div>
          <Controller
            name="custom_name"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                onWhitebg
                error={errors?.first_name?.message}
                maxLength={50}
              />
            )}
          />
        </div>
      ) : null}
    </>
  );
};

export default CallerIDSetting;
