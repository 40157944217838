import { useMutation, useQueryClient } from '@tanstack/react-query';
import { removeCartItems } from 'api';
import { toastEmitter } from 'components/Toast';

export default function useRemoveCartItem({
  onSuccessFn = () => null,
  onErrorFn = () => null,
} = {}) {
  const queryclient = useQueryClient();
  const Request = useMutation({
    mutationFn: removeCartItems,
    mutationKey: ['removeCartItems'],
    onSuccess: (data) => {
      const status = data?.status;
      const responseData = data?.data;
      if (status !== 200) {
        onErrorFn();
        return toastEmitter('error', responseData?.error?.message);
      }
      onSuccessFn();
      queryclient.invalidateQueries(['getCart']);
      toastEmitter('success', responseData?.data?.message);
    },
  });
  return { ...Request };
}
