import Tooltip from 'components/Tooltip';
import React from 'react';

const EllipsisTextWithTooltip = ({
  charLength = 20,
  string = '',
  withTooltip = true,
  position = 't',
}) => {
  const label = typeof string !== 'string' ? '' : string;
  const isTextBig =
    label && typeof label === 'string' && label?.length > charLength;

  if (isTextBig) {
    if (withTooltip) {
      return (
        <Tooltip title={label} position={position}>
          <span className="whitespace-nowrap cursor-pointer ">
            {label?.slice(0, charLength)}...
          </span>
        </Tooltip>
      );
    }
    return (
      <span className="whitespace-nowrap cursor-pointer ">
        {label?.slice(0, charLength)}...
      </span>
    );
  } else {
    return label;
  }
};

export default EllipsisTextWithTooltip;
