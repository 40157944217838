import moment from "moment";

export const _defaultState = {
  _status: null,
  _number: null,
  _uaSessions: {},
  _uiSessions: {},
  _ring: null,
};

export const _reducers = (_state, _action) => {
  const { _type, _payload = null } = _action;
  if (_type === "_SET_STATUS") {
    return { ..._state, _status: _payload };
  } else if (_type === "_SET_NUMBER") {
    return { ..._state, _number: _payload };
  } else if (_type === "_NEW_CALL") {
    let _uaSessions = { ..._state._uaSessions, ..._payload._uaSession };
    let _uiSessions = { ..._state._uiSessions, ..._payload._uiSession };
    return { ..._state, _uaSessions, _uiSessions };
  } else if (_type === "_UPDATE_CALL") {
    let { _uiSessions, _uaSessions } = _state;
    if (_payload._status === "connected") {
      Object.keys(_uiSessions)?.forEach((_key) => {
        if (
          _key !== _payload._call_id &&
          _uiSessions[_key]._status === "connected"
        ) {
          _uaSessions[_key].hold();
          _uiSessions[_key] = {
            ..._uiSessions[_key],
            _status: "hold",
            _active: false,
          };
        }
      });
    }
    _uiSessions[_payload._call_id] = {
      ..._uiSessions[_payload._call_id],
      _status: _payload._status,
      _active: true,
      _joined_at: moment.utc().valueOf(),
    };
    return { ..._state, _uiSessions };
  } else if (_type === "_HOLD_CALL") {
    let { _uiSessions } = _state;
    _uiSessions[_payload] = { ..._uiSessions[_payload], _status: "hold" };
    return { ..._state, _uiSessions };
  } else if (_type === "_UNHOLD_CALL" || _type === "_UNMUTE_CALL") {
    let { _uiSessions, _uaSessions } = _state;
    if (_type === "_UNHOLD_CALL") {
      Object.keys(_uiSessions)?.forEach((_key) => {
        if (_key !== _payload && _uiSessions[_key]._status === "connected") {
          _uaSessions[_key].hold();
          _uiSessions[_key] = {
            ..._uiSessions[_key],
            _status: "hold",
            _active: false,
          };
        }
      });
    }
    _uiSessions[_payload] = {
      ..._uiSessions[_payload],
      _status: "connected",
      _active: true,
    };
    return { ..._state, _uiSessions };
  } else if (_type === "_MUTE_CALL") {
    let { _uiSessions } = _state;
    _uiSessions[_payload] = { ..._uiSessions[_payload], _status: "mute" };
    return { ..._state, _uiSessions };
  } else if (_type === "_RECORDING") {
    let { _uiSessions } = _state;
    _uiSessions[_payload._call_id] = {
      ..._uiSessions[_payload._call_id],
      _recording: _payload._recording,
    };
    return { ..._state, _uiSessions };
  } else if (_type === "_FAILED_CALL" || _type === "_COMPLETE_CALL") {
    let { _uaSessions, _uiSessions } = _state;
    delete _uaSessions[_payload];
    delete _uiSessions[_payload];
    return { ..._state, _uaSessions, _uiSessions };
  } else {
    return _state;
  }
};
