import { getDIDListing } from 'api';
import { X } from 'assets/images';
import { Avatar, Button, Spinner } from 'components';
import ListManager from 'components/ListManager';
import useAssignDID from 'hooks/useAssignDID';
import { useAuth } from 'hooks/useAuth';
import { LoadingItem } from 'pages/Dashboard';
import React, { useState } from 'react';
import { formatPhoneNumber } from 'shared/resources';

const DIDListItem = ({ item, ...rest }) => {
  const { user } = useAuth();
  const numberIcon = item?.settings?.incoming_call?.icon
    ? `${process.env.REACT_APP_BASE_API_URL}${
        user?.company_uuid || user?.uuid
      }/icon/${item?.settings?.incoming_call?.icon}`
    : '';

  return (
    <label
      htmlFor={item?.did_number}
      className="flex items-center justify-between py-3 border-grey-300 border-b cursor-pointer "
    >
      <div className="flex gap-3">
        <div className="relative">
          <Avatar
            mediaUrl={numberIcon}
            name={item?.name ?? 'Unknown'}
            type="icon"
          />
        </div>
        <div className="flex flex-col gap-1">
          <div className="flex items-center text-sm font-medium	 text-grey-900">
            {item?.settings?.incoming_call?.name ?? 'Unknown'}&nbsp;&nbsp;
          </div>
          <div className="text-xs text-grey-600">
            {formatPhoneNumber(item?.did_number)}
          </div>
        </div>
      </div>
      <div className="flex text-xs text-green gap-1">
        <input
          className="checked:bg-green checked:hover:bg-green checked:active:bg-green checked:focus:bg-green focus:outline-none focus:ring-1 focus:ring-green"
          id={item?.did_number}
          type="radio"
          value={item?.did_number}
          onChange={(e) => rest?.onChangeItemHandler(e.target.value)}
          checked={item?.did_number === rest?.itemValue}
        />
      </div>
    </label>
  );
};

const AssignNumberModal = ({ handleClose = () => null, showModal = {} }) => {
  const itemElement = showModal?.elementData;
  const hasAssignedDID = itemElement?.caller_id;

  const [selectedDID, setSelectedDID] = useState('');

  const { mutate, isPending } = useAssignDID({
    onSuccessFn: () => handleClose(),
    onErrorFn: () => null,
  });

  const isDisabled = !selectedDID;

  const result = hasAssignedDID ? (
    <>
      <div className="flex justify-end items-center p-4">
        <button type="button" onClick={() => handleClose()}>
          <X className="text-grey-600 w-6 h-6" />
        </button>
      </div>
      <div className="text-center px-16 pb-5 justify-center">
        <div className="text-4xl text-black font-semibold	 mt-4 mb-1">
          Unassign Number
        </div>
        <div className="text-grey-700 text-base	mt-4 w-96">
          Are you sure you want to unassign <br />
          <span className="font-medium">{itemElement?.caller_id}</span> ?
        </div>
        <div className="mt-10 pb-14">
          <Button
            disabled={isPending}
            type="button"
            onClick={() =>
              mutate({
                did_numbers: [],
                uuid: itemElement?.uuid,
              })
            }
          >
            <div className="flex w-full px-5 justify-center items-center">
              {isPending ? <Spinner /> : 'Submit'}
            </div>
          </Button>
        </div>
      </div>
    </>
  ) : (
    <div className="min-w-[460px]">
      <div className="flex justify-between items-start py-4 px-6">
        <div className="flex flex-col gap-1">
          <div className="text-xl font-semibold text-black">Assign number</div>
          <div className="text-sm  text-grey-700">
            Select the number assigned to {itemElement?.email}
          </div>
        </div>
        <button type="button" onClick={() => handleClose()}>
          <X className="text-grey-600 w-6 h-6" />
        </button>
      </div>

      <div className=" px-6 pb-6 pt-0 border-grey-300 border-t overflow-auto xxl:max-h-[330px] xl:max-h-[330px] lg:max-h-[330px] md:max-h-[320px]">
        <ListManager
          actionFn={getDIDListing}
          queryKey="getDIDListing"
          listItem={DIDListItem}
          loadingComponent={LoadingItem}
          onChangeItemHandler={setSelectedDID}
          itemValue={selectedDID}
        />
      </div>

      <div className=" mt-10 px-6 pb-6">
        <Button
          onClick={() =>
            mutate({
              did_numbers: [selectedDID],
              uuid: itemElement?.uuid,
            })
          }
          disabled={isDisabled || isPending}
          extraClasses="justify-start pl-4 cursor-pointer"
          type="button"
        >
          {isPending ? <Spinner /> : 'Submit'}
        </Button>
      </div>
    </div>
  );

  return result;
};

export default AssignNumberModal;
