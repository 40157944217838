import { useQuery } from '@tanstack/react-query';
import { getIVRList } from 'api';

export default function useIVRListWithCache() {
  const didListRequest = useQuery({
    queryFn: ({ queryKey }) => getIVRList(queryKey[1] || {}),
    queryKey: ['getIVRListingWithCache', { limit: 1000 }],
    refetchOnMount: false,
  });

  return { ...didListRequest };
}
