import { X } from 'assets/images';
import Button from 'components/Button';
import Input from 'components/Input';
import React, { useState } from 'react';

const DateRange = ({
  handleModalClose,
  fromDate,
  toDate,
  setFromDate,
  setToDate,
  handleApply,
  setTab,
}) => {
  const [tempFromDate, setTempFromDate] = useState(fromDate);
  const [tempToDate, setTempToDate] = useState(toDate);

  const handleFromDateChange = (e) => {
    setTempFromDate(e.target.value);
  };

  const handleToDateChange = (e) => {
    setTempToDate(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFromDate(tempFromDate);
    setToDate(tempToDate);
    setTab('CUSTOM_RANGE');
    handleModalClose();
    handleApply(tempFromDate, tempToDate);
  };

  return (
    <div className="relative p-2">
      <div className="absolute top-2 right-2" onClick={handleModalClose}>
        <X />
      </div>
      <p className="text-[14px] font-medium">Set date range</p>
      <form className="flex flex-col gap-3 mt-4" onSubmit={handleSubmit}>
        <div className="flex flex-col gap-2">
          <label className="text-sm">From:</label>
          <Input
            type="date"
            required
            value={tempFromDate}
            onChange={handleFromDateChange}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label className="text-sm">To:</label>
          <Input
            type="date"
            required
            value={tempToDate}
            onChange={handleToDateChange}
          />
        </div>
        <Button type="submit">Apply</Button>
      </form>
    </div>
  );
};

export default DateRange;
