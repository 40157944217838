export const initialModalState = {
  modalType: '',
  isShow: false,
  elementData: {},
  type: '',
};

export const dropdownActions = {
  PURCHASE_BUNDLE: 'PURCHASE_BUNDLE',
  PURCHASE_CONFIRMATION: 'PURCHASE_CONFIRMATION',
};
