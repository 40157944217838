import { Download } from "assets/images";
import React, { useEffect, useState } from "react";
import { Avatar, Spinner } from "components";
import axios from "axios";
import { getToken } from "shared/resources";
import { toastEmitter } from "components/Toast";
import { routes } from "api/routes";
import ListManager from "components/ListManager";
import useAssignUnassignMemberNumber from "hooks/useAssignUnassignMemberNumber";
import { getMemberListing } from "api";
import { useAuth } from "hooks/useAuth";
import { LoadingItem } from "pages/Dashboard";
import { Controller, useForm } from "react-hook-form";
import useAllBatches from "hooks/useAllBatches";

const MemberListItem = ({ item, ...rest }) => {
  const { user } = useAuth();
  const isDisabled = item?.status === 0;
  return (
    <label
      htmlFor={item?.uuid}
      className="flex items-center justify-between py-3 border-grey-300 border-b cursor-pointer "
    >
      <div className="flex gap-3">
        <div className="relative">
          <Avatar
            name={`${item?.first_name} ${item?.last_name}`}
            mediaUrl={`${process.env.REACT_APP_BASE_API_URL}${
              user?.company_uuid || user?.uuid
            }/profile/${item?.profile_pic}`}
          />
        </div>
        <div className="flex flex-col gap-1">
          <div className="flex items-center text-sm font-medium	 text-grey-900">
            {item?.first_name} {item?.last_name ?? ""}&nbsp;&nbsp;
          </div>
        </div>
      </div>
      {isDisabled ? null : (
        <div className="flex text-xs text-green gap-1">
          <input
            className="checked:bg-green checked:hover:bg-green checked:active:bg-green checked:focus:bg-green focus:outline-none focus:ring-1 focus:ring-green"
            id={item?.uuid}
            type="checkbox"
            value={item?.uuid}
            onChange={(e) => {
              if (rest?.itemValue.includes(e.target.value)) {
                rest.itemValue.splice(
                  rest.itemValue.indexOf(e.target.value),
                  1
                );
                rest?.onChangeItemHandler([...rest.itemValue]);
              } else {
                rest?.onChangeItemHandler([...rest.itemValue, e.target.value]);
              }
            }}
            checked={rest?.itemValue?.includes(item?.uuid)}
          />
        </div>
      )}
    </label>
  );
};

const ImportCSV = ({ handleCSVModalClose }) => {
  const [file, setFile] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedBatchUuid, setSelectedBatchUuid] = useState("");

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type === "text/csv") {
      setFile(selectedFile);
      setError("");
    } else {
      setFile(null);
      setError("Please upload a valid CSV file.");
    }
  };

  const [selectedMembers, setSelectedMembers] = useState([]);
  const [hasData, setHasdata] = useState(false);
  const { allBatchesMutate, allBatchesData } = useAllBatches();

  const { isPending: assignTeamLoad } = useAssignUnassignMemberNumber();
  const {
    control,
    // formState: { errors },
    // setValue,
    // handleSubmit,
  } = useForm({
    mode: "onSubmit",
  });

  useEffect(() => {
    allBatchesMutate();
  }, [allBatchesMutate]);

  return (
    <div className="relative">
      <h1 className="text-[20px] font-bold my-2">Upload a CSV File</h1>

      <a
        className="text-[#4F772D] text-[18px] pb-6 flex gap-2 font-bold"
        href="/sample.csv"
        download
      >
        <Download />
        Download a sample CSV file
      </a>

      <form>
        <button
          type="submit"
          disabled={assignTeamLoad || !hasData}
          className="bg-green-400 absolute top-0 text-white w-[80px] right-0 h-[40px] rounded flex items-center justify-center"
          onClick={async (event) => {
            event.preventDefault();
            setLoading(true);
            if (file && selectedBatchUuid && selectedMembers.length > 0) {
              const formData = new FormData();
              formData.append("file", file);
              const members = selectedMembers.map((member) => {
                return { uuid: member };
              });
              formData.append("members", JSON.stringify(members));
              formData.append("batch_uuid", selectedBatchUuid);
              try {
                const response = await axios.post(
                  `${process.env.REACT_APP_BASE_API_URL}${routes.IMPORT_CONTACT.URL}`,
                  formData,
                  {
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "multipart/form-data",
                      Authorization: `Bearer ${getToken()}`,
                    },
                  }
                );
                toastEmitter("success", response?.data?.data?.message);
              } catch (error) {
                if (error?.response?.status === 401) {
                  localStorage.clear();
                  sessionStorage.clear();
                  return (window.location.href = "/");
                } else {
                  toastEmitter("error", error?.response?.data?.error?.message);
                }
              }
              handleCSVModalClose();
            } else {
              setError(
                "Please upload a CSV file, select a batch, and select members before submitting."
              );
            }
            setLoading(false);
          }}
        >
          {loading ? <Spinner /> : "Submit"}
        </button>
        <div
          className={`w-full text-sm border border-black/30 rounded-lg cursor-pointer h-[50px] justify-center px-4 items-start flex flex-col bg-black/20 focus:outline-none mb-1`}
        >
          <input
            type="file"
            accept=".csv"
            onChange={handleFileChange}
            className="cursor-pointer focus:outline-none"
          />
        </div>
        {error && <p className="text-danger text-sm">{error}</p>}

        <div>
          <div className="flex pb-2 justify-between items-center">
            <div className="text-sm text-grey-700">Select team member</div>
            <div>
              <Controller
                name=""
                control={control}
                rules={{ required: "Please select a batch" }}
                render={({ field }) => (
                  <select
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                      setSelectedBatchUuid(e.target.value);
                    }}
                    required={true}
                  >
                    <option value="">Please select a batch</option>
                    {allBatchesData?.data?.data?.result.map((batch) => (
                      <option value={batch.uuid} key={batch.uuid}>
                        {batch.name}
                      </option>
                    ))}
                  </select>
                )}
              />
            </div>
          </div>
          <div className=" px-6 pb-10 py-3 h-[400px] mb-10 border-grey-300 border-t overflow-auto xxl:max-h-[330px] xl:max-h-[330px] lg:max-h-[330px] md:max-h-[254px]">
            <ListManager
              setHasdata={setHasdata}
              actionFn={getMemberListing}
              queryKey="getMemberListing"
              listItem={MemberListItem}
              loadingComponent={LoadingItem}
              onChangeItemHandler={setSelectedMembers}
              itemValue={selectedMembers}
            />
          </div>
        </div>
        {/* <div className="flex gap-4 mt-10 ml-auto justify-end items-center">
          <button
            onClick={handleCSVModalClose}
            className="border-danger border text-danger hover:bg-danger hover:text-white duration-500 w-[100px] h-[40px] rounded"
          >
            Close
          </button>
        </div> */}
      </form>
    </div>
  );
};

export default ImportCSV;
