import { useAuth } from "hooks/useAuth";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { navigationArr } from "shared/resources";

export default function Sidebar() {
  const location = useLocation();
  const { user } = useAuth();
  const hasIvrAccess = Boolean(
    user?.get_company_details?.features?.find(
      (feat) => feat?.key === "voice_response_ivr"
    )?.value
  );
  const hasCallRecordingAccess = Boolean(
    user?.get_company_details?.features?.find(
      (feat) => feat?.key === "call_recording"
    )?.value
  );
  const parentPath = window.location.pathname?.split("/")?.[1];
  const [{ innerNavigation = [] }] = navigationArr.filter((nav) =>
    parentPath.includes(nav?.parent)
  );

  const [mutatedRoutes, setMutatedRoutes] = useState(innerNavigation);

  useEffect(() => {
    if (location.pathname) {
      const mutatedRoutes = innerNavigation.map((nav) => {
        if (location.pathname.includes(nav.path)) {
          return { ...nav, isActive: true, user };
        } else {
          return { ...nav, isActive: false, user };
        }
      });

      setMutatedRoutes(mutatedRoutes);
    }
  }, [location.pathname]);

  return (
    <div>
      <span className="sm:hidden p-3  flex font-bold text-lg">
        {mutatedRoutes?.find((route) => route?.isActive)?.label ?? null}
      </span>

      <div className="overflow-x-auto xs:h-11 sm:h-full  xxl:min-w-[290px]   xl:min-w-[250px] lg:min-w-[256px] md:min-w-[230px] xs:rounded-md xs:m-2 xs:mb-0 xs:mt-0 sm:m-0 xs:p-[2px] xs:bg-grey-300 sm:bg-grey-100 sm:pt-10 sm:px-4 flex md:flex-col xs:flex-row  gap-2 border-grey-300 border-r">
        {mutatedRoutes?.map((nav) => {
          if (
            nav?.access?.length === 0 ||
            !nav.access.includes(user?.role_id)
          ) {
            return;
          }

          if (nav?.label === "Phone menu" && !hasIvrAccess) {
            return;
          }
          if (nav?.label === "Call recordings" && !hasCallRecordingAccess) {
            return;
          }

          return (
            <Link
              key={nav?.id}
              to={nav?.path}
              className={`xxl:text-base xl:text-base lg:text-sm md:text-sm  flex items-center xs:justify-center sm:justify-start gap-3 p-3 rounded-md xs:hover:bg-white sm:hover:bg-grey-300 cursor-pointer xs:w-full whitespace-nowrap  ${
                nav?.isActive ? "sm:bg-grey-300 xs:bg-white " : ""
              }`}
            >
              <nav.icon className="w-6 h-6 md:flex xs:hidden" />
              {nav?.label}
            </Link>
          );
        })}
      </div>
    </div>
  );
}
