import React from 'react';
import { ReactComponent as Africa } from '../../assets/images/signup/Africa.svg';
import { InfoGrey } from 'assets/images';
import Tooltip from 'components/Tooltip';
const baseInputLabelClasses =
  'text-grey-800 xxl:text-base xl:text-sm	lg:text-sm md:text-xs sm:text-sm xs:text-sm font-medium';

const baseInputClasses =
  'flex gap-3 items-center w-full border border-grey-400  rounded-md  focus:outline-none focus:ring focus:bg-white font-medium		text-base text-grey-900';

export default function CountrySelect({
  extraClasses = '',
  background = 'bg-transparent',
  height = 'xxl:h-[56px] xl:h-[45px] lg:h-[45px] md:h-[41px] sm:h-[40px] xs:h-[40px]',
  withTooltip = false,
  border = '',
  label = 'Country',
  title = 'Nigeria',
  padding = 'px-4',
  withLabel = true,
  withFlag = true,
  tooltipPosition = 't',
}) {
  return (
    <div className={`flex flex-col gap-1 ${extraClasses}`}>
      {withLabel ? (
        <label htmlFor={label} className={baseInputLabelClasses}>
          {label}
        </label>
      ) : null}
      <span className="flex gap-2 items-center relative">
        <div
          className={
            padding +
            ' ' +
            baseInputClasses +
            ' ' +
            background +
            ' ' +
            height +
            ' ' +
            border
          }
        >
          {withFlag ? <Africa /> : null}
          {title}
        </div>
        {withTooltip ? (
          <Tooltip
            position={tooltipPosition}
            title="Fonu is only available to Nigerians at this time, we would notify you when we extend our service to other countries."
          >
            <div className='xs:absolute md:relative xs:right-2 md:right-auto xs:-top-11 md:top-auto xs:text-grey-600 md:text-black'>

              <InfoGrey />
            </div>
          </Tooltip>
        ) : null}
      </span>
    </div>
  );
}
