import React from 'react';
import { Button, Input } from 'components';
import { useServerValidator } from 'hooks';
import { Controller } from 'react-hook-form';

export default function StepOne({
  control,
  errors,
  handleNextStep,
  step,
  watch,
  setError,
  clearErrors,
  ifErrors,
}) {
  const [watchEmail] = watch(['email']);

  const {
    loadingServerError: serverEmailErrorLoading,
    isValid: isValidServerEmail,
  } = useServerValidator({
    payload: {
      watch: watchEmail,
      value: watchEmail,
      type: 'email',
      key: 'email',
    },
    successAction: clearErrors,
    shouldValidate: watchEmail && watchEmail.length > 0,
    errorAction: setError,
    delay: 1000,
  });

  return (
    <div className="flex flex-col  ">
      <div className="xxl:mt-4 xl:mt-4 lg:mt-3 md:mt-4 sm:mt-4 xs:mt-4">
        <Controller
          name="first_name"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              label="First name "
              error={errors?.first_name?.message}
              maxLength={50}
              required={true}
            />
          )}
        />
      </div>
      <div className="xxl:mt-4 xl:mt-4 lg:mt-3 md:mt-4 sm:mt-4 xs:mt-4">
        <Controller
          name="last_name"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              label="Last name"
              error={errors?.last_name?.message}
              maxLength={50}
              required={true}
            />
          )}
        />
      </div>
      <div className="xxl:mt-4 xl:mt-4 lg:mt-3 md:mt-4 sm:mt-4 xs:mt-4">
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              label="Email address"
              error={errors?.email?.message}
              isServerValidationLoading={serverEmailErrorLoading}
              required
            />
          )}
        />
      </div>
      <div className="xxl:mt-4 xl:mt-4 lg:mt-3 md:mt-4 sm:mt-4 xs:mt-4">
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              required={true}
              type="password"
              label="Password"
              autoComplete="new-password"
              error={errors?.password?.message}
            />
          )}
        />
      </div>
      <div className="xxl:mt-4 xl:mt-4 lg:mt-3 md:mt-4 sm:mt-4 xs:mt-4">
        <Controller
          name="confirm_password"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              type="password"
              required={true}
              label="Confirm Password"
              autoComplete="new-password"
              error={errors?.confirm_password?.message}
            />
          )}
        />
      </div>
      <div className="  xxl:mt-4 xl:mt-4 lg:mt-3 md:mt-4 sm:mt-4 xs:mt-4">
        <Button
          disabled={
            ifErrors || !isValidServerEmail || !watch('confirm_password')
          }
          onClick={() => handleNextStep(step)}
        >
          Continue
        </Button>
      </div>
    </div>
  );
}
