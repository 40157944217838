export const initialModalState = {
  modalType: '',
  isShow: false,
  elementData: {},
  type: '',
};

export const dropdownActions = {
  PORT_NUMBER_MODAL: 'port_number',
};
