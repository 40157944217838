import { useEffect, useState } from 'react';
import { secondsToHHMMSS } from 'shared/resources';
import moment from 'moment';

export default function Timer({ seconds }) {
  let [time, setTime] = useState('00:00');
  let call_start_time = moment(seconds);
  useEffect(() => {
    if (!seconds) return;
    const interval = setInterval(() => {
      var Current_time = moment();
      var second = Current_time.diff(call_start_time, 'seconds');
      setTime(secondsToHHMMSS(second));
    }, 1000);

    return () => clearInterval(interval);
  }, [seconds, call_start_time]);

  if (!seconds) return;
  return time;
}
