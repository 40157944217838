import { getDIDListing, getIVRList, getMemberListing, teamListing } from 'api';
import { CustomSelectServer, Input } from 'components';
import CustomSelect from 'components/CustomSelect';
import { useAuth } from 'hooks/useAuth';
import React from 'react';
import { Controller } from 'react-hook-form';
import { ForwardingArray, ayt, formatPhoneNumber } from 'shared/resources';

const ForwardToItem = ({ item }) => {
  return (
    <div
      key={item.value}
      value={item?.value}
      className="w-full h-full flex  justify-start gap-2 items-center py-2 px-3"
    >
      {item?.label}
    </div>
  );
};
const IVRListItem = ({ item }) => {
  return (
    <div
      key={item.uuid}
      value={item?.uuid}
      className="w-full h-full flex  justify-start gap-2 items-center py-2 px-3"
    >
      {item?.name}
    </div>
  );
};
const TeamListItem = ({ item }) => {
  return (
    <div
      key={item.uuid}
      value={item?.uuid}
      className="w-full h-full flex  justify-start gap-2 items-center py-2 px-3"
    >
      {item?.name}
    </div>
  );
};
const MemberListItem = ({ item }) => {
  const first_name = item?.first_name ?? 'Unknown';
  const last_name = item?.last_name ?? '';
  return (
    <div
      key={item.extension}
      value={item?.extension}
      className="w-full h-full flex  justify-start gap-2 items-center py-2 px-3"
    >
      {first_name} {last_name}
    </div>
  );
};
const FonuNumberItem = ({ item, ...rest }) => {
  if (rest?.selectedItem === item?.did_number) return;
  if (rest?.selectedItem === item?.uuid) return;
  return (
    <div
      key={item?.did_number}
      value={item?.did_number}
      className="w-full h-full flex  justify-start gap-2 items-center py-2 px-3"
    >
      {formatPhoneNumber(item?.did_number)}
    </div>
  );
};

const ForwardToInput = ({
  clearErrors,
  forward_type_key = '',
  forward_value_key = '',
  forward_type_error = undefined,
  forward_value_error = undefined,
  control,
  watch,
  setValue,
  label = 'Forward calls to',
  selectedItem = '',
}) => {
  watch(forward_type_key);

  const { user } = useAuth();

  const hasIvrAccess = Boolean(
    user?.get_company_details?.features?.find(
      (feat) => feat?.key === 'voice_response_ivr',
    )?.value,
  );

  function getOptionType(forward_type_value) {
    switch (forward_type_value) {
      case 'TEAM':
        return (
          <Controller
            name={forward_value_key}
            control={control}
            render={({ field }) => (
              <CustomSelectServer
                {...field}
                onWhitebg={true}
                label="Select Team"
                placeholder="Select team"
                error={forward_value_error}
                ItemComponent={TeamListItem}
                labelKey={'name'}
                valueKey={'uuid'}
                isMulti={false}
                actionFn={teamListing}
                queryKey={'teamListing'}
              />
            )}
          />
        );
      case 'EXTENSION':
        return (
          <Controller
            name={forward_value_key}
            control={control}
            render={({ field }) => (
              <CustomSelectServer
                {...field}
                onWhitebg={true}
                label="Select Member"
                placeholder="Select member"
                error={forward_value_error}
                ItemComponent={MemberListItem}
                labelKey={'first_name'}
                valueKey={'extension'}
                isMulti={false}
                actionFn={getMemberListing}
                queryKey={'getMemberListing'}
              />
            )}
          />
        );
      case 'FONU_NUMBER':
        return (
          <Controller
            name={forward_value_key}
            control={control}
            render={({ field }) => (
              <CustomSelectServer
                {...field}
                height="xxl:h-14 xl:h-12"
                onWhitebg={true}
                label="Fonu Number"
                placeholder="Select fonu number"
                error={forward_value_error}
                ItemComponent={FonuNumberItem}
                labelKey={'did_number'}
                valueKey={'did_number'}
                isMulti={false}
                actionFn={getDIDListing}
                queryKey={'getDIDListing'}
                shouldFormat={true}
                selectedItem={selectedItem}
              />
            )}
          />
        );

      case 'NUMBER':
        return (
          <Controller
            name={forward_value_key}
            control={control}
            render={({ field }) => {
              const { onChange } = field;
              return (
                <Input
                  {...field}
                  onWhitebg={true}
                  label="Enter phone number"
                  error={forward_value_error}
                  autoComplete="off"
                  maxLength="17"
                  onChange={(e) => {
                    const aytNumber = ayt(e.target.value);
                    onChange(aytNumber);
                  }}
                />
              );
            }}
          />
        );
      // case 'VOICEMAIL':
      //   return (
      //     <Controller
      //       name={forward_value_key}
      //       control={control}
      //       render={({ field }) => (
      //         <CustomSelectServer
      //           {...field}
      //           height="xxl:h-14 xl:h-12"
      //           onWhitebg={true}
      //           label="Select voicemail"
      //           placeholder="Select option"
      //           error={forward_value_error}
      //           ItemComponent={VoicemailItem}
      //           labelKey={'name'}
      //           valueKey={'uuid'}
      //           isMulti={false}
      //           actionFn={greetingList}
      //           queryKey={'greetingList'}
      //         />
      //       )}
      //     />
      //   );
      case 'IVR':
        return hasIvrAccess ? (
          <Controller
            name={forward_value_key}
            control={control}
            render={({ field }) => (
              <CustomSelectServer
                {...field}
                height="xxl:h-14 xl:h-12"
                onWhitebg={true}
                label="Select phone menu"
                placeholder="Select option"
                error={forward_value_error}
                ItemComponent={IVRListItem}
                labelKey={'name'}
                valueKey={'uuid'}
                isMulti={false}
                actionFn={getIVRList}
                queryKey={'getIVRList'}
              />
            )}
          />
        ) : null;

      case 'IP':
        return (
          <Controller
            name={forward_value_key}
            control={control}
            render={({ field }) => {
              return (
                <Input
                  {...field}
                  onWhitebg={true}
                  label="Enter IP"
                  error={forward_value_error}
                  autoComplete="off"
                />
              );
            }}
          />
        );

      default:
        return null;
    }
  }

  return (
    <div className="xs:flex-col sm:flex-row flex items-start justify-between w-full gap-6">
      <div className="w-full">
        <Controller
          name={forward_type_key}
          control={control}
          render={({ field }) => (
            <CustomSelect
              {...field}
              height="xxl:h-14 xl:h-12"
              placeholder="Select option"
              onWhitebg={true}
              label={label}
              error={forward_type_error}
              ItemComponent={ForwardToItem}
              valueKey={'value'}
              labelKey={'label'}
              isMulti={false}
              optionsList={ForwardingArray}
              onChange={(e) => {
                field.onChange(e);
                setValue(forward_value_key, '');
                clearErrors();
              }}
            />
          )}
        />
      </div>
      {['TEAM', 'EXTENSION', 'FONU_NUMBER', 'NUMBER', 'IVR', 'IP'].includes(
        watch(`${forward_type_key}`),
      ) ? (
        <div className="w-full xs:min-w-[100%] sm:min-w-[400px]">
          {getOptionType(watch(`${forward_type_key}`))}
        </div>
      ) : null}
    </div>
  );
};

export default ForwardToInput;
