import { Button, Input, Spinner } from 'components';
import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { alphaNumericRegx, getRequiredText } from 'shared/resources';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import DocumentUploadInput from 'pages/PortNumber/PortNumberForm/UploadDocumentsForm/DocumentUploadInput';

const validationSchema = yup.object().shape({
  file_name: yup.string().required(getRequiredText('Name')),
  valid_file: yup.mixed().required(getRequiredText('Valid file')),
});

const UploadFile = ({
  onSuccess = () => null,
  acceptType = 'application/*,image/*',
  mediaUploadLoad = false,
  mediaUploadAction = () => null,
  type = '',
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      file_name: '',
      valid_file: null,
    },
    mode: 'onChange',
  });

  const handleSave = async (values) => {
    const res = await mediaUploadAction({
      file: values?.valid_file,
      type: 'greeting',
    });
    if (res) {
      onSuccess({
        name: values?.file_name,
        filename: res?.file_name,
        file: values?.valid_file,
        duration: res?.duration,
        type: 'U',
        action_type: type,
      });
    } else {
      return null;
    }
  };
  return (
    <div
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          handleSubmit(handleSave)();
          e.preventDefault();
          e.stopPropagation();
        }
      }}
    >
      <Controller
        name="file_name"
        control={control}
        render={({ field }) => {
          const { onChange } = field;
          return (
            <Input
              {...field}
              onChange={(e) => {
                if (alphaNumericRegx.test(e.target.value)) {
                  onChange(e.target.value);
                } else {
                  return;
                }
              }}
              onWhitebg={true}
              label="Name"
              error={errors?.file_name?.message}
              maxLength={50}
            />
          );
        }}
      />

      <div className="mt-5">
        <DocumentUploadInput
          setValue={setValue}
          watch={watch}
          label="Upload valid file"
          valueKey="valid_file"
          uploadTypeText="file"
          acceptType={acceptType}
          error={errors?.valid_file?.message}
          showFileName={true}
          IIconText="Only audio files are accepted. Supported audio formats are MP3, WAV, etc."
        />
      </div>

      <div className="mt-10 ">
        <Button
          type="button"
          height="xxl:h-[50px] xl:h-[45px] lg:h-[45px] md:h-[41px]"
          disabled={mediaUploadLoad}
          onClick={handleSubmit(handleSave)}
        >
          {mediaUploadLoad ? <Spinner /> : 'Save'}
        </Button>
      </div>
    </div>
  );
};

export default UploadFile;
