import { userDetailsByParams } from 'api';
import { useMutation } from '@tanstack/react-query';

export default function useUserDetailsByParams() {
  const Request = useMutation({
    mutationFn: userDetailsByParams,
    mutationKey: ['userDetailsByParams'],
  });
  return { ...Request };
}
