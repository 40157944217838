import React from 'react';

const MessageBoard = ({ chatId = '' }) => {
  console.log('🚀 ~ MessageBoard ~ chatId:', chatId);
  return (
    <>
      <div className="text-xs text-grey-600 text-center">Today</div>
      <div className=" flex flex-col gap-2 mt-4">
        <div className="flex items-center gap-2.5">
          <div className="w-[38px] "></div>
          <div className="text-sm font-normal text-grey-600 ">
            Deanna Curtis
          </div>
        </div>
        <div className="flex items-center gap-2.5">
          <div className="w-[38px] h-[38px]"></div>
          <div className="bg-grey-200 text-grey-900 rounded-full px-4 py-2">
            Hello, anyone there?
          </div>
        </div>
        <div className="flex items-center gap-2.5">
          <div className="w-[38px] h-[38px]">
            <img
              src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8Z2lybHN8ZW58MHx8MHx8fDA%3D"
              alt="ss"
              className="rounded-full w-[38px] h-[38px] object-cover "
            />
          </div>
          <div className="bg-grey-200 text-grey-900 rounded-full px-4 py-2">
            I need to make a complaint
          </div>
        </div>
        <div className="flex items-center gap-2.5">
          <div className="w-[38px] "></div>
          <div className="text-sm font-normal text-grey-600 mb-2">9:41 PM</div>
        </div>
      </div>
      <div className=" flex flex-col items-end gap-2 mt-4">
        <div className="flex items-center gap-2.5">
          <div className="text-sm font-normal text-grey-600 ">
            Deanna Curtis
          </div>
          <div className="w-[38px] "></div>
        </div>

        <div className="flex items-center gap-2.5">
          <div className="bg-green text-white rounded-full px-4 py-2">
            I need to make a complaint
          </div>
          <div className="w-[38px] h-[38px]">
            <img
              src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8Z2lybHN8ZW58MHx8MHx8fDA%3D"
              alt="ss"
              className="rounded-full w-[38px] h-[38px] object-cover "
            />
          </div>
        </div>
        <div className="flex items-center gap-2.5">
          <div className="text-sm font-normal text-grey-600 mb-2">9:41 PM</div>
          <div className="w-[38px] "></div>
        </div>
      </div>
    </>
  );
};

export default MessageBoard;
