import { PortPhoneIcon } from 'assets/images';
import React from 'react';
import NumberStatusComponent from './NumberStatusComponent';

const PortNumberStatus = () => {
  return (
    <div className="bg-white w-full h-full overflow-auto flex items-center justify-center">
      <div className="flex flex-col justify-center items-center">
        <PortPhoneIcon />
        <div className="font-semibold text-4xl mt-4 mb-1">
          Porting in process
        </div>
        <div className="text-base text-grey-700 text-center leading-6">
          Porting typically takes 2-5 hours on business days,<br></br> and 10-12
          hours on weekends
        </div>
        <div>
          <NumberStatusComponent />
        </div>
      </div>
    </div>
  );
};

export default PortNumberStatus;
