import React from 'react';
import Sidebar from './Sidebar';
import { Outlet } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
export default function SidebarLayout() {
  const { pathname } = useLocation();
  return (
    <div className="bg-grey-100 flex xs:flex-col md:flex-row xs:h-[calc(100%_-_8.5%)] sm:h-[calc(100%_-_80px)]  sm:body-height overflow-auto   ">
      {!pathname?.includes('cart') ? <Sidebar /> : null}
      {pathname.includes('port-number/form') || pathname.includes('customer-kyc/read-view') ? (
        <Outlet />
      ) : (
        <div className="overflow-auto w-full px-6 pt-6 pb-2 xs:h-full sm:h-auto">
          <Outlet />
        </div>
      )}
    </div>
  );
}
