import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createTeam } from 'api';
import { toastEmitter } from 'components/Toast';

export default function useCreateTeam({
  handleSuccess = () => null,
  handleError = () => null,
} = {}) {
  const queryclient = useQueryClient();

  const Request = useMutation({
    mutationFn: createTeam,
    mutationKey: ['createTeam'],
    onSuccess: (data) => {
      const status = data?.status;
      const responseData = data?.data;
      if (status !== 200) {
        handleError();
        return toastEmitter('error', responseData?.error?.message);
      }
      handleSuccess();
      queryclient.invalidateQueries(['teamListing']);
      toastEmitter('success', responseData?.data?.message);
    },
  });
  return { ...Request };
}
