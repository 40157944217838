import { Support } from "assets/images";
import { Input, Spinner } from "components";
import SwitchInput from "components/SwitchInput";
import { useAuth } from "hooks/useAuth";
import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";

const IncomingCallSetting = ({ control, errors, watch }) => {
  const { user } = useAuth();
  const [iconPreview, setIconPreview] = useState(null);
  const [loadingIcon, setLoadingIcon] = useState(true);

  const hasCallRecordingAccess = Boolean(
    user?.get_company_details?.features?.find(
      (feat) => feat?.key === "call_recording"
    )?.value
  );

  useEffect(() => {
    if (watch("icon") && watch("icon")?.name) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setIconPreview(event.target.result);
      };
      reader.readAsDataURL(watch("icon"));
    }
  }, [watch("icon")?.name]);

  useEffect(() => {
    if (watch("icon") && typeof watch("icon") === "string") {
      const result = `${process.env.REACT_APP_BASE_API_URL}${
        user?.company_uuid || user?.uuid
      }/icon/${watch("icon")}`;
      setIconPreview(result);
    }
  }, [watch("icon")]);

  return (
    <>
      <div className="flex flex-col">
        <div className="font--medium text-base text-grey-900 ">
          Icon and name
        </div>
        <div className="flex items-center w-full gap-2 mt-2">
          <div className="">
            <Controller
              name="icon"
              control={control}
              render={({ field }) => (
                <div>
                  <label
                    className="w-[76px] xxl:h-[56px] xl:h-[45px] lg:h-[45px] md:h-[41px] sm:h-[40px] xs:h-[40px] flex items-center justify-center bg-grey-100 border-grey-400 border rounded-md cursor-pointer"
                    htmlFor="icon"
                  >
                    <div className="w-6 h-6">
                      {watch("icon") ? (
                        <div className="flex items-center justify-center">
                          <img
                            className="w-8 h-8 hidden"
                            src={iconPreview}
                            alt="icon-preview"
                            onLoad={() => {
                              setLoadingIcon(false);
                            }}
                            onError={() => setLoadingIcon(false)}
                          />
                          {loadingIcon ? (
                            <Spinner />
                          ) : (
                            <img
                              className="w-6 h-6"
                              src={iconPreview}
                              alt="icon-preview"
                            />
                          )}
                        </div>
                      ) : (
                        <Support />
                      )}
                    </div>
                    <input
                      id="icon"
                      type="file"
                      accept="image/*"
                      onChange={(e) => field.onChange(e.target.files[0])}
                      hidden
                    />
                  </label>
                </div>
              )}
            />
          </div>
          <div className="w-full">
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <Input
                  className="bg-grey-100"
                  {...field}
                  label=""
                  onWhitebg={true}
                  error={errors?.name?.message}
                />
              )}
            />
          </div>
        </div>
      </div>
      {hasCallRecordingAccess ? (
        <>
          <div className="flex flex-col mt-7 mb-5">
            <div className="flex justify-between">
              <div className="text-base font-semibold text-grey-900">
                Auto record calls
              </div>
              <Controller
                name={"auto_record_calls"}
                control={control}
                render={({ field }) => (
                  <SwitchInput {...field} name="auto_record_calls" label={""} />
                )}
              />
            </div>
          </div>
          <div className="flex mt-4">
            <div className="text-grey-700 text-sm font-medium  ">
              Automatically record all incoming calls to this number. Make sure
              you stay compliant with recording rules in your region.
            </div>
          </div>
          <div className="flex flex-col mt-7 mb-5">
            <div className="flex justify-between">
              <div className="text-base font-semibold text-grey-900">
                Auto record outgoing calls
              </div>
              <Controller
                name={"auto_record_outgoing_calls"}
                control={control}
                render={({ field }) => (
                  <SwitchInput
                    {...field}
                    name="auto_record_outgoing_calls"
                    label={""}
                  />
                )}
              />
            </div>
          </div>
          <div className="flex mt-4">
            <div className="text-grey-700 text-sm font-medium  ">
              Automatically record all outgoing calls from this number. Make
              sure you stay compliant with recording rules in your region.
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default IncomingCallSetting;
