import {
  Card,
  Password,
  Prohibit,
  UserCircle,
  CreditCard,
  PinLink,
  Billing,
  Wallet,
} from 'assets/images';
import { useAuth } from 'hooks/useAuth';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { ADMIN, MEMBER, OWNER } from 'shared/resources';

const NavigationArr = [
  {
    id: 1,
    parent: 'profile',
    path: '/profile/general',
    label: 'Profile',
    icon: UserCircle,
    access: [ADMIN, OWNER, MEMBER],
  },
  {
    id: 2,
    parent: 'profile',
    path: '/profile/pnb',
    label: 'Plans',
    icon: Card,
    access: [ADMIN, OWNER],
  },
  {
    id: 3,
    parent: 'profile',
    path: '/profile/invite-earn',
    label: 'Invite and Earn',
    icon: PinLink,
    access: [ADMIN, OWNER],
  },
  {
    id: 4,
    parent: 'profile',
    path: '/profile/blocklist',
    label: 'Blocklist',
    icon: Prohibit,
    access: [ADMIN, OWNER],
  },
  {
    id: 5,
    parent: 'profile',
    path: '/profile/password',
    label: 'Change password',
    icon: Password,
    access: [ADMIN, OWNER, MEMBER],
  },
  {
    id: 6,
    parent: 'profile',
    path: '/profile/transactions',
    label: 'Billing',
    icon: Billing,
    access: [ADMIN, OWNER],
  },
  {
    id: 7,
    parent: 'profile',
    path: '/profile/wallet',
    label: 'Wallet',
    icon: Wallet,
    access: [ADMIN, OWNER],
  },
  {
    id: 8,
    parent: 'profile',
    path: '/profile/saved-cards',
    label: 'Saved Cards',
    icon: CreditCard,
    access: [ADMIN, OWNER],
  },

];

const Sidebar = () => {
  const { user } = useAuth();
  return (
    <div className=" xxl:min-w-[290px] xl:min-w-[250px] lg:min-w-[256px] md:min-w-[230px] pt-7 flex flex-col border-grey-300 border-r">
      <div className="flex flex-col gap-2.5 px-4 mb-2">
        {NavigationArr?.map((nav) => {
          if (
            nav?.access?.length === 0 ||
            !nav.access.includes(user?.role_id)
          ) {
            return;
          }
          return (
            <NavLink
              key={nav?.id}
              to={nav?.path}
              className={({ isActive }) =>
                `xxl:text-base xl:text-base lg:text-sm md:text-sm  flex items-center gap-3 p-3 rounded-md hover:bg-grey-300 cursor-pointer  ${isActive ? 'bg-grey-300' : ''
                }`
              }
            >
              <nav.icon className="w-6 h-6" />
              {nav?.label}
            </NavLink>
          );
        })}
      </div>
    </div>
  );
};

export default Sidebar;
