import { useGoogleLogin } from '@react-oauth/google';
import { Google } from 'assets/images/signup';
import axios from 'axios';
import { Spinner } from 'components';
import { toastEmitter } from 'components/Toast';
import React from 'react';

const SCOPES = [
  'https://www.googleapis.com/auth/user.birthday.read',
  'https://www.googleapis.com/auth/profile.agerange.read',
  'https://www.googleapis.com/auth/userinfo.profile',
  'https://www.googleapis.com/auth/userinfo.email',
  'https://www.googleapis.com/auth/user.gender.read',
].join(' ');

const useGoogleAuth = ({
  actionFn = () => null,
  isLoading = false,
  socialType = 'GOOGLE',
}) => {
  const type = 'GOOGLE';

  const login = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      try {
        const res = await axios.get(
          'https://www.googleapis.com/oauth2/v3/userinfo',
          {
            headers: {
              Authorization: `Bearer ${codeResponse?.access_token}`,
            },
          },
        );
        actionFn(res?.data);
      } catch (err) {
        console.log(err);
      }
    },
    scope: SCOPES,
    onError: () => {
      return toastEmitter(
        'error',
        'User cancelled login or did not fully authorize.',
      );
    },
  });

  return (
    <div
      id="custom-google-login-btn"
      className="flex items-center justify-center gap-2 border border-solid  border-grey-400 rounded-md xxl:h-[56px] xl:h-[45px] lg:h-[45px] md:h-[41px] sm:h-[40px] xs:h-[40px]   text-black  w-full cursor-pointer"
      onClick={() => login()}
    >
      {isLoading && socialType === type ? (
        <Spinner />
      ) : (
        <>
          <Google className="w-5" />
          Google
        </>
      )}
    </div>
  );
};

export default useGoogleAuth;
